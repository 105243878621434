import axios from "axios";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Nav from "../process/Nav";
import { url } from "../link";
const Addenquiryremark = () => {
  const { state } = useLocation();
  const nav = useNavigate();
  const [remark, setremark] = useState("");
  const [remarkdate, setremarkdate] = useState("");
  const [status, setstatus] = useState("");
  const eid = state;
  const submitdata = () => {
    const value = {
      remark: remark,
      remarkdate: remarkdate,
      eid: eid,
      status: status,
    };
    axios.post(url + "/edusphere/insertenquiryremark", value).then((res) => {
      alert("success");
      nav("/showenquiry");
    });
  };
  return (
    <div>
      <Nav />

      <h1>Add enquiryremark</h1>
      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setremark(e.target.value)}
          value={remark}
          placeholder="Enter remark"
        />
        <label htmlFor="remark">remark</label>
      </div>

      {/* <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setremarkdate(e.target.value)}
          value={remarkdate}
          placeholder="Enter remarkdate"
        />
        <label htmlFor="remarkdate">remarkdate</label>
      </div> */}

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          value={eid}
          placeholder="Enter eid"
          disabled
        />
        <label htmlFor="eid">eid</label>
      </div>
      <div className="form-floating mb-3 mt-3">
        <select
          onChange={(e) => setstatus(e.target.value)}
          className="form-select"
        >
          <option>Open</option>
          <option>Closed</option>
        </select>
        <label htmlFor="status">status</label>
      </div>
      <input
        type="submit"
        className="btn btn-primary"
        onClick={submitdata}
        style={{ width: "100%" }}
      />
    </div>
  );
};
export default Addenquiryremark;
