import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { url } from "../link";
import Nav from "../process/Nav";
import DataTable from "react-data-table-component";
const Viewmobileremark = () => {
  const { state } = useLocation();

  const nav = useNavigate();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  //  Internally, customStyles will deep merges your customStyles with the default styling.
  const customStyles = {
    rows: {
      style: {
        minHeight: "72px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontSize: "1.2em",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        fontSize: "1em",
      },
    },
  };
  const column = [
    {
      name: "Mrid",
      selector: (row) => row.Mrid,
      sortable: true,
    },
    {
      name: "Mid",
      selector: (row) => row.Mid,
      sortable: true,
    },
    {
      name: "Remark",
      selector: (row) => row.Remark,
      sortable: true,
    },
    {
      name: "Transcationdate",
      selector: (row) => row.Transactiondate,
      sortable: true,
    },

    {
      name: "Delete",
      selector: (row) => row.Delete,
      sortable: true,
    },
  ];
  useEffect(() => {
    const timeout = setTimeout(() => {
      axios
        .post(url + "/edusphere/viewmobileremark", { id: state })
        .then((response) => {
          var r = response.data.map((k) => {
            return {
              Mrid: (
                <button
                  className="btn btn-primary"
                  onClick={() => viewmobileenquiry(k)}
                >
                  {k[0]}
                </button>
              ),
              Mid: k[3],
              Remark: k[1],

              Transactiondate: k[2],

              Delete: (
                <button
                  className="btn btn-primary"
                  onClick={() => deletec(k[0])}
                >
                  delete
                </button>
              ),
            };
          });

          setData(r);
          setFilteredData(r);

          setPending(false);
        });
      return () => clearTimeout(timeout);
    }, 1000);
  }, []);
  const [pending, setPending] = useState(true);
  const viewmobileenquiry = (e) => {
    nav("/updatemobileenquiry", { state: e });
  };
  const deletec = (e) => {
    axios
      .post(url + "/edusphere/deletemobileremark", { id: e })
      .then((response) => {
        const timeout = setTimeout(() => {
          axios
            .post(url + "/edusphere/viewmobileremark", {
              id: state,
            })
            .then((response) => {
              var r = response.data.map((k) => {
                return {
                  Mrid: (
                    <button
                      className="btn btn-primary"
                      onClick={() => viewmobileenquiry(k)}
                    >
                      {k[0]}
                    </button>
                  ),
                  Mid: k[3],
                  Remark: k[1],

                  Transactiondate: k[2],

                  Delete: (
                    <button
                      className="btn btn-primary"
                      onClick={() => deletec(k[0])}
                    >
                      delete
                    </button>
                  ),
                };
              });

              setData(r);
              setFilteredData(r);

              setPending(false);
            });
          return () => clearTimeout(timeout);
        }, 1000);
      });
  };

  return (
    <div>
      <Nav />
      <h3>MobileRemark</h3>

      <div className="dataTableContainer">
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
            const filtered = data.filter((item) =>
              Object.values(item).some((value) =>
                value
                  .toString()
                  .toLowerCase()
                  .includes(e.target.value.toLowerCase())
              )
            );
            setFilteredData(filtered);
          }}
          placeholder="Search..."
        />
        <DataTable
          columns={column}
          data={filteredData}
          pagination
          fixedHeader
          fixedHeaderScrollHeight="70vh"
          dense
          customStyles={customStyles}
          progressPending={pending}
        />
      </div>
    </div>
  );
};
export default Viewmobileremark;
