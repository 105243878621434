import axios from "axios";
import { useEffect, useState } from "react";

import Nav from "../process/Nav";
import Fileuploadforquestion from "./Fileuploadforquestion";
import { url } from "../link";
const Addquestion = () => {
  const [qname, setqname] = useState("");
  const [o1, seto1] = useState("");
  const [o2, seto2] = useState("");
  const [o3, seto3] = useState("");
  const [o4, seto4] = useState("");
  const [correctanswer, setcorrectanswer] = useState("");
  const [mark, setmark] = useState("");
  const [tid, settid] = useState("");
  const [sid, setsid] = useState("");
  const [data, setdata] = useState("");
  useEffect(() => {});
  const submitdata = () => {
    const value = {
      qname: qname,
      o1: o1,
      o2: o2,
      o3: o3,
      o4: o4,
      correctanswer: correctanswer,
      mark: mark,
      tid: sid,
      data: data,
      sid: sid,
    };
    axios.post(url + "/edusphere/insertquestion", value);
    alert("success");
    setqname("");
    seto1("");
    seto2("");
    seto3("");
    seto4("");
    setcorrectanswer("");
    setmark("");
    settid("");
    setdata("");
  };
  return (
    <div>
      <Nav />
      <div className="main">
        <div className="page-container">
          <h1>Add question</h1>
          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => setqname(e.target.value)}
              value={qname}
              placeholder="Enter qname"
            />
            <label htmlFor="qname">qname</label>
          </div>

          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => seto1(e.target.value)}
              value={o1}
              placeholder="Enter o1"
            />
            <label htmlFor="o1">o1</label>
          </div>

          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => seto2(e.target.value)}
              value={o2}
              placeholder="Enter o2"
            />
            <label htmlFor="o2">o2</label>
          </div>

          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => seto3(e.target.value)}
              value={o3}
              placeholder="Enter o3"
            />
            <label htmlFor="o3">o3</label>
          </div>

          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => seto4(e.target.value)}
              value={o4}
              placeholder="Enter o4"
            />
            <label htmlFor="o4">o4</label>
          </div>

          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => setcorrectanswer(e.target.value)}
              value={correctanswer}
              placeholder="Enter correctanswer"
            />
            <label htmlFor="correctanswer">correctanswer</label>
          </div>
          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => setsid(e.target.value)}
              value={sid}
              placeholder="Enter sid"
            />
            <label htmlFor="tid">sid</label>
          </div>
          {/* <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => setmark(e.target.value)}
              value={mark}
              placeholder="Enter mark"
            />
            <label htmlFor="mark">mark</label>
          </div>
         
          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => settid(e.target.value)}
              value={tid}
              placeholder="Enter tid"
            />
            <label htmlFor="tid">tid</label>
          </div> */}
          <div className=" mb-3 mt-3 col">
            <label htmlFor="project_status">Ppt</label>
            <Fileuploadforquestion
              name={qname.replace(/[^\w\s]/g, "") + tid + sid}
              setdata={setdata}
            />
          </div>
          <input
            type="submit"
            className="btn btn-primary"
            onClick={submitdata}
            style={{ width: "100%" }}
          />
        </div>
      </div>
    </div>
  );
};
export default Addquestion;
