import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Nav from "./Nav";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import { url } from "../link";
const Openenquiry = () => {
  const [data, setData] = useState([]);
  const [value, setvalue] = useState([]);
  const nav = useNavigate();
  useEffect(() => {
    axios.post(url + "/edusphere/enquiryopenreport").then((response) => {
      setData(response.data);
      setvalue(response.data);
      $(document).ready(function () {
        var eventFired = function (type) {
          var n = $("#demo_info")[0];

          n.scrollTop = n.scrollHeight;
        };

        $("#example")
          .on("order.dt", function () {
            eventFired("Order");
          })
          .on("search.dt", function () {
            eventFired("Search");
          })
          .on("page.dt", function () {
            eventFired("Page");
          })
          .DataTable();
      });
    });
  }, []);
  const nexts = (m) => {
    nav("/viewenquiry", { state: m });
  };

  const reg = (m) => {
    var x = m[0] + "-" + m[1] + "-" + m[2];
    nav("/addregistration", { state: x });
  };
  return (
    <>
      <Nav />
      <div className="main">
        <div className="page-container">
          <div className="table-responsive p-0 pb-2">
            <div
              id="demo_info"
              className="box"
              style={{ display: "none" }}
            ></div>
            <table id="example" className="display" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>Eid</th>
                  <th>First Name</th>
                  <th>Mobile</th>
                  <th>Course</th>
                  <th>Enquiry date</th>
                  <th>Call date</th>
                  <th>Remark</th>
                  <th>Status</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {data.map((m) => {
                  return (
                    <tr key={m[0]}>
                      <td>
                        <button
                          onClick={() => nexts(m)}
                          className="btn btn-primary"
                        >
                          {m[0]}
                        </button>
                      </td>
                      <td>{m[1]}</td>
                      <td>{m[2]}</td>
                      <td>{m[3]}</td>
                      <td>{m[4]}</td>
                      <td>{m[5]}</td>
                      <td>{m[6]}</td>
                      <td>{m[7]}</td>
                      <td>
                        <td>
                          <button
                            className="btn btn-danger"
                            onClick={() => reg(m)}
                          >
                            Join
                          </button>
                        </td>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Openenquiry;
