import axios from "axios";
import { useState } from "react";
import Nav from "../process/Nav";
import { url } from "../link";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
const Schedule = () => {
  const { state } = useLocation();
  console.log(state);
  const [reg, setreg] = useState([]);
  const [cour, setcour] = useState([
    "c",
    "cplus",
    "java",
    "python",
    "mysql",
    "js",
    "javaterm1",
    "pythonterm",
    "tally",
    "selinum python",
    "excel",
  ]);

  var xr = {
    c: 1,
    cplus: 2,
    java: 3,
    python: 4,
    mysql: 5,
    js: 6,
    javaterm1: 7,
    pythonterm: 8,
    tally: 9,
    "selinum python": 10,
    excel: 11,
  };
  const [rid, setrid] = useState("");
  const [start, setstart] = useState("");
  const [end, setend] = useState("");
  const [course, setcourse] = useState("");
  const [name, setname] = useState("");
  const [mycour, setmycour] = useState("");
  const [studentcourse, setstudentcourse] = useState("");
  useEffect(() => {
    axios.post(url + "/edusphere/getreg").then((response) => {
      setreg(response.data["reg"]);
      console.log(state);
      if (state !== undefined && state !== null) {
        setrid(String(state));
        var e = response.data["reg"].filter((x) => {
          return x[0] === state;
        });
        var e = e[0];
        setrid(e[0]);
        setstudentcourse(e[1]);
        setname(e[2]);
      }
    });
  }, []);
  const setco = (e) => {
    var e = e.split("-");
    setrid(e[0]);
    // setcourse(e[1]);
    setname(e[2]);
  };
  const nav = useNavigate();

  const submitdata = () => {
    if (
      rid !== "" ||
      start !== "" ||
      end !== "" ||
      course !== "" ||
      mycour !== ""
    ) {
      const value = {
        rid: rid,
        course: course,
        start: start,
        end: end,
        mycour: mycour,
      };

      axios.post(url + "/edusphere/scheduleexam", value).then((response) => {
        nav("/viewattendanceclosed");
      });
    } else {
      alert(" will the data correctly");
    }
  };
  return (
    <>
      <Nav />
      <div className="main">
        <div className="page-container">
          <h1>Add Exam</h1>
          <div className="form-floating mb-3 mt-3">
            <input
              list="browsers"
              name="browser"
              id="browser"
              className="form-control"
              value={rid}
              onChange={(e) => setco(e.target.value)}
            />
            <datalist id="browsers">
              {reg.map((d) => {
                return (
                  <option value={d[0] + "-" + d[1] + "-" + d[2]} key={d[0]} />
                );
              })}
            </datalist>

            <label htmlFor="eid">rid</label>
          </div>
          <div className="form-floating mb-3 mt-3">
            <input type="text" className="form-control" value={studentcourse} />
            <label htmlFor="nexpamentdate">Student course</label>
          </div>
          <div className="form-floating mb-3 mt-3">
            <input type="text" className="form-control" value={course} />
            <label htmlFor="nexpamentdate">Courseid</label>
          </div>
          <div className="form-floating mb-3 mt-3">
            <input type="text" className="form-control" value={name} disabled />
            <label htmlFor="nexpamentdate">Name</label>
          </div>
          <div className="form-floating mb-3 mt-3">
            <select
              onChange={(e) => {
                setmycour(e.target.value);
                setcourse(xr[e.target.value]);
              }}
              className="form-select"
              defaultValue={mycour}
            >
              <option disabled selected>
                Select course
              </option>
              {cour.map((c) => {
                return (
                  <option value={c} key={c}>
                    {c}
                  </option>
                );
              })}
            </select>
            <label htmlFor="nexpamentdate">Select Course</label>
          </div>
          <div className="form-floating mb-3 mt-3">
            <input
              type="datetime-local"
              className="form-control"
              onChange={(e) => setstart(e.target.value)}
              value={start}
              placeholder="Enter Start time"
            />
            <label htmlFor="nexpamentdate">Start date and time</label>
          </div>

          <div className="form-floating mb-3 mt-3">
            <input
              type="datetime-local"
              className="form-control"
              onChange={(e) => setend(e.target.value)}
              value={end}
              placeholder="Enter Endtime and date"
            />
            <label htmlFor="nexpamentdate">End date and time</label>
          </div>
          <input
            type="submit"
            className="btn btn-primary"
            onClick={submitdata}
            style={{ width: "100%" }}
          />
        </div>
      </div>
    </>
  );
};

export default Schedule;
