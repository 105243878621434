import axios from "axios";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { url } from "../link";
const Updateproject23payment = () => {
  var rx = 0;
  const { state } = useLocation();
  const [pr23pa, setpr23pa] = useState(state[rx++]);
  const [pid, setpid] = useState(state[rx++]);
  const [amountpaid, setamountpaid] = useState(state[rx++]);
  const [balancetobepaid, setbalancetobepaid] = useState(state[rx++]);
  const [paiddate, setpaiddate] = useState(state[rx++]);
  const nav = useNavigate();
  const submitdata = () => {
    const value = {
      pr23pa: pr23pa,
      pid: pid,
      amountpaid: amountpaid,
      balancetobepaid: balancetobepaid,
      paiddate: paiddate,
    };
    axios
      .post(url + "/edusphere/updateproject23payment", value)
      .then((response) => {
        nav("/viewproject23payment");
      });
  };
  return (
    <div>
      <h1>Add project23payment</h1>
      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setpr23pa(e.target.value)}
          value={pr23pa}
          placeholder="Enter pr23pa"
        />
        <label htmlFor="pr23pa">pr23pa</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setpid(e.target.value)}
          value={pid}
          placeholder="Enter pid"
        />
        <label htmlFor="pid">pid</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setamountpaid(e.target.value)}
          value={amountpaid}
          placeholder="Enter amountpaid"
        />
        <label htmlFor="amountpaid">amountpaid</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setbalancetobepaid(e.target.value)}
          value={balancetobepaid}
          placeholder="Enter balancetobepaid"
        />
        <label htmlFor="balancetobepaid">balancetobepaid</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setpaiddate(e.target.value)}
          value={paiddate}
          placeholder="Enter paiddate"
        />
        <label htmlFor="paiddate">paiddate</label>
      </div>

      <input
        type="submit"
        className="btn btn-primary"
        onClick={submitdata}
        style={{ width: "100%" }}
      />
    </div>
  );
};
export default Updateproject23payment;
