import axios from "axios";
import { useState } from "react";
import { url } from "../link";
const Addattendance = () => {
  const [eid, seteid] = useState("");
  const [starttiming, setstarttiming] = useState("");
  const [endtime, setendtime] = useState("");
  const [skiptilldate, setskiptilldate] = useState("");
  const [status, setstatus] = useState("");
  const submitdata = () => {
    const value = {
      eid: eid,
      starttiming: starttiming,
      endtime: endtime,
      skiptilldate: skiptilldate,
      status: status,
    };
    axios.post(url + "/edusphere/insertattendance", value).then((res) => {
      alert("success");
      seteid("");
      setstarttiming("");
      setendtime("");
      setskiptilldate("");
      setstatus("");
    });
  };
  return (
    <div>
      <h1>Add attendance</h1>
      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => seteid(e.target.value)}
          value={eid}
          placeholder="Enter eid"
        />
        <label htmlFor="eid">eid</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setstarttiming(e.target.value)}
          value={starttiming}
          placeholder="Enter starttiming"
        />
        <label htmlFor="starttiming">starttiming</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setendtime(e.target.value)}
          value={endtime}
          placeholder="Enter endtime"
        />
        <label htmlFor="endtime">endtime</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setskiptilldate(e.target.value)}
          value={skiptilldate}
          placeholder="Enter skiptilldate"
        />
        <label htmlFor="skiptilldate">skiptilldate</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setstatus(e.target.value)}
          value={status}
          placeholder="Enter status"
        />
        <label htmlFor="status">status</label>
      </div>

      <input
        type="submit"
        className="btn btn-primary"
        onClick={submitdata}
        style={{ width: "100%" }}
      />
    </div>
  );
};
export default Addattendance;
