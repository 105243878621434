import React from "react";
import Header from "./course_header";
import AboutPage from "./course_about";
import CourseList from "./course_List";
import ContactPage from "./course_contact";
import Footer from "./course_footer";
import { Link } from "react-router-dom";

const Course_page = () => {
  const appStyle = {
    display: "flex",
    flexDirection: "column",
  };

  const contentStyle = {};

  const footerStyle = {
    backgroundColor: "#333",
    color: "#fff",
    textAlign: "center",
    padding: "20px",
  };

  // Custom CSS for the button
  const buttonStyle = {
    display: "inline-block",
    textDecoration: "none", // Remove underline from the button text
    color: "white",
    backgroundColor: "#28a745",
    padding: "10px 20px",
    borderRadius: "5px",
    fontWeight: "bold",
    cursor: "pointer",
    transition: "background-color 0.3s",
  };

  return (
    <div
      className="container-fuild"
      style={{
        backgroundImage:
          'url("https://www.defondos.com/images/wallpapers/cuadros%20imagenes%20para%20fondo%20de%20pantalla%20(205)-446099_800.jpeg")',
        backgroundSize: "cover",
      }}
    >
      <div style={appStyle} className="row">
        <Header />
      </div>
      <div>
        <CourseList />
      </div>
      <div style={{ textAlign: "center", color: "white" }} className="m-5">
        <Link to="/enquiry" style={buttonStyle}>
          Register Here
        </Link>
      </div>
      <div style={contentStyle}>
        <ContactPage />
      </div>
      {/* <div style={contentStyle}>
        <AboutPage />
      </div> */}
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Course_page;
