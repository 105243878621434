import Footer from "./Footer";
import Header from "./Header";
const Html = () => {
  return (
    <div className="container-fuild">
      <Header />
      <div className="container">
        <div className="row border border-dark m-1">
          <div className="row text-center m-3">
            <h1>
              <b>
                <u> HTML</u>
              </b>
            </h1>
          </div>

          <div className="row m-3">
            <h4>
              HTML is the standard markup language for Web pages. With HTML you
              can create your own Website. HTML is easy to learn.
            </h4>
          </div>

          <div className="row m-5 h4">
            <fieldset>
              <legend style={{ width: "40%" }} id="html">
                HTML:
              </legend>
              <ul>
                <li>Introduction to HTML</li>
                <li>Basic HTML Tags</li>
                <li>Formatting Tags</li>
                <li>Forms and Input Tags</li>
                <li>Multi Media Tags</li>
                <li>Link </li>
                <li>List </li>
                <li>Table</li>
                <li>Styles and Semantics</li>
              </ul>
              <legend style={{ width: "40%" }} id="css">
                CSS:
              </legend>
              <ul>
                <li>Introduction to CSS</li>
                <li>Selectors</li>
                <li>Colors</li>
                <li>Text</li>
                <li>Box Model</li>
                <li>Icons</li>
                <li>Table</li>
                <li>List</li>
                <li>Link</li>
                <li>Max-width</li>
                <li>Align</li>
                <li>Navigation bar</li>
                <li>Dropdown</li>
                <li>Float</li>
                <li>Important</li>
              </ul>
              <legend style={{ width: "40%" }} id="js">
                Java Script:
              </legend>
              <ul>
                <li>Introduction to JavaScript</li>
                <li>Variable</li>
                <li>Let</li>
                <li>Constant</li>
                <li>Operators</li>
                <li>If</li>
                <li>Switch</li>
                <li>loop</li>
                <li>Data Type</li>
                <li>Function</li>
                <li>Object</li>
                <li>String</li>
                <li>Array</li>
                <li>Dates</li>
                <li>Random</li>
                <li>Set</li>
                <li>Map</li>
                <li>RegExp</li>
                <li>Scope</li>
                <li>Json</li>
              </ul>
              <legend style={{ width: "40%" }} id="jq">
                jquery:
              </legend>
              <ul>
                <li>Introduction to jquery</li>
                <li>Syntax</li>
                <li>Selectors</li>
                <li>Hide/Show</li>
                <li>Fade</li>
                <li>Slide</li>
                <li>Animate</li>
                <li>Callback</li>
                <li>Data Type</li>
                <li>Function</li>
                <li>Get</li>
                <li>Set</li>
                <li>Traversing</li>
                <li>Ajax</li>
              </ul>
              <legend style={{ width: "40%" }} id="bs">
                Bootstrap:
              </legend>
              <ul>
                <li>Introduction to jquery</li>
                <li>All Properties</li>
              </ul>
            </fieldset>
          </div>

          <div className="row m-5">
            <h2>
              <b> Certificate:</b>
            </h2>
            <h2>
              <p>Foundation in Web Designing</p>
            </h2>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Html;
