import { useLocation } from "react-router-dom";

const Afterexam = () => {
  const { state } = useLocation();

  const i = state.slice(0, state.length - 1);
  var x = 0;
  return (
    <>
      <h1>Total Score={state[state.length - 1]}</h1>
      {i.map((d) => {
        if (d["selectanswer"] === d["rightorwrong"]) {
          return (
            <button className="btn btn-success" key={x}>
              {++x}
            </button>
          );
        } else if (d["selectanswer"] === "") {
          return (
            <button className="btn btn-primary" key={x}>
              {++x}
            </button>
          );
        } else {
          return (
            <button className="btn btn-danger" key={x}>
              {++x}
            </button>
          );
        }
      })}
      <br />
      <button
        className="btn btn-primary"
        onClick={() => {
          window.close();
        }}
      >
        Close page
      </button>
    </>
  );
};

export default Afterexam;
