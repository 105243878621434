import React from 'react';

const Footer = () => {
  return (
    <footer style={{ backgroundColor: '#007bff', color: '#fff', textAlign: 'center', padding: '10px',  bottom: '0', left: '0', width: '100%' }}>
    &copy; {new Date().getFullYear()} Edusphere Software Training and Development Institute. All rights reserved.
  </footer>
  );
};

export default Footer;
