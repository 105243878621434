import React from 'react';
import { animateScroll as scroll } from 'react-scroll';
import Header from './course_header';
import AboutPage from './course_about';
import ContactPage from './course_contact';
import Footer from './course_footer';
import logo from '../images/logo.png'; // Adjust the path based on your project structure
import { Link } from 'react-router-dom';
import PlacementsPage from './placement';

const HomePage = () => {
  const containerStyle = {
    textAlign: 'center',
    padding: '40px',
  };

  const headingStyle = {
    fontSize: '36px',
    fontWeight: 'bold',
    marginBottom: '20px',
    // color: '#007bff',
    color: "blue"
  };

  const paragraphStyle = {
    fontSize: '18px',
    textAlign: 'center',
    maxWidth: '800px',
    margin: '0 auto',
  };

  const offerContainerStyle = {
    // backgroundColor: '#f9f9f9',
    color:'white',
    padding: '2%',
    marginTop: '2%',
    marginBottom: "2%",
    marginLeft: '15vw',
    maxWidth: '70%',
    borderRadius: '30%',
  };

  const advertisingContainerStyle = {
    backgroundColor: '#007bff',
    color: '#fff',
    padding: '40px',
    borderRadius: '50px'
  };

  const scrollToAbout = () => {
    scroll.scrollTo(document.getElementById('about_par').offsetTop);
  };

  const scrollToContact = () => {
    scroll.scrollTo(document.getElementById('contact_par').offsetTop);
  };

  return (
    <>
      
        <Header />
    


      <div style={{ backgroundImage: "url('https://bs-uploads.toptal.io/blackfish-uploads/components/blog_post_page/content/cover_image_file/cover_image/1280201/retina_1708x683_cover-0325-LearnCandC__Languages_Dan_Newsletter-743100f051077054fa1cc613ff4523a2.png')", backgroundSize: "contain", opacity: '0.9' }} className='container-fuild'>
        <section className="section m-5 row bg-success" style={{ borderRadius: '100px'/*,backgroundColor:'rgba(106, 90, 205,0.9)' */}}>
          {/* Carousel with multiple offers */}
          <div
            id="offersCarousel"
            className="carousel slide"
            data-bs-ride="carousel"
            data-bs-interval="7000"
            style={{ textAlign: 'center' }}
          >
            <div className="carousel-inner" style={{ textAlign: 'center' }}>
              <div className="carousel-item active" style={{ ...offerContainerStyle }}>
                <h2>Special Offer!</h2>
                <p>Enroll in any course today and get 20% off on your first month's tuition fee.</p>
                <p>Don't miss this opportunity to kickstart your career in tech!</p>
                <Link to='/enquiry' className="btn btn-primary">Enroll Now</Link>
              </div>

              <div className="carousel-item" style={{ ...offerContainerStyle }}>
                <h2>Limited Time Offer!</h2>
                <p>Join our Python Full Stack course and get a free certification voucher.</p>
                <p>Unlock the power of Python and take your coding skills to the next level!</p>
                <Link to='/enquiry' className="btn btn-primary">Enroll Now</Link>
              </div>

              {/* Add more offer containers as needed */}
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#offersCarousel"
              data-bs-slide="prev"
            >
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#offersCarousel"
              data-bs-slide="next"
            >
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </section>
        <section id="home_par" className="section m-5" style={{ backgroundColor: 'rgba(255,255,255,0.9)', borderRadius: '100px', color: 'black' }}>
          {/* Your home page content here */}
          <div style={containerStyle}>
            {/* <img src={logo} alt="Logo" style={{ width: '150px', borderRadius: '50%' }} /> */}
            <h1 style={headingStyle}>Welcome to Edusphere Solutions</h1>
            <p style={paragraphStyle}>
              Edusphere Solutions is a leading education institute that offers a wide range of courses to help you
              achieve your academic and professional goals. Our courses are designed and taught by experienced
              instructors, and we provide a supportive learning environment to help you succeed.
            </p>
            <p style={paragraphStyle}>
              Whether you're looking to enhance your skills, pursue a new career, or simply expand your knowledge,
              we have the right course for you. Explore our course offerings and start your learning. <br /><b style={{ fontSize: '2em' }}> Join with us
                today!</b>
            </p>
            <div>
              <button className="btn btn-primary me-3" onClick={scrollToAbout}>
                Learn More
              </button>
              <button className="btn btn-outline-primary" onClick={scrollToContact}>
                Ping Us
              </button>
            </div>
          </div>
        </section>



        <section className="section m-5" >
          {/* Carousel with advertising content */}
          <div
            id="advertisingCarousel"
            className="carousel slide"
            data-bs-ride="carousel"
            data-bs-interval="7000"
          >
            <div className="carousel-inner" style={{ textAlign: 'center' }}>
              <div className="carousel-item active" style={{ ...advertisingContainerStyle }}>
                <h2>Join Edusphere Today!</h2>
                <p>Explore our diverse course offerings and unleash your potential in the world of technology.</p>
                <Link to='/course_check' className="btn btn-outline-light">View Courses</Link>
              </div>

              <div className="carousel-item" style={{ ...advertisingContainerStyle }}>
                <h2>Boost Your Career</h2>
                <p>Discover our data science courses and take your career to new heights with data-driven insights.</p>
                <Link to='/course_check' className="btn btn-outline-light">Explore Data Science</Link>
              </div>

              {/* Add more advertising containers as needed */}
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#advertisingCarousel"
              data-bs-slide="prev"
            >
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#advertisingCarousel"
              data-bs-slide="next"
            >
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </section>

        <section id="about_par" className="section" >
          {/* Your about page content here */}
          <AboutPage />
        </section>
        <section id="contact_par" className="section">
          {/* Your contact page content here */}
          <ContactPage />
        </section>
      </div>
        <PlacementsPage/>
        <Footer />
    
        {/* <style>
        {`
         /* Add this CSS to style the carousel with multiple offers 
         .carousel {
           max-width: 900px;
           margin: 0 auto;
           position: relative;
         }
         
         .carousel-inner {
           border-radius: 100px;
           overflow: hidden;
         }
         
         .carousel-item {
           display: flex;
           flex-direction: column;
           align-items: center;
           justify-content: center;
           padding: 20px;
           min-height: 200px;
         }
         
         .carousel-control-prev,
         .carousel-control-next {
           width: 5%;
         }
         
         .carousel-control-prev-icon,
         .carousel-control-next-icon {
           background-color: #007bff;
           padding: 5px;
           border-radius: 50%;
           color: #fff;
         }
         
         .carousel-control-prev-icon:before,
         .carousel-control-next-icon:before {
           content: '';
         }
         
         .carousel-control-prev-icon {
           transform: translateX(-50%);
         }
         
         .carousel-control-next-icon {
           transform: translateX(50%);
         }
         /* Add this CSS to style the sections and buttons 
.section {
  padding: 20px;
  text-align: center;
}

.btn {
  border-radius: 50px;
  font-size: 18px;
  padding: 10px 30px;
  margin-top: 10px;
}
/* Add this CSS to style the carousel with advertising content 
#advertisingCarousel {
  max-width: 900px;
  margin: 0 auto;
  position: relative;
}

.advertising-container {
  background-color: #007bff;
  color: #fff;
  padding: 40px;
  border-radius: 50px;
}

.advertising-container h2 {
  font-size: 24px;
}

.advertising-container p {
  font-size: 16px;
  max-width: 600px;
  margin: 0 auto;
}
/* Add this CSS to style the home page content 
#home_par {
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 100px;
  color: black;
  padding: 20px;
  margin-top: 20px;
}

#home_par h1 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
}

#home_par p {
  font-size: 18px;
  max-width: 800px;
  margin: 0 auto;
}

         
        `}
      </style> */}
    </>
  );
};

export default HomePage;
