import Footer from "./Footer";
import Header from "./Header";
const Sqlserver = () => {
  return (
    <div className="container-fuild">
      <Header />
      <div className="container">
        <div className="row border border-dark m-1">
          <div className="row text-center m-3">
            <h1>
              <b>
                <u>SQL Server</u>
              </b>
            </h1>
          </div>
          <div className="row m-3">
            <h4>
              SQL (Structured Query Language) is used to perform operations on
              the records stored in the database, such as updating records,
              inserting records, deleting records, creating and modifying
              database tables, views, etc.
            </h4>
          </div>
          <div className="row m-5 h4">
            <fieldset>
              <legend style={{ width: "40%" }}>Topics:</legend>
              <ul>
                <li>SQL Database</li>
                <li>SQL Table</li>
                <li>SQL Select</li>
                <li>SQL Clause</li>
                <li>SQL Order By</li>
                <li>SQL Insert</li>
                <li>SQL Update</li>
                <li>SQL Delete</li>
                <li>SQL Join</li>
                <li>SQL Keys</li>
                <li>SQL Injection</li>
                <li>SQL String Functions</li>
                <li>Misc</li>
              </ul>
            </fieldset>
          </div>
          <div className="row m-5">
            <h2>
              <b> Certificate:</b>
            </h2>
            <h2>
              <p>Database(SQL Server)</p>
            </h2>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Sqlserver;
