import logoedusphere from "../course/images/logoedusphere.jpg";
import { useLocation } from "react-router-dom";
import Nav from "../process/Nav";
import { useState, useEffect } from "react";
import axios from "axios";
import jsPDF from "jspdf";
import { url } from "../link";
const Printbill = () => {
  const { state } = useLocation();

  const [id] = useState(state[0]);
  const [reg, setreg] = useState([[], []]);

  var doc = new jsPDF("p", "pt", "a4");
  useEffect(() => {
    axios
      .post(url + "/edusphere/billeachreg", {
        bid: id,
      })
      .then((response) => {
        setreg(response.data);
      });
  }, []);
  const Checks = () => {
    if (reg[0][0] === "0000-00-00") {
      return reg[0][6];
    } else return "No Next Payment date avaiable";
  };

  const Printdata = () => {
    var k = document.querySelector("#da");
    doc.html(k, {
      callback: function (pdf) {
        pdf.save("myfile.pdf");
      },
    });
  };
  return (
    <>
      <Nav />
      <button
        className="btn btn-danger"
        onClick={Printdata}
        style={{
          width: "10%",
          height: "6vh",
          float: "right",
          marginTop: "5%",
        }}
      >
        pdf
      </button>
      <div
        style={{
          width: "57.5%",
          height: "110vh",
          border: "1px solid black",
          marginLeft: "24%",
        }}
        id="da"
      >
        <h1 style={{ textAlign: "center" }}>
          <u>Invoice </u>
        </h1>
        <div className="row" style={{ fontSize: "0.8em" }}>
          <div className="col">
            <img
              className="img"
              src={logoedusphere}
              alt="Invoce Template"
              style={{ width: "100%" }}
            />
          </div>
          <div className="col">
            <strong>
              <p style={{ color: "#F81D2D", fontSize: "1.5em" }}>
                Edu Sphere Solution
              </p>
            </strong>
            <address>
              No. 16, TMY complex,
              <br />
              Maraimalai adigal salai, <br />
              Opposite to Anthony church, <br />
              Puducherry-605004
              <br />
              Ph-9952363956
            </address>
          </div>
        </div>
        <u style={{ textAlign: "center" }}>
          <h5>INVOICE&nbsp;&nbsp;NO - {reg[0][0]}</h5>
        </u>

        <div style={{ width: "95%", marginLeft: "2%" }}>
          <hr />
          <div className="row">
            <div className="col" style={{ borderRight: "1px solid #d9d5cc" }}>
              <strong>Student Name</strong>
            </div>
            <div className="col" style={{ borderRight: "1px solid #d9d5cc" }}>
              <strong>Course Name</strong>
            </div>
            <div className="col">
              <strong>Amount paid</strong>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col" style={{ borderRight: "1px solid #d9d5cc" }}>
              <strong>{reg[0][9]}</strong>
            </div>
            <div className="col" style={{ borderRight: "1px solid #d9d5cc" }}>
              <strong>{reg[0][8]}</strong>
            </div>
            <div className="col">
              <strong>{reg[0][3]}</strong>
            </div>
          </div>
          <hr />

          <div className="row">
            <div className="col" style={{ borderRight: "1px solid #d9d5cc" }}>
              <strong>Total Fees: </strong>
            </div>
            <div className="col">
              <strong>{reg[0][2]}</strong>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col" style={{ borderRight: "1px solid #d9d5cc" }}>
              <strong>Balance to paid: </strong>
            </div>
            <div className="col">
              <strong>{reg[0][4]}</strong>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col" style={{ borderRight: "1px solid #d9d5cc" }}>
              <strong>Next Payment date: </strong>
            </div>
            <div className="col">
              <strong>{Checks()}</strong>
            </div>
          </div>
          <hr />
          <div>
            <p>
              <b>Date :{reg[0][5]}</b>
            </p>
            <br />
          </div>
          <hr />
        </div>
      </div>
    </>
  );
};

export default Printbill;
