import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Nav from "../process/Nav";
import { url } from "../link";
const Viewcontent = () => {
  const nav = useNavigate();
  const [data, setData] = useState([]);
  const [value, setvalue] = useState([]);
  useEffect(() => {
    axios.post(url + "/edusphere/viewcontent").then((response) => {
      setData(response.data);
      setvalue(response.data);
    });
  }, []);
  const viewcontent = (e) => {
    nav("/updatecontent", { state: e });
  };
  const deletec = (e) => {
    axios
      .post(url + "/edusphere/deletecontent", {
        id: e,
      })
      .then((response) => {
        axios.post(url + "/edusphere/viewcontent").then((response) => {
          setData(response.data);
          setvalue(response.data);
        });
      });
  };
  const searchdata = (e) => {
    const r = [];

    for (var k of value) {
      var v = 0;

      for (var n of k) {
        n = "" + n;
        if (n.indexOf(e) !== -1) {
          v = 1;
          break;
        }
      }
      if (v === 1) {
        r.push(k);
      }
    }
    setData(r);
  };

  return (
    <div>
      <Nav />
      <nav className="navbar navbar-expand-sm bg-danger navbar-dark">
        <div
          className="collapse navbar-collapse"
          id="navbarResponsive"
          style={{ marginTop: "5%" }}
        >
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink className="nav-link" to="/viewsubject">
                Subject
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/viewtopics">
                Topics
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/viewsubtopics">
                Subtopics
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/viewcontent">
                Content
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
      <nav
        className="navbar navbar-expand-sm bg-success navbar-dark"
        style={{ marginTop: "0%" }}
      >
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink className="nav-link" to="/addcontent">
                Addcontent
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/viewcontent">
                Viewcontent
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
      <h3>content</h3>
      <input
        type="search"
        onChange={(e) => searchdata(e.target.value)}
        className="form-select"
        placeholder="Search"
      />
      <div className="table-responsive">
        <table className="table table-bordered" id="table_id">
          <thead>
            <tr>
              <th>cid</th>
              <th>stid</th>
              <th>content</th>
              <th>samplecode</th>
              <th>output</th>
              <th>workout</th>
            </tr>
          </thead>
          <tbody>
            {data.map((d) => {
              return (
                <tr key={d[0]}>
                  <td>
                    <button
                      className="btn btn-primary"
                      onClick={() => viewcontent(d)}
                    >
                      {d[0]}
                    </button>
                  </td>
                  <td>{d[1]}</td>
                  <td>{d[2]}</td>
                  <td>{d[3]}</td>
                  <td>{d[4]}</td>
                  <td>{d[5]}</td>
                  <td>
                    <button
                      className="btn btn-primary"
                      onClick={() => deletec(d[0])}
                    >
                      delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default Viewcontent;
