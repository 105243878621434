import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Nav from "./Nav";
import { url } from "../link";
import DataTable from "react-data-table-component";
const Viewtable = ({ data }) => {
  const [columnNames, setColumnNames] = useState([]);
  const [column, setcolumn] = useState([]);

  useEffect(() => {
    if (data != undefined) {
      if (data.length > 0) {
        setColumnNames(Object.keys(data[0]));
        var x = Object.keys(data[0]);
        var d = x.map((c) => {
          return {
            name: c,
            selector: (row) => row[c],
            sortable: true,
          };
        });
        setcolumn(d);
      }
    }
  }, [data]);

  const [pending, setPending] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const customStyles = {
    table: {
      style: {
        border: "1px solid #dee2e6", // Table border
      },
    },
    headRow: {
      style: {
        borderBottom: "1px solid #dee2e6", // Header row border
      },
    },

    rows: {
      style: {
        minHeight: "72px", // override the row height
        borderBottom: "1px solid #dee2e6",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontSize: "1.2em",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        fontSize: "1em",
        borderRight: "1px solid #dee2e6",
      },
    },
  };
  return (
    <>
      <div className="dataTableContainer">
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
            const filtered = data.filter((item) =>
              Object.values(item).some((value) => {
                if (value !== null && value !== undefined) {
                  return value
                    .toString()
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase());
                }
                return false; // Handle null or undefined value, e.g., consider it not matching.
              })
            );
            setFilteredData(filtered);
          }}
          placeholder="Search..."
        />
        <DataTable
          columns={column}
          data={filteredData}
          pagination
          fixedHeader
          fixedHeaderScrollHeight="70vh"
          dense
          customStyles={customStyles}
          progressPending={pending}
        />
      </div>
    </>
  );
};

export default Viewtable;
