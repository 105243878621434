import axios from "axios";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { url } from "../link";
const Updatestaffattendance = () => {
  var rx = 0;
  const { state } = useLocation();
  const nav = useNavigate();
  const [Said, setSaid] = useState(state[rx++]);
  const [Staffname, setStaffname] = useState(state[rx++]);
  const [Morningstarttime, setMorningstarttime] = useState(state[rx++]);
  const [Morningendtime, setMorningendtime] = useState(state[rx++]);
  const [Eveningstarttime, setEveningstarttime] = useState(state[rx++]);
  const [Eveningendtime, setEveningendtime] = useState(state[rx++]);
  const submitdata = () => {
    const value = {
      said: Said,
      staffname: Staffname,
      Morningstarttime: Morningstarttime,
      Morningendtime: Morningendtime,
      eveningstarttime: Eveningstarttime,
      eveningendtime: Eveningendtime,
    };
    axios
      .post(url + "/edusphere/updatestaffattendance", value)
      .then((response) => {
        nav("/viewstaffattendance");
      });
  };
  return (
    <div>
      <h1>Add staffattendance</h1>
      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setSaid(e.target.value)}
          value={Said}
          placeholder="Enter Said"
        />
        <label htmlFor="Said">Said</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setStaffname(e.target.value)}
          value={Staffname}
          placeholder="Enter Staffname"
        />
        <label htmlFor="Staffname">Staffname</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setMorningstarttime(e.target.value)}
          value={Morningstarttime}
          placeholder="Enter Morningstarttime"
        />
        <label htmlFor="Morningstarttime">Morningstarttime</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setMorningendtime(e.target.value)}
          value={Morningendtime}
          placeholder="Enter Morningendtime"
        />
        <label htmlFor="Morningendtime">Morningendtime</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setEveningstarttime(e.target.value)}
          value={Eveningstarttime}
          placeholder="Enter Eveningstarttime"
        />
        <label htmlFor="Eveningstarttime">Eveningstarttime</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setEveningendtime(e.target.value)}
          value={Eveningendtime}
          placeholder="Enter Eveningendtime"
        />
        <label htmlFor="Eveningendtime">Eveningendtime</label>
      </div>

      <input
        type="submit"
        className="btn btn-primary"
        onClick={submitdata}
        style={{ width: "100%" }}
      />
    </div>
  );
};
export default Updatestaffattendance;
