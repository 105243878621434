import axios from "axios";
import Closewindow from "./closewindow";
import { useEffect, useState } from "react";
import { useLocation, NavLink } from "react-router-dom";
import $ from "jquery";
import { url } from "../link";
const Exam = () => {
  const { state } = useLocation();
  const [current, setcurrent] = useState(state["current"]);
  const [ques, setques] = useState("");
  const [option, setoption] = useState([]);
  const [img, setimg] = useState("");
  const [outs, setouts] = useState([[]]);
  var [v, setv] = useState(3600);

  const [all, setall] = useState(state["all"]);
  const next = () => {
    if (current < 30) {
      setcurrent(parseInt(current) + 1);
    }
  };
  const previous = () => {
    if (current > 1) {
      setcurrent(parseInt(current) - 1);
    }
  };
  const check = () => {
    try {
      if (outs[0][0] !== "") {
        for (var x = 0; x < option.length; x++) {
          if (option[x] === outs[0][0]) {
            break;
          }
        }
        $("input:radio[name=gender]")[x].checked = true;
      } else {
        $("input[name=gender]").prop("checked", false);
      }
    } catch (err) {}
  };
  const submit = () => {
    axios.post(url + "/edusphere/submitanswer", { emid: 1 });
  };
  useEffect(() => {
    val();
  }, []);
  useEffect(() => {
    axios
      .post(url + "/edusphere/questions", {
        qid: all[current][2],
      })
      .then((response) => {
        setouts(response.data["out"]);
        setques(response.data["question"][0][0]);
        setoption(response.data["question"][0].slice(1, 5));
        setimg(response.data["question"][0][5]);
      });
  }, [current]);

  const onChangeValue = (event) => {
    // var k = event.target.value;
    // axios.post(url+"/edusphere/answer", {
    //   qid: all[current][0],
    //   k: k,
    // });
  };
  const out = () => {
    return (
      <div>
        {check()}
        <h5>
          {current}. {ques}
        </h5>
        <img src={img} alt="" />
        <div onChange={onChangeValue}>
          <input type="radio" value={option[0]} name="gender" />
          {option[0]}
          <br />
          <input type="radio" value={option[1]} name="gender" /> {option[1]}
          <br />
          <input type="radio" value={option[2]} name="gender" /> {option[2]}
          <br />
          <input type="radio" value={option[3]} name="gender" /> {option[3]}
          <br />
        </div>
      </div>
    );
  };

  const val = () => {
    setInterval(function () {
      setv((v) => v - 0.5);
    }, 1000);
  };

  const value = () => {
    if (v % 60 == 0) {
      return (
        <h5 id="demo" style={{ float: "right" }}>
          {Math.floor(v / 60)}
        </h5>
      );
    } else {
      return (
        <h5 id="demo" style={{ float: "right" }}>
          {Math.floor(v / 60)}
        </h5>
      );
    }
  };

  return (
    <>
      {value()}

      <Closewindow />

      {out()}
    </>
  );
};

export default Exam;
