import { useEffect } from "react";
import { useState } from "react";
const Closewindow = () => {
  const [v, setv] = useState(0);
  useEffect(() => {
    window.addEventListener("blur", onBlur);
  });
  const onBlur = () => {

    // var x = v + 1;
    // setv(x);
    // if (x > 3) {
    //   window.close();
    // }
  };
  const check = () => {
    return (
      v +
      "times tried to change window if more then 3 times you cant take up exam"
    );
  };
  return <>{check()}</>;
};

export default Closewindow;
