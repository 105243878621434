import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Nav from "./Nav";
import { url } from "../link";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";

import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";

import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
const Pendingbal = () => {
  const nav = useNavigate();
  const [data, setData] = useState([]);
  const [value, setvalue] = useState([]);
  useEffect(() => {
    axios.post(url + "/edusphere/pendingfee").then((response) => {
      setData(response.data);
      setvalue(response.data);
      $(document).ready(function () {
        var eventFired = function (type) {
          var n = $("#demo_info")[0];

          n.scrollTop = n.scrollHeight;
        };

        $("#example")
          .on("order.dt", function () {
            eventFired("Order");
          })
          .on("search.dt", function () {
            eventFired("Search");
          })
          .on("page.dt", function () {
            eventFired("Page");
          })
          .DataTable();
      });
    });
  }, []);
  const viewregistration = (e) => {
    nav("/viewsinglereg", { state: e });
  };
  const checkdata = (e) => {
    if (e[8] !== 0) {
      return e[9];
    } else {
      return "-";
    }
  };

  return (
    <div>
      <Nav />
      <div className="main">
        <div className="page-container">
          <h3>Pending Balance</h3>
          <div className="table-responsive p-0 pb-2">
            <div
              id="demo_info"
              className="box"
              style={{ display: "none" }}
            ></div>
            <table id="example" className="display" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>Rid</th>
                  <th>Name</th>
                  <th>Mobile</th>
                  <th>Dob</th>
                  <th>Email</th>
                  <th>Course</th>
                  <th>Class by</th>
                  <th>Price</th>
                  <th>Bal</th>
                  <th>NextPayment</th>
                  <th>Joined</th>
                </tr>
              </thead>
              <tbody>
                {data.map((d) => {
                  return (
                    <tr key={d[0]}>
                      <td>
                        <button
                          className="btn btn-primary"
                          onClick={() => viewregistration(d)}
                        >
                          {d[0]}
                        </button>
                      </td>
                      <td>{d[1]}</td>
                      <td>{d[2]}</td>
                      <td>{d[3]}</td>
                      <td>{d[4]}</td>
                      <td>{d[5]}</td>
                      <td>{d[6]}</td>
                      <td>{d[7]}</td>
                      <td>{d[8]}</td>
                      <td>{checkdata(d)}</td>
                      <td>{d[10]}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Pendingbal;
