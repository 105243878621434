import axios from "axios";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import Nav from "../process/Nav";
import { url } from "../link";
const Addsubject = () => {
  const [subject_name, setsubject_name] = useState("");
  const submitdata = () => {
    const value = { subject_name: subject_name };
    axios.post(url + "/edusphere/insertsubject", value);
    alert("success");
    setsubject_name("");
  };
  return (
    <div>
      <Nav />
      {/* <nav
        className="navbar navbar-expand-sm bg-success navbar-dark"
        style={{ marginTop: "5%" }}
      >
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink className="nav-link" to="/viewsubject">
                Subject
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/viewtopics">
                Topics
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/viewsubtopics">
                Subtopics
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/viewcontent">
                Content
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
      <nav
        className="navbar navbar-expand-sm bg-success navbar-dark"
        style={{ marginTop: "5%" }}
      >
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink className="nav-link" to="/addsubject">
                Addsubject
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/viewsubject">
                Viewsubject
              </NavLink>
            </li>
          </ul>
        </div>
      </nav> */}
      <h1>Add subject</h1>
      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setsubject_name(e.target.value)}
          value={subject_name}
          placeholder="Enter subject_name"
        />
        <label htmlFor="subject_name">subject_name</label>
      </div>

      <input
        type="submit"
        className="btn btn-primary"
        onClick={submitdata}
        style={{ width: "100%" }}
      />
    </div>
  );
};
export default Addsubject;
