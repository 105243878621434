import axios from "axios";
import { useState } from "react";
import Nav from "./Nav";
import { url } from "../link";
import { useNavigate } from "react-router-dom";

const Editprofile = () => {
  const [data] = useState(window.localStorage.getItem("user").split(","));
  const [id] = useState(data[0]);
  const [name, setname] = useState(data[1]);
  const [password, setpassword] = useState(data[2]);
  const [email, setemail] = useState(data[3]);
  const [role, setrole] = useState(data[4]);
  const nav = useNavigate();
  const submitdata = () => {
    const value = {
      id: id,
      name: name,
      password: password,
      email: email,
      role: role,
    };
    axios.post(url + "/edusphere/updatelogin", value).then((response) => {
      alert("update successfully kindly login again");
      window.localStorage.removeItem("user");

      nav("/login");
    });
  };
  return (
    <div>
      <Nav />
      <div className="main">
        <div className="page-container">
          <h1>Edit Profile</h1>
          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              value={id}
              placeholder="Enter id"
              disabled
            />
            <label htmlFor="id">id</label>
          </div>

          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => setname(e.target.value)}
              value={name}
              placeholder="Enter name"
            />
            <label htmlFor="name">name</label>
          </div>

          <div className="form-floating mb-3 mt-3">
            <input
              type="password"
              className="form-control"
              onChange={(e) => setpassword(e.target.value)}
              value={password}
              placeholder="Enter password"
            />
            <label htmlFor="password">password</label>
          </div>

          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => setemail(e.target.value)}
              value={email}
              placeholder="Enter email"
            />
            <label htmlFor="email">email</label>
          </div>

          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => setrole(e.target.value)}
              value={role}
              placeholder="Enter role"
            />
            <label htmlFor="role">role</label>
          </div>

          <input
            type="submit"
            className="btn btn-primary"
            onClick={submitdata}
            style={{ width: "100%" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Editprofile;
