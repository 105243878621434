import axios from "axios";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Nav from "../process/Nav";
import { url } from "../link";
const Addmobileremark = () => {
  const { state } = useLocation();

  const [remark, setremark] = useState("");
  const [trandate, settrandate] = useState("");
  const [mid, setmid] = useState(state);
  const nav = useNavigate();
  const submitdata = () => {
    const value = { remark: remark, trandate: trandate, mid: mid };
    axios.post(url + "/edusphere/insertmobileremark", value).then((res) => {
      nav("/viewmobileenquiry");
    });
  };
  return (
    <div>
      <Nav />
      <h1>Add mobileremark</h1>
      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setremark(e.target.value)}
          value={remark}
          placeholder="Enter remark"
        />
        <label htmlFor="remark">remark</label>
      </div>

      {/* <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => settrandate(e.target.value)}
          value={trandate}
          placeholder="Enter trandate"
        />
        <label htmlFor="trandate">trandate</label>
      </div> */}

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setmid(e.target.value)}
          value={mid}
          placeholder="Enter mid"
          readOnly
        />
        <label htmlFor="mid">mid</label>
      </div>

      <input
        type="submit"
        className="btn btn-primary"
        onClick={submitdata}
        style={{ width: "100%" }}
      />
    </div>
  );
};
export default Addmobileremark;
