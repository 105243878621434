import axios from "axios";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Nav from "../process/Nav";
import { url } from "../link";
const Updateregistration = () => {
  var rx = 0;
  const { state } = useLocation();
  const nav = useNavigate();
  const [rid, setrid] = useState(state[rx++]);
  const [eid, seteid] = useState(state[rx++]);
  const [courseid, setcourseid] = useState(state[rx++]);
  const [feejoined, setfeejoined] = useState(state[rx++]);
  const [nexpamentdate, setnexpamentdate] = useState(state[rx++]);
  const [bal, setbal] = useState(state[rx++]);
  const [attempt, setattempt] = useState(state[rx++]);
  const [qpaper, setqpaper] = useState(state[rx++]);
  const [classby, setclassby] = useState(state[rx++]);
  const [bookissue, setbookissue] = useState(state[rx++]);
  const [rstatus, setrstatus] = useState(state[rx++]);
  const [dob, setdob] = useState(state[rx++]);
  const submitdata = () => {
    const value = {
      rid: rid,
      eid: eid,
      courseid: courseid,
      feejoined: feejoined,
      nexpamentdate: nexpamentdate,
      bal: bal,

      attempt: attempt,
      qpaper: qpaper,
      classby: classby,
      bookissue: bookissue,
      rstatus: rstatus,
      dob: dob,
    };
    axios.post(url + "/edusphere/updateregistration", value).then(() => {
      nav("/viewregistration");
    });
  };
  return (
    <div>
      <Nav />
      <div className="main">
        <div className="page-container">
          <h1>Edit registration</h1>
          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => setrid(e.target.value)}
              value={rid}
              placeholder="Enter rid"
            />
            <label htmlFor="rid">rid</label>
          </div>

          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => seteid(e.target.value)}
              value={eid}
              placeholder="Enter eid"
            />
            <label htmlFor="eid">eid</label>
          </div>

          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => setcourseid(e.target.value)}
              value={courseid}
              placeholder="Enter courseid"
            />
            <label htmlFor="courseid">courseid</label>
          </div>

          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => setfeejoined(e.target.value)}
              value={feejoined}
              placeholder="Enter feejoined"
            />
            <label htmlFor="feejoined">feejoined</label>
          </div>

          <div className="form-floating mb-3 mt-3">
            <input
              type="date"
              className="form-control"
              onChange={(e) => setnexpamentdate(e.target.value)}
              value={nexpamentdate}
              placeholder="Enter nexpamentdate"
            />
            <label htmlFor="nexpamentdate">nexpamentdate</label>
          </div>

          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => setbal(e.target.value)}
              value={bal}
              placeholder="Enter bal"
            />
            <label htmlFor="bal">bal</label>
          </div>

          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => setattempt(e.target.value)}
              value={attempt}
              placeholder="Enter attempt"
            />
            <label htmlFor="attempt">attempt</label>
          </div>

          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => setqpaper(e.target.value)}
              value={qpaper}
              placeholder="Enter qpaper"
            />
            <label htmlFor="qpaper">qpaper</label>
          </div>

          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => setclassby(e.target.value)}
              value={classby}
              placeholder="Enter classby"
            />
            <label htmlFor="classby">classby</label>
          </div>

          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => setbookissue(e.target.value)}
              value={bookissue}
              placeholder="Enter bookissue"
            />
            <label htmlFor="bookissue">bookissue</label>
          </div>

          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => setrstatus(e.target.value)}
              value={rstatus}
              placeholder="Enter rstatus"
            />
            <label htmlFor="rstatus">rstatus</label>
          </div>

          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => setdob(e.target.value)}
              value={dob}
              placeholder="Enter dob"
            />
            <label htmlFor="dob">Date of Registration</label>
          </div>

          <input
            type="submit"
            className="btn btn-primary"
            onClick={submitdata}
            style={{ width: "100%" }}
          />
        </div>
      </div>
    </div>
  );
};
export default Updateregistration;
