import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "./logo512.jpg";
import Navbar from "../components/Navbar";

const Nav = () => {
  const nav = useNavigate();

  useEffect(() => {
    if (window.localStorage.getItem("user") === null) {
      nav("/login");
    }
  }, []);
  return <Navbar />;
};

export default Nav;
