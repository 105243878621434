import axios from "axios";
import { useState } from "react";
import { url } from "../link";
const Addmonthlyexpense = () => {
  const [name, setname] = useState("");
  const [amount, setamount] = useState("");
  const [paymentdate, setpaymentdate] = useState("");
  const [status, setstatus] = useState("");
  const submitdata = () => {
    const value = {
      name: name,
      amount: amount,
      paymentdate: paymentdate,
      status: status,
    };
    axios.post(url + "/edusphere/insertmonthlyexpense", value);
    alert("success");
    setname("");
    setamount("");
    setpaymentdate("");
    setstatus("");
  };
  return (
    <div>
      <h1>Add monthlyexpense</h1>
      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setname(e.target.value)}
          value={name}
          placeholder="Enter name"
        />
        <label htmlFor="name">name</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setamount(e.target.value)}
          value={amount}
          placeholder="Enter amount"
        />
        <label htmlFor="amount">amount</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setpaymentdate(e.target.value)}
          value={paymentdate}
          placeholder="Enter paymentdate"
        />
        <label htmlFor="paymentdate">paymentdate</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setstatus(e.target.value)}
          value={status}
          placeholder="Enter status"
        />
        <label htmlFor="status">status</label>
      </div>

      <input
        type="submit"
        className="btn btn-primary"
        onClick={submitdata}
        style={{ width: "100%" }}
      />
    </div>
  );
};
export default Addmonthlyexpense;
