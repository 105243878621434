import axios from "axios";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { url } from "../link";
const Updateprojectcommentment = () => {
  var rx = 0;
  const { state } = useLocation();
  const nav = useNavigate();
  const [prcid, setprcid] = useState(state[rx++]);
  const [commited, setcommited] = useState(state[rx++]);
  const [status, setstatus] = useState(state[rx++]);
  const [pid, setpid] = useState(state[rx++]);
  const submitdata = () => {
    const value = {
      prcid: prcid,
      commited: commited,
      status: status,
      pid: pid,
    };
    axios
      .post(url + "/edusphere/updateprojectcommentment", value)
      .then((response) => {
        nav("/viewprojectcommentment");
      });
  };
  return (
    <div>
      <h1>Add projectcommentment</h1>
      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setprcid(e.target.value)}
          value={prcid}
          placeholder="Enter prcid"
        />
        <label htmlFor="prcid">prcid</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setcommited(e.target.value)}
          value={commited}
          placeholder="Enter commited"
        />
        <label htmlFor="commited">commited</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setstatus(e.target.value)}
          value={status}
          placeholder="Enter status"
        />
        <label htmlFor="status">status</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setpid(e.target.value)}
          value={pid}
          placeholder="Enter pid"
        />
        <label htmlFor="pid">pid</label>
      </div>

      <input
        type="submit"
        className="btn btn-primary"
        onClick={submitdata}
        style={{ width: "100%" }}
      />
    </div>
  );
};
export default Updateprojectcommentment;
