import axios from "axios";
import { useState, useEffect } from "react";
import Nav from "./Nav";
import { url } from "../link";
import { useLocation, useNavigate } from "react-router-dom";
const Addbill = () => {
  const nav = useNavigate();
  const { state } = useLocation();
  const [data, setdata] = useState([]);
  const [mode, setmode] = useState("cash");

  var MyDate = new Date();
  var MyDateString =
    MyDate.getFullYear() +
    "-" +
    ("0" + (MyDate.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + MyDate.getDate()).slice(-2);

  const [price, setprice] = useState("");
  const [paid, setpaid] = useState("");
  const [balance, setbalance] = useState(0);
  const [alreadypaid, setalreadypaid] = useState("");

  const [paymentdate, setpaymentdate] = useState(MyDateString);
  const [nextpaymentdate, setnextpaymentdate] = useState("");
  const [rid, setrid] = useState("");
  const [my, setmy] = useState("");
  const [next, setnext] = useState("");

  useEffect(() => {
    if (state !== null) {
      var e = state[0];
      setmy(e);
      setrid(e.split("-")[0]);
      setprice(e.split("-")[3]);
      setalreadypaid(e.split("-")[4]);
      setbalance(e.split("-")[4]);
    }
    axios.post(url + "/edusphere/searchbill").then((response) => {
      setdata(response.data);
    });
  }, []);
  const submitdata = () => {
    const value = {
      rid: rid,
      price: price,
      paid: paid,
      balance: balance,
      paymentdate: paymentdate,
      nextpaymentdate: nextpaymentdate,
      mode: mode,
      next: next,
      mobile: my.split("-")[2],
    };
    axios.post(url + "/edusphere/insertbill", value).then((res) => {
      alert("Payment success");
      nav("/viewbill");
    });
  };
  const setvalue = (e) => {
    setmy(e);
    setrid(e.split("-")[0]);
    setprice(e.split("-")[3]);
    setalreadypaid(e.split("-")[4]);
    setbalance(e.split("-")[4]);
  };

  const setoption = () => {
    var x = 0;
    return (
      <>
        <div className="form-floating mb-3 mt-3">
          <input
            list="browsers"
            name="browser"
            id="browser"
            className="form-control"
            value={my}
            onChange={(e) => setvalue(e.target.value)}
          />
          <datalist id="browsers">
            {data.map((d) => {
              return <option value={d} key={x++} />;
            })}
          </datalist>

          <label htmlFor="rid">Reg details</label>
        </div>
      </>
    );
  };
  const setamount = (e) => {
    setpaid(e);
    var k = parseInt(alreadypaid) - parseInt(e);
    setbalance(k);
  };
  return (
    <div>
      <Nav />
      <div className="main">
        <div className="page-container">
          <h1>Add bill</h1>
          {setoption()}

          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => setprice(e.target.value)}
              value={price}
              placeholder="Enter price"
              disabled
            />
            <label htmlFor="price">Totalprice</label>
          </div>
          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              value={alreadypaid}
              placeholder="Enter paid"
              disabled
            />
            <label htmlFor="paid">Balance to be Paid</label>
          </div>
          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => {
                if (e.target.value.length != 0) {
                  setamount(e.target.value);
                } else {
                  setamount("");
                  setbalance(my.split("-")[4]);
                }
              }}
              value={paid}
              placeholder="Enter paid"
            />
            <label htmlFor="paid">Payment</label>
          </div>

          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              value={balance}
              placeholder="Enter balance"
              disabled
            />
            <label htmlFor="balance">balance</label>
          </div>

          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => {
                setpaymentdate(e.target.value);
              }}
              value={paymentdate}
              placeholder="Enter paymentdate"
            />
            <label htmlFor="paymentdate">paymentdate</label>
          </div>

          {balance !== 0 ? (
            <>
              <div className="form-floating mb-3 mt-3">
                <input
                  type="date"
                  className="form-control"
                  onChange={(e) => setnextpaymentdate(e.target.value)}
                  value={nextpaymentdate}
                  placeholder="Enter Nextpaymentdate"
                />
                <label htmlFor="paymentdate">Next paymentdate</label>
              </div>

              <div className="form-floating mb-3 mt-3">
                <input
                  type="number"
                  className="form-control"
                  onChange={(e) => setnext(e.target.value)}
                  value={next}
                  placeholder="Enter Next"
                />
                <label htmlFor="paymentdate">Next Amount</label>
              </div>
            </>
          ) : (
            <></>
          )}

          <input
            type="submit"
            className="btn btn-primary"
            onClick={submitdata}
            style={{ width: "100%" }}
          />
        </div>
      </div>
    </div>
  );
};
export default Addbill;
