import axios from "axios";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Nav from "../process/Nav";
import { url } from "../link";
const Updatetopics = () => {
  var rx = 0;
  const { state } = useLocation();
  const [tid, settid] = useState(state[rx++]);
  const [topic_name, settopic_name] = useState(state[rx++]);
  const [sid, setsid] = useState(state[rx++]);
  const nav = useNavigate();
  const submitdata = () => {
    const value = { tid: tid, topic_name: topic_name, sid: sid };
    axios.post(url + "/edusphere/updatetopics", value).then(() => {
      nav("/addsubject");
    });
    settid("");
    settopic_name("");
    setsid("");
  };
  return (
    <div>
      <Nav />
      <nav
        className="navbar navbar-expand-sm bg-success navbar-dark"
        style={{ marginTop: "5%" }}
      >
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink className="nav-link" to="/viewsubject">
                Subject
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/viewtopics">
                Topics
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/viewsubtopics">
                Subtopics
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/viewcontent">
                Content
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
      <h1>Add topics</h1>
      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => settid(e.target.value)}
          value={tid}
          placeholder="Enter tid"
        />
        <label htmlFor="tid">tid</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => settopic_name(e.target.value)}
          value={topic_name}
          placeholder="Enter topic_name"
        />
        <label htmlFor="topic_name">topic_name</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setsid(e.target.value)}
          value={sid}
          placeholder="Enter sid"
        />
        <label htmlFor="sid">sid</label>
      </div>

      <input
        type="submit"
        className="btn btn-primary"
        onClick={submitdata}
        style={{ width: "100%" }}
      />
    </div>
  );
};
export default Updatetopics;
