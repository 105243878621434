import axios from "axios";
import { useState } from "react";
import { url } from "../link";
import { useLocation, useNavigate } from "react-router-dom";
const Updateattendance = () => {
  var rx = 0;
  const { state } = useLocation();
  const nav = useNavigate();
  const [aid, setaid] = useState(state[rx++]);
  const [eid, seteid] = useState(state[rx++]);
  const [starttiming, setstarttiming] = useState(state[rx++]);
  const [endtime, setendtime] = useState(state[rx++]);
  const [skiptilldate, setskiptilldate] = useState(state[rx++]);
  const [status, setstatus] = useState(state[rx++]);
  const submitdata = () => {
    const value = {
      aid: aid,
      eid: eid,
      starttiming: starttiming,
      endtime: endtime,
      skiptilldate: skiptilldate,
      status: status,
    };
    axios.post(url + "/edusphere/updateattendance", value).then((response) => {
      nav("/viewattendance");
    });
  };
  return (
    <div>
      <h1>Add attendance</h1>
      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setaid(e.target.value)}
          value={aid}
          placeholder="Enter aid"
        />
        <label htmlFor="aid">aid</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => seteid(e.target.value)}
          value={eid}
          placeholder="Enter eid"
        />
        <label htmlFor="eid">eid</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setstarttiming(e.target.value)}
          value={starttiming}
          placeholder="Enter starttiming"
        />
        <label htmlFor="starttiming">starttiming</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setendtime(e.target.value)}
          value={endtime}
          placeholder="Enter endtime"
        />
        <label htmlFor="endtime">endtime</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setskiptilldate(e.target.value)}
          value={skiptilldate}
          placeholder="Enter skiptilldate"
        />
        <label htmlFor="skiptilldate">skiptilldate</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setstatus(e.target.value)}
          value={status}
          placeholder="Enter status"
        />
        <label htmlFor="status">status</label>
      </div>

      <input
        type="submit"
        className="btn btn-primary"
        onClick={submitdata}
        style={{ width: "100%" }}
      />
    </div>
  );
};
export default Updateattendance;
