import Footer from "./Footer";
import Header from "./Header";
const Datascience = () => {
  return (
    <div className="container-fuild">
      <Header />

      <div className="container">
        <div className="row border border-dark m-1">
          <div className="row text-center m-3">
            <h1>
              <b>
                <u> Data Science</u>
              </b>
            </h1>
          </div>

          <div className="row m-3">
            <h4>
              Data Science certification program is designed for working
              professionals and covers job-critical topics like R, Python,
              Machine Learning techniques, NLP notions, and Data Visualization
              with Tableau, using an active learning model that includes live
              sessions from global professionals, practical labs, IBM
              Hackathons, and corporate ready projects.
            </h4>
          </div>

          <div className="row m-5 h4">
            <fieldset>
              <legend style={{ width: "40%" }}>Topics:</legend>
              <ul>
                <li>Introduction to Data Science</li>
                <li>Basic Python</li>
                <li>Data type in python</li>
                <li>Pandas</li>
                <li>Numpy</li>
                <li>Sci-ki learn</li>
                <li>Mat plot</li>
                <li>CSV</li>
                <li>Python data objects</li>
                <li>Manipulating Data</li>
                <li>Statistics Basics</li>
                <li>Probability basics</li>
                <li>Standard deviation</li>
                <li>Bais variance Tradeoff</li>
                <li>Distance metrics</li>
                <li>Error metrics</li>
                <li>Machine Learning</li>
                <li>Unsuprevised Learning</li>
                <li>SVM</li>
                <li>ML alogrithms</li>
                <li>AI</li>
                <li>NLP</li>
                <li>Tableau</li>
                <li>Data Base</li>
              </ul>
            </fieldset>
          </div>

          <div className="row m-5">
            <h2>
              <b> Certificate:</b>
            </h2>
            <h2>
              <p>Programming in Data Science</p>
            </h2>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Datascience;
