import axios from "axios";
import { useState } from "react";
import { url } from "../link";
const Addamountavailble = () => {
  const [amount, setamount] = useState("");
  const [mode, setmode] = useState("");
  const submitdata = () => {
    const value = { amount: amount, mode: mode };
    axios.post(url + "/edusphere/insertamountavailble", value);
    alert("success");
    setamount("");
    setmode("");
  };
  return (
    <div>
      <h1>Add amountavailble</h1>
      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setamount(e.target.value)}
          value={amount}
          placeholder="Enter amount"
        />
        <label htmlFor="amount">amount</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setmode(e.target.value)}
          value={mode}
          placeholder="Enter mode"
        />
        <label htmlFor="mode">mode</label>
      </div>

      <input
        type="submit"
        className="btn btn-primary"
        onClick={submitdata}
        style={{ width: "100%" }}
      />
    </div>
  );
};
export default Addamountavailble;
