import { NavLink } from "react-router-dom";
const Footer = () => {
  return (
    <>
      <div className="row">
        <div className="row">
          <div className="col"></div>
          <div className="col"></div>
          <div className="col">
            <NavLink
              to="https://www.facebook.com/profile.php?id=100077324284875/"
              className="fa fa-facebook">
              {" "}
            </NavLink>
          </div>
          <div className="col">
            <NavLink
              to="https://twitter.com/Eduspherepdy?t=g5A5XOmrlKCxCGvjBpFiTg&s=09"
              className="fa fa-twitter">
              {" "}
            </NavLink>
          </div>
          <div className="col">
            <NavLink
              to="https://www.linkedin.com/in/edusphere-solutions-314a3324a/"
              className="fa fa-linkedin">
              {" "}
            </NavLink>
          </div>
          <div className="col">
            <NavLink
              to="https://www.instagram.com/eduspheresolutions/"
              className="fa fa-instagram"></NavLink>
          </div>
          <NavLink to="#" className="fa fa-youtube">
            {" "}
          </NavLink>
        </div>
        <div className="col">
          <NavLink
            to="https://www.google.com/search?q=edusphere+software+training+and+development+institute&rlz=1C1ONGR_enIN1017IN1017&tbm=lcl&sxsrf=ALiCzsbq3WMxfJrUgksOrsIOda06lkV_EA%3A1662026785897&ei=IYQQY5qyNq6RseMP062LwA8&oq=edusphere+&gs_lcp=Cg1nd3Mtd2l6LWxvY2FsEAMYATIECCMQJzIECCMQJzIECCMQJzIFCAAQgAQyCggAEIAEEIcCEBQyBQgAEIAEMgUIABCABDIFCAAQgAQyBAgAEEMyBAgAEApQc1hzYPENaABwAHgAgAFTiAGbAZIBATKYAQCgAQHAAQE&sclient=gws-wiz-local#rlfi=hd:;si:16680252885623005235,l,CjVlZHVzcGhlcmUgc29mdHdhcmUgdHJhaW5pbmcgYW5kIGRldmVsb3BtZW50IGluc3RpdHV0ZUjO-NXy_7SAgAhaTxAAEAEQAhADEAQQBRgAGAEYAhgDGAQYBSI1ZWR1c3BoZXJlIHNvZnR3YXJlIHRyYWluaW5nIGFuZCBkZXZlbG9wbWVudCBpbnN0aXR1dGWSARdlZHVjYXRpb25hbF9pbnN0aXR1dGlvbg;mv:[[11.9354803,79.8216437],[11.9308315,79.80686089999999]]"
            className="fa fa-google">
            {" "}
          </NavLink>
        </div>
        <div className="col"></div>
        <div className="col"></div>
      </div>
      <div className="row bg-secondary text-white m-3">
        <div className="col m-5 mt-2">
          <h2>About us:</h2>
          <p>
            We are committed to providing the best professional education to our
            students and to turn them into knowledgeable and successful
            information technology professionals. For that reason our company
            invests in new facilities, the latest software and newest hardware
            and hires talented teachers and assistants.
          </p>
        </div>
        <div className="col m-5 mt-2">
          <h2> Contact us:</h2>
          <p>
            <span className="h4 text-black">
              <b> R. Hariharan </b>
            </span>
            (Managing Director) <br />
            <NavLink to="tel:+91-9952363956" className="text-white">
              +91-9952363956 <br />
            </NavLink>
            <a
              className="text-white"
              href="mailto:hariharanr@eduspheresolutions.com">
              hariharanr@eduspheresolutions.com
            </a>
          </p>
          <p>
            <span className="h4 text-black">
              <b> B. Rajasekar </b>
            </span>
            (CEO) <br />
            <NavLink to="tel:+91-8807796595" className="text-white">
              +91-8807796595 <br />
            </NavLink>
            <NavLink
              to="mailto:rajasekar.rk@eduspheresolutions.com"
              className="text-white">
              rajasekar.rk@eduspheresolutions.com
            </NavLink>
          </p>
          <p>
            <span className="h4 text-black">
              <b> R. Rajesh </b>
            </span>
            (Executive Director) <br />
            <NavLink to="tel:+91-7092043942" className="text-white">
              +91-7092043942 <br />
            </NavLink>
            <NavLink
              to="mailto:rajesh@eduspheresolutions.com"
              className="text-white">
              rajesh@eduspheresolutions.com
            </NavLink>
          </p>

          <h4>
            {" "}
            <b>Office Mail:</b>
          </h4>
          <NavLink
            to="mailto:edusphere@eduspheresolutions.com"
            className="text-white">
            edusphere@eduspheresolutions.com
          </NavLink>
        </div>
      </div>{" "}
    </>
  );
};

export default Footer;
