import React from 'react';
import Header from './course_header'

import ContactPage from './course_contact';
import Footer from './course_footer';

const Only_contact = () => {


  return (
    <>
      <Header />

      
 
        
          {/* Your contact page content here */}
          <ContactPage />
        
      


      

      <Footer />
    </>
  );
};

export default Only_contact;
