import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Nav from "../process/Nav";
import { NavLink } from "react-router-dom";
import { url } from "../link";
import $ from "jquery";
const Viewexpense = () => {
  const nav = useNavigate();
  const [data, setData] = useState([]);
  useEffect(() => {
    axios.post(url + "/edusphere/viewexpense").then((response) => {
      setData(response.data);
      $(document).ready(function () {
        var eventFired = function (type) {
          var n = $("#demo_info")[0];

          n.scrollTop = n.scrollHeight;
        };

        $("#example")
          .on("order.dt", function () {
            eventFired("Order");
          })
          .on("search.dt", function () {
            eventFired("Search");
          })
          .on("page.dt", function () {
            eventFired("Page");
          })
          .DataTable();
      });
    });
  }, []);
  const viewexpense = (e) => {
    nav("/updateexpense", { state: e });
  };
  const deletec = (e) => {
    const value = { id: e[0], expense: e[1], amount: e[2], mode: e[4] };

    axios.post(url + "/edusphere/deleteexpense", value).then((response) => {
      nav("/dashboard");
    });
  };

  return (
    <div>
      <Nav />
      <div className="main">
        <div className="page-container">
          <h3>
            Expense
            <NavLink className="nav-link col" to="/addexpense">
              <button className="btn btn-danger">Add Expense</button>
            </NavLink>
          </h3>
          <div className="table-responsive p-0 pb-2">
            <div
              id="demo_info"
              className="box"
              style={{ display: "none" }}
            ></div>
            <table id="example" className="display" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>id</th>
                  <th>expense</th>
                  <th>amount</th>
                  <th>curdate</th>
                  <th>mode</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data.map((d) => {
                  return (
                    <tr key={d[0]}>
                      <td>
                        <button
                          className="btn btn-primary"
                          onClick={() => viewexpense(d)}
                        >
                          {d[0]}
                        </button>
                      </td>
                      <td>{d[1]}</td>
                      <td>{d[2]}</td>
                      <td>{d[3]}</td>
                      <td>{d[4]}</td>
                      <td>
                        <button
                          className="btn btn-primary"
                          onClick={() => deletec(d)}
                        >
                          delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Viewexpense;
