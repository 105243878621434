import axios from "axios";
import { useState } from "react";
import Nav from "../process/Nav";
import { url } from "../link";
const Addexpense = () => {
  const [expense, setexpense] = useState("");
  const [amount, setamount] = useState("");
  const [mode, setmode] = useState("cash");
  var MyDate = new Date();
  var MyDateString =
    MyDate.getFullYear() +
    "-" +
    ("0" + (MyDate.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + MyDate.getDate()).slice(-2);
  const [curdate, setcurdate] = useState(MyDateString);
  const submitdata = () => {
    const value = {
      expense: expense,
      amount: amount,
      curdate: curdate,
      mode: mode,
    };
    axios.post(url + "/edusphere/insertexpense", value);
    alert("success");
    setexpense("");
    setamount("");

    setmode("cash");
  };
  return (
    <>
      <Nav />
      <div className="main">
        <div className="page-container">
          <h1>Add expense</h1>
          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => setexpense(e.target.value)}
              value={expense}
              placeholder="Enter expense"
            />
            <label htmlFor="expense">expense</label>
          </div>

          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => setamount(e.target.value)}
              value={amount}
              placeholder="Enter amount"
            />
            <label htmlFor="amount">amount</label>
          </div>

          <div className="form-floating mb-3 mt-3">
            <input
              type="date"
              className="form-control"
              onChange={(e) => setcurdate(e.target.value)}
              value={curdate}
              placeholder="Enter curdate"
            />
            <label htmlFor="curdate">curdate</label>
          </div>

          {/* <div className="form-floating mb-3 mt-3">
            <select
              onChange={(e) => setmode(e.target.value)}
              className="form-control">
              <option>select</option>
              <option>cash</option>
              <option>Bank</option>
            </select>

            <label htmlFor="curdate">curdate</label>
          </div> */}

          <input
            type="submit"
            className="btn btn-primary"
            onClick={submitdata}
            style={{ width: "100%" }}
          />
        </div>
      </div>
    </>
  );
};
export default Addexpense;
