// AboutPage.js
import React from 'react';
import logo from '../images/logo.png'; // Adjust the path based on your project structure
import './AboutPage.css'; // Import the CSS file for this component

const AboutPage = () => {
  return (
    <div className="about-page-container">
      <h2 className="text-center mb-5 text-dark">About Edusphere Software Training and Development Institute</h2>
      <div className="container about-content-container">
        <div className="row">
          <div className="col-md-6">
            <img
              src={logo}
              alt="About Us Image"
              className="img-fluid rounded d-flex"
              style={{ width: '100%', height: 'auto' }}
            />
          </div>
          <div className="col-md-6 text-white">
            <p>
              Welcome to <b className="about-title"> Edusphere Solutions</b>, your premier destination for comprehensive software training and development in Pondicherry! As a registered education institute under MSME, we are dedicated to empowering individuals with cutting-edge skills that propel their careers to new heights.
            </p>
            <p>
              <strong className="about-subtitle">Our Vision</strong>
              <br />
              At <b className="about-title"> Edusphere Solutions</b>, our vision is to be a leading force in the education sector, fostering a culture of continuous learning and innovation. We strive to create a nurturing environment where students can explore their potential and excel in the rapidly evolving tech landscape.
            </p>
            <p>
              <strong className="about-subtitle">Our Mission</strong>
              <br />
              Our mission is to provide top-notch training programs that not only impart knowledge but also instill confidence and a problem-solving mindset in our students. By combining theoretical expertise with practical application, we aim to produce skilled professionals who can make a significant impact in the software industry.
            </p>
            <p>
              <strong className="about-subtitle">Our Diverse Course Offerings</strong>
              <br />
              We take immense pride in our diverse range of meticulously crafted courses, catering to individuals of all backgrounds and expertise levels. Our offerings include:
            </p>
            <ul>
              <li><b> Programming Languages:</b> Learn the foundational languages of the tech world, including C, C++, Java, C# and Python.</li>
              <li><b> Web Development:</b> Master Java Full Stack and Python Full Stack to create dynamic and responsive web applications.</li>
              <li><b> Data Science:</b> Dive into the world of data analytics and data-driven decision-making.</li>
              <li><b> User Experience (UX) and User Interface (UI) Design:</b> Create intuitive and visually appealing digital experiences for users.</li>
              <li><b> Business Applications:</b> Acquire proficiency in Tally and MS Office to streamline business operations.</li>
            </ul>
            <p>
              <strong className="about-subtitle">Why Choose Edusphere?</strong>
              <br />
              <ul>
                <li><b> Expert Faculty:</b> Our instructors are seasoned professionals with extensive industry experience, ensuring you receive practical insights.</li>
                <li><b> Hands-on Learning: </b>We believe in learning by doing, which is why our courses emphasize hands-on projects and real-world scenarios.</li>
                <li><b> Personalized Attention:</b> With small batch sizes, we provide personalized attention to each student, nurturing a conducive learning environment.</li>
                <li><b> Placement Support:</b> We are committed to your success. Our placement assistance helps you connect with potential employers and job opportunities.</li>
              </ul>
            </p>
            <p>
              <strong className="about-subtitle">Join Us Today!</strong>
              <br />
            </p>
            <p>
              Whether you're a student looking to launch a career or a professional seeking to upskill,<b className="about-title">  Edusphere Software Training and Development Institute</b> welcomes you to join our community of learners. Let us help you unlock your potential and achieve your aspirations in the world of technology.
            </p>
            <p>
              Discover the transformative power of education with <b className="about-title"> Edusphere Solutions</b>.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
