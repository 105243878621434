import Footer from "./Footer";
import Header from "./Header";
const Java = () => {
  return (
    <div className="container-fuild">
      <Header />
      <div className="container">
        <div className="row border border-dark m-1">
          <div className="row text-center m-3">
            <h1>
              <b>
                <u> Core Java</u>
              </b>
            </h1>
          </div>

          <div className="row m-3">
            <h4>
              Java is one of the well-optimized programming languages that is
              faster than Python and PHP. Being one of the older programming
              languages, Java still has a huge demand in current job markets.
              Learn Java Basics is designed to help complete beginners start
              their programming journey from scratch.
            </h4>
          </div>

          <div className="row m-5 h4">
            <fieldset>
              <legend style={{ width: "40%" }}>Topics:</legend>
              <ul>
                <li>Introduction to Java</li>
                <li>Basic Syntax</li>
                <li>Scanner Class</li>
                <li>Conditional Statement</li>
                <li>Loop</li>
                <li>Patterns</li>
                <li>Array</li>
                <li>Multi Dimension Array</li>
                <li>String</li>
                <li>Functions</li>
                <li>Class and Objects</li>
                <li>Constructor</li>
                <li>Encapsulation</li>
                <li>Inheritance</li>
                <li>Polymorphism</li>
                <li>Wrapper class</li>
                <li>Interface</li>
                <li>Abstract Class</li>
                <li>Inner class</li>
                <li>Collections</li>
                <li>Generics</li>
                <li>Exception Handling</li>
                <li>File Handling</li>
                <li>Threading</li>
                <li>Regex</li>
                <li>Jdbc</li>
              </ul>
            </fieldset>
          </div>

          <div className="row m-5">
            <h2>
              <b> Certificate:</b>
            </h2>
            <h2>
              <p>Programming in Core Java</p>
            </h2>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Java;
