import Footer from "./Footer";
import Header from "./Header";
const Cpp = () => {
  return (
    <div className="container-fuild">
      <Header />
      <div className="container">
        <div className="row border border-dark m-1">
          <div className="row text-center m-3">
            <h1>
              <b>
                <u> C++ Progarmming</u>
              </b>
            </h1>
          </div>

          <div className="row m-3">
            <h4>
              C++ is a powerful general-purpose programming language. It can be
              used to develop operating systems, browsers, games, and so on. C++
              supports different ways of programming like procedural,
              object-oriented, functional, and so on. This makes C++ powerful as
              well as flexible.
            </h4>
          </div>

          <div className="row m-5 h4">
            <fieldset>
              <legend style={{ width: "40%" }}>Topics:</legend>
              <ul>
                <li>Introduction to C++</li>
                <li>Basic Syntax</li>
                <li>Conditional Statement</li>
                <li>Loop</li>
                <li>Patterns</li>
                <li>Array</li>
                <li>Multi Dimension Array</li>
                <li>String</li>
                <li>Functions</li>
                <li>Files</li>
                <li>OOPS Concepts</li>
                <li>Class and Objects</li>
                <li>Encapsulation</li>
                <li>Static</li>
                <li>Scope</li>
                <li>Enum</li>
                <li>Constructor and Destructor</li>
                <li>Exception handling</li>
                <li>File handling</li>
                <li>Polymorphism</li>
                <li>Inheritance</li>
              </ul>
            </fieldset>
          </div>

          <div className="row m-5">
            <h2>
              <b> Certificate:</b>
            </h2>
            <h2>
              <p>Programming in C++</p>
            </h2>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Cpp;
