import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import Nav from "../process/Nav";
import { url } from "../link";
const Viewproject = () => {
  const nav = useNavigate();
  const [data, setData] = useState([]);
  const [to, setto] = useState("");
  const [bal, setbal] = useState("");
  const [top, settop] = useState("");
  const [tow, settow] = useState("");
  const [toptow, settoptow] = useState("");

  useEffect(() => {
    axios.post(url + "/edusphere/viewproject").then((response) => {
      setData(response.data);

      var to = 0;
      var bal = 0;
      var top = 0;
      var tow = 0;
      var toptow = 0;
      for (var x of response.data) {
        to = to + x[5];
        bal = bal + x[7];
        top = top + x[6];
        toptow = toptow + x[10];
        tow = tow + x[9];
      }
      setto(to);
      setbal(bal);
      settop(top);
      settow(tow);
      settoptow(toptow);
      $(document).ready(function () {
        var eventFired = function (type) {
          var n = $("#demo_info")[0];

          n.scrollTop = n.scrollHeight;
        };

        $("#example")
          .on("order.dt", function () {
            eventFired("Order");
          })
          .on("search.dt", function () {
            eventFired("Search");
          })
          .on("page.dt", function () {
            eventFired("Page");
          })
          .DataTable();
      });
    });
  }, []);
  const viewproject = (e) => {
    nav("/updateproject", { state: e });
  };
  const deletec = (e) => {
    axios
      .post(url + "/edusphere/deleteproject", {
        id: e,
      })
      .then((response) => {
        window.location.reload();
      });
  };

  return (
    <div>
      <Nav />
      <div className="main">
        <div className="page-container">
          <h5>
            Total {to} TotalPaid {top} Totalbal {bal}
            Totaltovendor {tow} <br /> Totaltopaid {toptow} Balance{" "}
            {top - (toptow + 800)}
            unknown amount {800}
          </h5>
          <div className="table-responsive p-0 pb-2">
            <div
              id="demo_info"
              className="box"
              style={{ display: "none" }}
            ></div>
            <table id="example" className="display" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>pid</th>
                  <th>name</th>
                  <th>phone_number</th>
                  <th>college</th>
                  <th>project_title</th>
                  <th>total_amount</th>
                  <th>total_paid</th>
                  <th>balance</th>
                  <th>vendor</th>
                  <th>total</th>
                  <th>paid</th>
                  <th>project_status</th>
                  <th>report</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data.map((d) => {
                  return (
                    <tr key={d[0]}>
                      <td>
                        <button
                          className="btn btn-primary"
                          onClick={() => viewproject(d)}
                        >
                          {d[0]}
                        </button>
                      </td>
                      <td>{d[1]}</td>
                      <td>{d[2]}</td>
                      <td>{d[3]}</td>
                      <td>{d[4]}</td>
                      <td>{d[5]}</td>
                      <td>{d[6]}</td>
                      <td>{d[7]}</td>
                      <td>{d[8]}</td>
                      <td>{d[9]}</td>
                      <td>{d[10]}</td>
                      <td>{d[11]}</td>
                      <td>{d[12]}</td>
                      <td>
                        <button
                          className="btn btn-primary"
                          onClick={() => deletec(d[0])}
                        >
                          delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Viewproject;
