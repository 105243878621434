import Footer from "./Footer";
import Header from "./Header";
const Hibernate = () => {
  return (
    <div className="container-fuild">
      <Header />
      <div className="container">
        <div className="row border border-dark m-1">
          <div className="row text-center m-3">
            <h1>
              <b>
                <u> Hibernate</u>
              </b>
            </h1>
          </div>

          <div className="row m-3">
            <h4>
              Hibernate is a Java framework that simplifies the development of
              Java application to interact with the database. It is an open
              source, lightweight, ORM (Object Relational Mapping) tool.
              Hibernate implements the specifications of JPA (Java Persistence
              API) for data persistence.
            </h4>
          </div>

          <div className="row m-5 h4">
            <fieldset>
              <legend style={{ width: "40%" }}>Topics:</legend>
              <ul>
                <li>Introduction to Hibernate</li>
                <li>Log4j</li>
                <li>Inheritance Mapping</li>
                <li>Hibernate Mapping</li>
                <li>Tx Management</li>
                <li>HQL</li>
                <li>HCQL</li>
                <li>Named Query</li>
                <li>Hiberanate Caching</li>
                <li>Second level cache</li>
                <li>Intergration</li>
                <li>Misc</li>
              </ul>
            </fieldset>
          </div>

          <div className="row m-5">
            <h2>
              <b> Certificate:</b>
            </h2>
            <h2>
              <p>Hibernate</p>
            </h2>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Hibernate;
