import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Nav from "../process/Nav";
import { url } from "../link";
import $ from "jquery";
const Viewquestion = () => {
  const nav = useNavigate();
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.post(url + "/edusphere/viewquestion").then((response) => {
      setData(response.data);

      $(document).ready(function () {
        var eventFired = function (type) {
          var n = $("#demo_info")[0];

          n.scrollTop = n.scrollHeight;
        };

        $("#example")
          .on("order.dt", function () {
            eventFired("Order");
          })
          .on("search.dt", function () {
            eventFired("Search");
          })
          .on("page.dt", function () {
            eventFired("Page");
          })
          .DataTable();
      });
    });
  }, []);
  const viewquestion = (e) => {
    nav("/updatequestion", { state: e });
  };
  const deletec = (e) => {
    axios
      .post(url + "/edusphere/deletequestion", { id: e })
      .then((response) => {
        axios.post(url + "/edusphere/viewquestion").then((response) => {
          setData(response.data);

          $(document).ready(function () {
            var eventFired = function (type) {
              var n = $("#demo_info")[0];

              n.scrollTop = n.scrollHeight;
            };

            $("#example")
              .on("order.dt", function () {
                eventFired("Order");
              })
              .on("search.dt", function () {
                eventFired("Search");
              })
              .on("page.dt", function () {
                eventFired("Page");
              })
              .DataTable();
          });
        });
      });
  };

  return (
    <div>
      <Nav />
      <div className="main">
        <div className="page-container">
          <h3>All Question</h3>
          <div className="table-responsive p-0 pb-2">
            <div
              id="demo_info"
              className="box"
              style={{ display: "none" }}
            ></div>
            <table id="example" className="display" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>qno</th>
                  <th>qname</th>
                  <th>o1</th>
                  <th>o2</th>
                  <th>o3</th>
                  <th>o4</th>
                  <th>correctanswer</th>
                  <th>mark</th>
                  <th>tid</th>
                  <th>photoname</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data.map((d) => {
                  return (
                    <tr key={d[0]}>
                      <td>
                        <button
                          className="btn btn-primary"
                          onClick={() => viewquestion(d)}
                        >
                          {d[0]}
                        </button>
                      </td>
                      <td>{d[1]}</td>
                      <td>{d[2]}</td>
                      <td>{d[3]}</td>
                      <td>{d[4]}</td>
                      <td>{d[5]}</td>
                      <td>{d[6]}</td>
                      <td>{d[7]}</td>
                      <td>{d[8]}</td>
                      <td>{d[9]}</td>
                      <td>
                        <button
                          className="btn btn-primary"
                          onClick={() => deletec(d[0])}
                        >
                          delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Viewquestion;
