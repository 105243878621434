import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import Nav from "../process/Nav";
import { url } from "../link";

const Viewdailyreport = () => {
  const nav = useNavigate();
  const [data, setData] = useState([]);
  var currentDate = new Date();

  // Get the current date and time
  var currentYear = currentDate.getFullYear();
  var currentMonth = currentDate.getMonth() + 1; // Months are zero-based, so we add 1
  var currentDay = currentDate.getDate();

  const [stdate, setstdate] = useState(
    currentYear +
      "-" +
      String(currentMonth).padStart(2, "0") +
      "-" +
      String(currentDay).padStart(2, "0")
  );
  const [endate, setendate] = useState(
    currentYear +
      "-" +
      String(currentMonth).padStart(2, "0") +
      "-" +
      String(currentDay + 1).padStart(2, "0")
  );
  const [total, settotal] = useState(0);
  useEffect(() => {
    axios
      .post(url + "/edusphere/viewdailyreport", {
        stdate: stdate,
        endate: endate,
      })
      .then((response) => {
        setData(response.data);
        var e = 0;
        for (var k of response.data) {
          e += k[2];
        }
        settotal(e);

        $(document).ready(function () {
          var eventFired = function (type) {
            var n = $("#demo_info")[0];

            n.scrollTop = n.scrollHeight;
          };

          $("#example")
            .on("order.dt", function () {
              eventFired("Order");
            })
            .on("search.dt", function () {
              eventFired("Search");
            })
            .on("page.dt", function () {
              eventFired("Page");
            })
            .DataTable();
        });
      });
  }, []);

  return (
    <div>
      <Nav />
      <div className="row">
        <div className="col-4">
          <input
            type="date"
            value={stdate}
            onChange={(e) => {
              setstdate(e.target.value);
            }}
            className="form-control "
          />
        </div>
        <div className="col-4">
          <input
            type="date"
            value={endate}
            onChange={(e) => {
              setendate(e.target.value);
            }}
            className="form-control "
          />
        </div>

        <div className="col-2">
          <input
            type="submit"
            className="btn btn-primary"
            onClick={() => {
              axios
                .post(url + "/edusphere/viewdailyreport", {
                  stdate: stdate,
                  endate: endate,
                })
                .then((response) => {
                  setData(response.data);
                  var e = 0;
                  for (var k of response.data) {
                    e += k[2];
                  }
                  settotal(e);

                  $(document).ready(function () {
                    var eventFired = function (type) {
                      var n = $("#demo_info")[0];

                      n.scrollTop = n.scrollHeight;
                    };

                    $("#example")
                      .on("order.dt", function () {
                        eventFired("Order");
                      })
                      .on("search.dt", function () {
                        eventFired("Search");
                      })
                      .on("page.dt", function () {
                        eventFired("Page");
                      })
                      .DataTable();
                  });
                });
            }}
          />
        </div>
        <div className="col-2">
          <h5>total - {total}</h5>
        </div>
      </div>
      <h3>View Payment Bill</h3>

      <div className="table-responsive p-0 pb-2">
        <div id="demo_info" className="box" style={{ display: "none" }}></div>
        <table id="example" className="display" style={{ width: "100%" }}>
          <thead>
            <tr>
              <th>pr23pa</th>
              <th>pid</th>
              <th>amountpaid</th>
              <th>balancetobepaid</th>
              <th>paiddate</th>
            </tr>
          </thead>
          <tbody>
            {data.map((d) => {
              return (
                <tr key={d[0]}>
                  <td>{d[0]}</td>
                  <td>{d[1]}</td>
                  <td>{d[2]}</td>
                  <td>{d[3]}</td>
                  <td>{d[4]}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default Viewdailyreport;
