import React, { useState } from "react";

const PlacementsPage = () => {
  const studentData = [
    {
      name: "John Doe",
      college: "XYZ College",
      department: "Computer Science",
      course: "Full Stack Web Development",
      placedCompany: "ABC Tech Solutions",
      salary: "₹50,000/month",
      photo: "https://via.placeholder.com/150", // Replace with the actual image URL
      designation: "Software Engineer",
    },
    {
      name: "Jane Smith",
      college: "ABC University",
      department: "Electrical Engineering",
      course: "Data Science",
      placedCompany: "XYZ Analytics",
      salary: "₹60,000/month",
      photo: "https://via.placeholder.com/150", // Replace with the actual image URL
      designation: "Data Scientist",
    },
    {
      name: "Jane Smith",
      college: "ABC University",
      department: "Electrical Engineering",
      course: "Data Science",
      placedCompany: "XYZ Analytics",
      salary: "₹60,000/month",
      photo: "https://via.placeholder.com/150", // Replace with the actual image URL
      designation: "Data Scientist",
    },
    {
      name: "Jane Smith",
      college: "ABC University",
      department: "Electrical Engineering",
      course: "Data Science",
      placedCompany: "XYZ Analytics",
      salary: "₹60,000/month",
      photo: "https://via.placeholder.com/150", // Replace with the actual image URL
      designation: "Data Scientist",
    },
    {
      name: "Jane Smith",
      college: "ABC University",
      department: "Electrical Engineering",
      course: "Data Science",
      placedCompany: "XYZ Analytics",
      salary: "₹60,000/month",
      photo: "https://via.placeholder.com/150", // Replace with the actual image URL
      designation: "Data Scientist",
    },
    {
      name: "Jane Smith",
      college: "ABC University",
      department: "Electrical Engineering",
      course: "Data Science",
      placedCompany: "XYZ Analytics",
      salary: "₹60,000/month",
      photo: "https://via.placeholder.com/150", // Replace with the actual image URL
      designation: "Data Scientist",
    },
    {
      name: "Jane Smith",
      college: "ABC University",
      department: "Electrical Engineering",
      course: "Data Science",
      placedCompany: "XYZ Analytics",
      salary: "₹60,000/month",
      photo: "https://via.placeholder.com/150", // Replace with the actual image URL
      designation: "Data Scientist",
    },
    {
      name: "Jane Smith",
      college: "ABC University",
      department: "Electrical Engineering",
      course: "Data Science",
      placedCompany: "XYZ Analytics",
      salary: "₹60,000/month",
      photo: "https://via.placeholder.com/150", // Replace with the actual image URL
      designation: "Data Scientist",
    },
    {
      name: "Jane Smith",
      college: "ABC University",
      department: "Electrical Engineering",
      course: "Data Science",
      placedCompany: "XYZ Analytics",
      salary: "₹60,000/month",
      photo: "https://via.placeholder.com/150", // Replace with the actual image URL
      designation: "Data Scientist",
    },
    {
      name: "Jane Smith",
      college: "ABC University",
      department: "Electrical Engineering",
      course: "Data Science",
      placedCompany: "XYZ Analytics",
      salary: "₹60,000/month",
      photo: "https://via.placeholder.com/150", // Replace with the actual image URL
      designation: "Data Scientist",
    },
    {
      name: "Jane Smith",
      college: "ABC University",
      department: "Electrical Engineering",
      course: "Data Science",
      placedCompany: "XYZ Analytics",
      salary: "₹60,000/month",
      photo: "https://via.placeholder.com/150", // Replace with the actual image URL
      designation: "Data Scientist",
    },
    {
      name: "Jane Smith",
      college: "ABC University",
      department: "Electrical Engineering",
      course: "Data Science",
      placedCompany: "XYZ Analytics",
      salary: "₹60,000/month",
      photo: "https://via.placeholder.com/150", // Replace with the actual image URL
      designation: "Data Scientist",
    },
    // Add more student data here
  ];

  const [selectedStudent, setSelectedStudent] = useState(null);

  const handleViewDetails = (index) => {
    setSelectedStudent(index === selectedStudent ? null : index);
  };

  return (
    <div
      style={{
        background: "#f9f9f9",
        padding: "20px",
        backgroundImage:
          'url("https://img.freepik.com/premium-vector/blue-black-background-with-blue-background-that-says-blue_614034-771.jpg")',
        backgroundSize: "contain",
      }}
    >
      {/* <h1 className="text-center mb-4 text-white">Students Placed</h1>

      <div className="row justify-content-center" style={{ opacity: '0.8' }}>
        {studentData.map((student, index) => (
          <div className="col-md-2 col-sm-4 mb-2 col-12" key={index}>
            <div className="card p-2 d-flex align-items-center" style={{ maxWidth: '100%' }}>
              <div className="mr-3">
                <img src={student.photo} alt={student.name} className="img-fluid rounded-circle" style={{ width: '100px', height: '100px' }} />
              </div>
              <div className="flex-grow-1">
                <h5 className="mb-2">{student.name}</h5>
                <p className="mb-1" style={{ fontSize: '0.9rem' }}>Designation: {student.designation}</p>
                <p className="mb-1" style={{ fontSize: '0.9rem' }}>College: {student.college}</p>
                <p className="mb-1" style={{ fontSize: '0.9rem' }}>Department: {student.department}</p>
                {selectedStudent === index && (
                  <div>
                    <p className="mb-2" style={{ fontSize: '0.9rem' }}>Course: {student.course}</p>
                    <p className="mb-2" style={{ fontSize: '0.9rem' }}>Placed Company: {student.placedCompany}</p>
                    <p className="mb-2" style={{ fontSize: '0.9rem' }}>Salary: {student.salary}</p>
                  </div>
                )}
                <button className="btn btn-primary btn-sm mt-2 align-items-center" onClick={() => handleViewDetails(index)}>
                  {selectedStudent === index ? 'Hide Details' : 'View Details'}
                </button>
              </div>
            </div>
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default PlacementsPage;
