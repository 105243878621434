// import { useState } from "react";
// import axios from "axios";
// import { NavLink } from "react-router-dom";
// import Nav from "./Nav";
// import { url } from "../link";
// const Enquiryentry = () => {
//   const [fname, setfname] = useState("");
//   const [lname, setlname] = useState("");
//   const [cname, setcname] = useState("");
//   const [mo, setmo] = useState("");
//   const [dob, setdob] = useState("");
//   const [email, setemail] = useState("");
//   const [gender, setgender] = useState("male");
//   const [degree, setdegree] = useState("");
//   const [year, setyear] = useState("");
//   const [address, setaddress] = useState("");
//   const [city, setcity] = useState("");
//   const [pincode, setpincode] = useState("");
//   const [pname, setpname] = useState("");
//   const [occ, setocc] = useState("");
//   const [anin, setanin] = useState("");
//   const [pmo, setpmo] = useState("");
//   const [Co, setCo] = useState("");
//   const [remark, setremark] = useState("");
//   const [nextcdate, setnextcdate] = useState("");
//   const [sd, setsd] = useState(0);
//   const [isSubmitting, setIsSubmitting] = useState(false);
//   var MyDate = new Date();
//   var MyDateString =
//     MyDate.getFullYear() +
//     "-" +
//     ("0" + (MyDate.getMonth() + 1)).slice(-2) +
//     "-" +
//     ("0" + MyDate.getDate()).slice(-2);
//   const [cur, setcur] = useState(MyDateString);

//   const sumbitdata = () => {
//     setsd(1);
//     setIsSubmitting(true);
//     if (mo === "" || email === "") {
//       alert("kindly fill mobile or email");
//       setsd(0);
//     } else {
//       const value = {
//         fname: fname,
//         lname: lname,
//         cname: cname,
//         mo: mo,
//         dob: dob,
//         email: email,
//         gender: gender,
//         degree: degree,
//         year: year,
//         address: address,
//         city: city,
//         pincode: pincode,
//         pname: pname,
//         occ: occ,
//         anin: anin,
//         pmo: pmo,
//         Co: Co,
//         remark: remark,
//         nextcdate: nextcdate,
//         cur: cur,
//       };

//       axios.post(url + "/edusphere/", value).then((response) => {
//         if (response.data === "success") {
//           setfname("");
//           setlname("");
//           setcname("");
//           setmo("");
//           setdob("");
//           setemail("");
//           setgender("");
//           setdegree("");
//           setyear("");
//           setaddress("");
//           setcity("");
//           setpincode("");
//           setpname("");
//           setocc("");
//           setanin("");
//           setpmo("");
//           setCo("");
//           setremark("");
//           setnextcdate("");

//           const timer = setTimeout(() => {
//             setIsSubmitting(false);
//             setsd(0);
//           }, 1000);
//           return () => clearTimeout(timer);
//         }
//       });
//     }
//   };
//   const setv = (e) => {
//     setfname(e);
//     var k = e.charAt(0).toUpperCase() + e.slice(1) + " " + lname;
//     setcname(k);
//   };
//   const setv1 = (e) => {
//     setlname(e);
//     var k =
//       fname.charAt(0).toUpperCase() +
//       fname.slice(1) +
//       " " +
//       e.charAt(0).toUpperCase() +
//       e.slice(1);
//     setcname(k);
//   };
//   const rend = () => {
//     if (sd === 0) {
//       return (
//         <div>
//           <Nav />
//           <div className="main">
//             <div className="page-container">
//               <div className="container" id="main">
//                 <div className="row" id="com_name">
//                   <center>
//                     <h1 style={{ color: "#03b6fc" }}>
//                       Edusphere Software Training and Development Institute
//                     </h1>
//                   </center>
//                 </div>

//                 <div className="row">
//                   <div className="col-1"></div>
//                   <div className="col-11">
//                     <h3 style={{ marginLeft: "30%" }}>
//                       <u> Enquiry Form</u>
//                     </h3>
//                   </div>
//                 </div>
//                 <div className="row">
//                   <div className="col-10">
//                     <h2 style={{ marginLeft: "35%" }}>
//                       <u> Student Information</u>
//                     </h2>
//                   </div>
//                   <div className="col-1">
//                     <NavLink to="/c">
//                       <button className="btn btn-primary">Course</button>
//                     </NavLink>
//                   </div>
//                 </div>

//                 <div className="row">
//                   <div className="col">
//                     <div
//                       className="form-floating mb-3"
//                       style={{ width: "auto" }}
//                     >
//                       <input
//                         type="text"
//                         className="form-control"
//                         id="floatingInput"
//                         placeholder="Name"
//                         value={fname}
//                         onChange={(e) => setv(e.target.value)}
//                         required
//                       />
//                       <label htmlFor="floatingInput">
//                         First Name <span style={{ color: "red" }}>*</span>
//                       </label>
//                     </div>
//                   </div>
//                   <div className="col">
//                     <div
//                       className="form-floating mb-3"
//                       style={{ width: "auto" }}
//                     >
//                       <input
//                         type="text"
//                         className="form-control"
//                         id="floatingInput"
//                         placeholder="Name"
//                         value={lname}
//                         onChange={(e) => setv1(e.target.value)}
//                         required
//                       />
//                       <label htmlFor="floatingInput">
//                         Last Name <span style={{ color: "red" }}>*</span>
//                       </label>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="row">
//                   <div className="col">
//                     <div
//                       className="form-floating mb-3"
//                       style={{ width: "auto" }}
//                     >
//                       <input
//                         type="text"
//                         className="form-control"
//                         id="floatingInput"
//                         placeholder="Name"
//                         value={cname}
//                         onChange={(e) => setcname(e.target.value)}
//                         required
//                       />
//                       <label htmlFor="floatingInput">
//                         Certificate Name <span style={{ color: "red" }}>*</span>
//                       </label>
//                     </div>
//                   </div>
//                 </div>

//                 <div className="row">
//                   <div className="col-sm-4">
//                     <div className="form-floating mb-3">
//                       <input
//                         type="tel"
//                         className="form-control"
//                         id="floatingInput"
//                         placeholder=""
//                         pattern="[0-9]{10}"
//                         value={mo}
//                         onChange={(e) => setmo(e.target.value)}
//                         required
//                       />
//                       <label htmlFor="floatingInput">
//                         Student Mobile No.{" "}
//                         <span style={{ color: "red" }}>*</span>
//                       </label>
//                     </div>
//                   </div>

//                   <div className="col-sm-3">
//                     <div className="form-floating mb-3">
//                       <input
//                         type="date"
//                         className="form-control"
//                         id="floatingInput"
//                         placeholder=""
//                         value={dob}
//                         onChange={(e) => setdob(e.target.value)}
//                         required
//                       />
//                       <label htmlFor="floatingInput">
//                         Date of Birth<span style={{ color: "red" }}>*</span>
//                       </label>
//                     </div>
//                   </div>

//                   <div className="col">
//                     <div className="form-floating mb-3">
//                       <input
//                         type="email"
//                         className="form-control"
//                         id="floatingInput"
//                         placeholder=""
//                         value={email}
//                         onChange={(e) => setemail(e.target.value)}
//                         required
//                       />
//                       <label htmlFor="floatingInput">
//                         Email<span style={{ color: "red" }}>*</span>
//                       </label>
//                     </div>
//                   </div>
//                 </div>

//                 <div className="row">
//                   <div className="col">
//                     <div className="form-floating mb-3">
//                       <select
//                         name="course"
//                         id="course"
//                         className="form-select"
//                         value={gender}
//                         onChange={(e) => setgender(e.target.value)}
//                       >
//                         <option>male </option>
//                         <option>female</option>
//                       </select>
//                       <label htmlFor="floatingInput">
//                         Gender<span style={{ color: "red" }}>*</span>
//                       </label>
//                     </div>
//                   </div>

//                   <div className="col">
//                     <div className="form-floating mb-3">
//                       <input
//                         type="text"
//                         className="form-control"
//                         id="floatingInput"
//                         placeholder="B.tech"
//                         value={degree}
//                         onChange={(e) => setdegree(e.target.value)}
//                       />
//                       <label htmlFor="floatingInput">
//                         Degree&dept(B.tech(Mech))
//                         <span style={{ color: "red" }}>*</span>
//                       </label>
//                     </div>
//                   </div>
//                   <div className="col">
//                     <div className="form-floating mb-3">
//                       <input
//                         type="text"
//                         className="form-control"
//                         id="floatingInput"
//                         placeholder=""
//                         value={year}
//                         onChange={(e) => setyear(e.target.value)}
//                         required
//                       />
//                       <label htmlFor="floatingInput">
//                         Year<span style={{ color: "red" }}>*</span>
//                       </label>
//                     </div>
//                   </div>
//                 </div>

//                 <fieldset>
//                   <legend>Address</legend>

//                   <div className="row">
//                     <div className="row">
//                       <div className="col">
//                         <div className="form-floating mb-3">
//                           <input
//                             type="text"
//                             className="form-control"
//                             id="floatingInput"
//                             placeholder=""
//                             value={address}
//                             onChange={(e) => setaddress(e.target.value)}
//                             required
//                           />
//                           <label htmlFor="floatingInput">
//                             Address<span style={{ color: "red" }}>*</span>
//                           </label>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="row">
//                       <div className="col">
//                         <div className="form-floating mb-3">
//                           <input
//                             type="text"
//                             className="form-control"
//                             id="floatingInput"
//                             placeholder=""
//                             value={city}
//                             onChange={(e) => setcity(e.target.value)}
//                             required
//                           />
//                           <label htmlFor="floatingInput">
//                             City<span style={{ color: "red" }}>*</span>
//                           </label>
//                         </div>
//                       </div>
//                       <div className="col">
//                         <div className="form-floating mb-3">
//                           <input
//                             type="text"
//                             className="form-control"
//                             id="floatingInput"
//                             placeholder=""
//                             value={pincode}
//                             onChange={(e) => setpincode(e.target.value)}
//                             required
//                           />
//                           <label htmlFor="floatingInput">
//                             Pincode<span style={{ color: "red" }}>*</span>
//                           </label>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </fieldset>

//                 <fieldset>
//                   <center>
//                     <legend>
//                       <u> Parent Information</u>
//                     </legend>
//                   </center>
//                   <div className="row">
//                     <div className="col">
//                       <div
//                         className="form-floating mb-3"
//                         style={{ width: "auto" }}
//                       >
//                         <input
//                           type="text"
//                           className="form-control"
//                           id="floatingInput"
//                           placeholder="Name"
//                           value={pname}
//                           onChange={(e) => setpname(e.target.value)}
//                           required
//                         />
//                         <label htmlFor="floatingInput">
//                           Name <span style={{ color: "red" }}>*</span>
//                         </label>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="row">
//                     <div className="col">
//                       <div
//                         className="form-floating mb-3"
//                         style={{ width: "auto" }}
//                       >
//                         <input
//                           type="text"
//                           className="form-control"
//                           id="floatingInput"
//                           placeholder="Name"
//                           value={occ}
//                           onChange={(e) => setocc(e.target.value)}
//                           required
//                         />
//                         <label htmlFor="floatingInput">
//                           Occupation <span style={{ color: "red" }}>*</span>
//                         </label>
//                       </div>
//                     </div>
//                     <div className="col">
//                       <div
//                         className="form-floating mb-3"
//                         style={{ width: "auto" }}
//                       >
//                         <input
//                           type="number"
//                           className="form-control"
//                           id="floatingInput"
//                           placeholder="Name"
//                           value={anin}
//                           onChange={(e) => setanin(e.target.value)}
//                           required
//                         />
//                         <label htmlFor="floatingInput">
//                           Annual income(2lakhs per annum){" "}
//                           <span style={{ color: "red" }}>*</span>
//                         </label>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="row">
//                     <div className="col-sm-4">
//                       <div className="form-floating mb-3">
//                         <input
//                           type="tel"
//                           className="form-control"
//                           id="floatingInput"
//                           placeholder=""
//                           pattern="[0-9]{10}"
//                           value={pmo}
//                           onChange={(e) => setpmo(e.target.value)}
//                           required
//                         />
//                         <label htmlFor="floatingInput">
//                           Parent Mobile No.{" "}
//                           <span style={{ color: "red" }}>*</span>
//                         </label>
//                       </div>
//                     </div>
//                     <div className="col">
//                       <div className="form-floating mb-3">
//                         <select
//                           name="course"
//                           id="course"
//                           className="form-select"
//                           value={Co}
//                           onChange={(e) => setCo(e.target.value)}
//                         >
//                           <option>Select </option>
//                           <option>C </option>
//                           <option>C++ </option>
//                           <option>Java </option>
//                           <option>Python </option>
//                           <option>C# </option>
//                           <option>HTML </option>
//                           <option>Css </option>
//                           <option>JavaScript </option>
//                           <option>jquery </option>
//                           <option>Bootstrap </option>
//                           <option>ReactJs </option>
//                           <option>My Sql </option>
//                           <option>Sql Server </option>
//                           <option>Mongo db </option>
//                           <option>Java Full Stack </option>
//                           <option> Python Web Development </option>
//                           <option> Java Web Development </option>
//                           <option> Data Science </option>
//                           <option> Flask </option>
//                           <option> Django </option>
//                           <option> JSP </option>
//                           <option> Servelets </option>
//                           <option> Springboot </option>
//                           <option> Hibernate </option>
//                           <option> Selenium </option>
//                           <option> Tally Prime </option>
//                           <option> Advanced Excel </option>
//                           <option> MS Office </option>
//                           <option> Data Structures </option>
//                         </select>
//                         <label htmlFor="floatingInput">
//                           Course <span style={{ color: "red" }}>*</span>
//                         </label>
//                       </div>
//                     </div>
//                     <div className="col">
//                       <div className="row">
//                         <div className="form-floating mb-3">
//                           <input
//                             type="date"
//                             className="form-control"
//                             id="floatingInput"
//                             placeholder=""
//                             value={cur}
//                             onChange={(e) => setcur(e.target.value)}
//                             required
//                           />

//                           <label htmlFor="floatingInput">
//                             Current Date<span style={{ color: "red" }}>*</span>
//                           </label>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </fieldset>
//                 <fieldset>
//                   <legend>Office Use</legend>

//                   <div className="row">
//                     <div className="row">
//                       <div className="col">
//                         <div className="form-floating mb-3">
//                           <input
//                             type="text"
//                             className="form-control"
//                             id="floatingInput"
//                             placeholder=""
//                             value={remark}
//                             onChange={(e) => setremark(e.target.value)}
//                             required
//                           />
//                           <label htmlFor="floatingInput">
//                             Remark<span style={{ color: "red" }}>*</span>
//                           </label>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="row">
//                       <div className="col">
//                         <div className="form-floating mb-3">
//                           <input
//                             type="date"
//                             className="form-control"
//                             id="floatingInput"
//                             placeholder=""
//                             value={nextcdate}
//                             onChange={(e) => setnextcdate(e.target.value)}
//                             required
//                           />
//                           <label htmlFor="floatingInput">
//                             next call date
//                             <span style={{ color: "red" }}>*</span>
//                           </label>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </fieldset>
//                 <div className="form mb-3 mt-3">
//                   <input
//                     type="submit"
//                     className="form-control btn btn-primary"
//                     id="submit"
//                     onClick={sumbitdata}
//                   />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       );
//     } else {
//       return (
//         <div style={{ marginLeft: "20%", marginTop: "50%" }}>
//           <div className="spinner-grow text-primary" role="status">
//             <span className="sr-only">Loading...</span>
//           </div>
//           <div className="spinner-grow text-secondary" role="status">
//             <span className="sr-only">Loading...</span>
//           </div>
//           <div className="spinner-grow text-success" role="status">
//             <span className="sr-only">Loading...</span>
//           </div>
//           <div className="spinner-grow text-danger" role="status">
//             <span className="sr-only">Loading...</span>
//           </div>
//           <div className="spinner-grow text-warning" role="status">
//             <span className="sr-only">Loading...</span>
//           </div>
//           <div className="spinner-grow text-info" role="status">
//             <span className="sr-only">Loading...</span>
//           </div>
//           <div className="spinner-grow text-light" role="status">
//             <span className="sr-only">Loading...</span>
//           </div>
//           <div className="spinner-grow text-dark" role="status">
//             <span className="sr-only">Loading...</span>
//           </div>
//           <br />
//           <h1>Loading</h1>
//         </div>
//       );
//     }
//   };
//   return rend();
// };

// export default Enquiryentry;

import { useState } from "react";
import axios from "axios";
import Nav from "./Nav";
import Footer from "../course_design/course_footer";
import ContactPage from "../course_design/course_contact";
import { url } from "../link";
import Header from "../course_design/course_header";
const Enquiryentryforall = () => {
  // State variables and other code...

  // Dynamic course list array
  const courseList = [
    "C",
    "C++",
    "Java",
    "Python",
    "Java",
    "Java Full Stack",
    "Python Full Stack",
    "Data Science",
    "Data Structures",
    "Tally Prime",
    "Advanced Excel",
    "MS Office",
    "SAP",
    "C#",
    "React Js",
    "React Native",
    "My SQL",
    "SQL Server",
    "AWS",
    "UX/UI",
    "Selenium",
    "HTML",
    // Add more courses as needed
  ];

  const [fname, setfname] = useState("");
  const [lname, setlname] = useState("");
  const [cname, setcname] = useState("");
  const [mo, setmo] = useState("");
  const [dob, setdob] = useState("");
  const [email, setemail] = useState("");
  const [gender, setgender] = useState("male");
  const [degree, setdegree] = useState("");
  const [year, setyear] = useState("");
  const [address, setaddress] = useState("");
  const [city, setcity] = useState("");
  const [pincode, setpincode] = useState("");
  const [pname, setpname] = useState("");
  const [occ, setocc] = useState("");
  const [anin, setanin] = useState("");
  const [pmo, setpmo] = useState("");
  const [Co, setCo] = useState("");
  const [remark, setremark] = useState("");
  const [nextcdate, setnextcdate] = useState("");
  const [sd, setsd] = useState(0);
  const [collage, setcollage] = useState("");
  var MyDate = new Date();
  var MyDateString =
    MyDate.getFullYear() +
    "-" +
    ("0" + (MyDate.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + MyDate.getDate()).slice(-2);
  const [cur, setcur] = useState(MyDateString);
  const sumbitdata = () => {
    setsd(1);
    if (mo === "" || email === "") {
      setsd(0);
      alert("kindly fill mobile or email");
    } else {
      const value = {
        fname: fname,
        lname: lname,
        cname: cname,
        mo: mo,
        dob: dob,
        email: email,
        gender: gender,
        degree: degree,
        year: year,
        address: address,
        city: city,
        pincode: pincode,
        pname: pname,
        occ: occ,
        anin: anin,
        pmo: pmo,
        Co: Co,
        remark: remark,
        nextcdate: nextcdate,
        cur: cur,
        collage: collage,
      };

      axios.post(url + "/edusphere/", value).then((response) => {
        if (response.data === "success") {
          setfname("");
          setlname("");
          setcname("");
          setmo("");
          setdob("");
          setemail("");
          setgender("");
          setdegree("");
          setyear("");
          setaddress("");
          setcity("");
          setpincode("");
          setpname("");
          setocc("");
          setanin("");
          setpmo("");
          setCo("");
          setremark("");
          setnextcdate("");
          setcollage("");
          const timer = setTimeout(() => {
            setsd(0);
          }, 1000);
          return () => clearTimeout(timer);
        }
      });
    }
  };
  const setv = (e) => {
    setfname(e);
    var k = e.charAt(0).toUpperCase() + e.slice(1) + " " + lname;
    setcname(k);
  };
  const setv1 = (e) => {
    setlname(e);
    var k =
      fname.charAt(0).toUpperCase() +
      fname.slice(1) +
      " " +
      e.charAt(0).toUpperCase() +
      e.slice(1);
    setcname(k);
  };
  // Other functions...
  const [ch, setch] = useState(1);
  const getclass = () => {
    return "col-md-" + window.localStorage.getItem("mg");
  };
  const getclass1 = () => {
    return "col-md-" + window.localStorage.getItem("mt");
  };
  const storedata = (e) => {
    setch(e);
  };

  const rend = () => {
    // Render function...
    if (sd === 0) {
      return (
        <div
          className="bg-success"
          style={{
            backgroundImage:
              "url('https://i.pinimg.com/1200x/4a/aa/49/4aaa49626ce2f6bec3def32e767fb354.jpg')",
            backgroundSize: "contain",
          }}
        >
          <div className="main row">
            <div className={getclass()} style={{ backgroundColor: "darkblue" }}>
              <Header />
            </div>
            <div className={getclass1()}>
              <div className="page-container">
                {/* ...Other content... */}
                <div className="container-fuild" id="main">
                  <div className="row m-3">
                    <div className="col m-2">
                      <h3 style={{ textAlign: "center", fontSize: "2.5em" }}>
                        <b
                          className="text-light m-3"
                          style={{
                            borderRadius: "5px",
                            fontFamily: "inherit",
                            backgroundColor: "rgba(11,1,1,0.6)",
                          }}
                        >
                          {" "}
                          Enquiry Form
                        </b>
                      </h3>
                    </div>
                  </div>
                  <div style={{}} className="m-2 ">
                    <div className="row m-2">
                      {/* <div className="col-3">
                     <NavLink to="/login">
                      <button className="btn btn-primary">Login</button>
                    </NavLink> 
                  </div> */}
                      <div className="col m-2">
                        <h5 style={{ textAlign: "center" }}>
                          <b
                            style={{
                              borderRadius: "5px",
                              fontSize: "2em",
                              color: "white",
                            }}
                          >
                            {" "}
                            Student Information
                          </b>
                        </h5>
                      </div>
                      {/* <div className="col-1">
                    <NavLink to="/course_check">
                      <button className="btn btn-primary">Course</button>
                    </NavLink> 
                  </div> */}
                    </div>

                    <div className="row m-3">
                      <div className="col">
                        <div
                          className="form-floating mb-3"
                          style={{ width: "auto" }}
                        >
                          <input
                            type="text"
                            className="form-control"
                            id="floatingInput"
                            placeholder="Name"
                            value={fname}
                            onChange={(e) => setv(e.target.value)}
                            required
                          />
                          <label htmlFor="floatingInput">
                            First Name <span style={{ color: "red" }}>*</span>
                          </label>
                        </div>
                      </div>
                      <div className="col">
                        <div
                          className="form-floating mb-3"
                          style={{ width: "auto" }}
                        >
                          <input
                            type="text"
                            className="form-control"
                            id="floatingInput"
                            placeholder="Name"
                            value={lname}
                            onChange={(e) => setv1(e.target.value)}
                            required
                          />
                          <label htmlFor="floatingInput">
                            Last Name <span style={{ color: "red" }}>*</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row m-2">
                      <div className="col">
                        <div
                          className="form-floating mb-3"
                          style={{ width: "auto" }}
                        >
                          <input
                            type="text"
                            className="form-control"
                            id="floatingInput"
                            placeholder="Name"
                            value={cname}
                            onChange={(e) => setcname(e.target.value)}
                            required
                          />
                          <label htmlFor="floatingInput">
                            Certificate Name(This name will be printed on your
                            Certificate) <span style={{ color: "red" }}>*</span>
                          </label>
                        </div>
                      </div>
                      {/* Course options */}
                      <div className="col">
                        <div className="form-floating mb-3">
                          <select
                            name="course"
                            id="course"
                            className="form-select"
                            value={Co}
                            onChange={(e) => setCo(e.target.value)}
                          >
                            <option>Select</option>
                            {courseList.map((course, index) => (
                              <option key={index} value={course}>
                                {course}
                              </option>
                            ))}
                          </select>
                          <label htmlFor="floatingInput">Course *</label>
                        </div>
                      </div>

                      {/* ...Other content... */}
                      <div className="row m-2">
                        <div className="col-sm-4">
                          <div className="form-floating mb-3">
                            <input
                              type="number"
                              className="form-control"
                              id="floatingInput"
                              placeholder=""
                              pattern="[0-9]{10}"
                              value={mo}
                              onChange={(e) => setmo(e.target.value)}
                              required
                            />
                            <label htmlFor="floatingInput">
                              Student Mobile No.{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                          </div>
                        </div>

                        <div className="col-sm-3">
                          <div className="form-floating mb-3">
                            <input
                              type="date"
                              className="form-control"
                              id="floatingInput"
                              placeholder=""
                              value={dob}
                              onChange={(e) => setdob(e.target.value)}
                              required
                            />
                            <label htmlFor="floatingInput">
                              Date of Birth
                              <span style={{ color: "red" }}>*</span>
                            </label>
                          </div>
                        </div>

                        <div className="col">
                          <div className="form-floating mb-3">
                            <input
                              type="email"
                              className="form-control"
                              id="floatingInput"
                              placeholder=""
                              value={email}
                              onChange={(e) => setemail(e.target.value)}
                              required
                            />
                            <label htmlFor="floatingInput">
                              Email<span style={{ color: "red" }}>*</span>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="row m-2">
                        <div className="col">
                          <div className="form-floating mb-3">
                            <select
                              name="course"
                              id="course"
                              className="form-select"
                              value={gender}
                              onChange={(e) => setgender(e.target.value)}
                            >
                              <option>Male </option>
                              <option>Female</option>
                            </select>
                            <label htmlFor="floatingInput">
                              Gender<span style={{ color: "red" }}>*</span>
                            </label>
                          </div>
                        </div>

                        <div className="col">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              id="floatingInput"
                              placeholder="B.tech"
                              value={degree}
                              onChange={(e) => setdegree(e.target.value)}
                            />
                            <label htmlFor="floatingInput">
                              Degree/dept (B.tech/(Mech))
                              <span style={{ color: "red" }}>*</span>
                            </label>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              id="floatingInput"
                              placeholder=""
                              value={year}
                              onChange={(e) => setyear(e.target.value)}
                              required
                            />
                            <label htmlFor="floatingInput">
                              Year(Passed out or Completed)
                              <span style={{ color: "red" }}>*</span>
                            </label>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              id="floatingInput"
                              placeholder=""
                              value={collage}
                              onChange={(e) => setcollage(e.target.value)}
                              required
                            />
                            <label htmlFor="floatingInput">
                              College
                              <span style={{ color: "red" }}>*</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="m-5 " style={{}}>
                      <fieldset
                        style={{ borderWidth: "10px", borderColor: "blue" }}
                      >
                        <legend
                          style={{ textAlign: "center" }}
                          className="text-dark"
                        >
                          <b style={{ fontSize: "2em", color: "white" }}>
                            {" "}
                            Address{" "}
                          </b>
                        </legend>

                        <div className="row m-3">
                          <div className="row">
                            <div className="col">
                              <div className="form-floating mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder=""
                                  value={address}
                                  onChange={(e) => setaddress(e.target.value)}
                                  required
                                />
                                <label htmlFor="floatingInput">
                                  Address<span style={{ color: "red" }}>*</span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <div className="form-floating mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder=""
                                  value={city}
                                  onChange={(e) => setcity(e.target.value)}
                                  required
                                />
                                <label htmlFor="floatingInput">
                                  City<span style={{ color: "red" }}>*</span>
                                </label>
                              </div>
                            </div>
                            <div className="col">
                              <div className="form-floating mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder=""
                                  value={pincode}
                                  onChange={(e) => setpincode(e.target.value)}
                                  required
                                />
                                <label htmlFor="floatingInput">
                                  Pincode<span style={{ color: "red" }}>*</span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                    <div style={{}} className="m-2 ">
                      <fieldset className="m-4">
                        <center>
                          <legend>
                            <b style={{ fontSize: "2em", color: "white" }}>
                              {" "}
                              Parent Information
                            </b>
                          </legend>
                        </center>
                        <div className="row">
                          <div className="col">
                            <div
                              className="form-floating mb-3"
                              style={{ width: "auto" }}
                            >
                              <input
                                type="text"
                                className="form-control"
                                id="floatingInput"
                                placeholder="Name"
                                value={pname}
                                onChange={(e) => setpname(e.target.value)}
                                required
                              />
                              <label htmlFor="floatingInput">
                                Name <span style={{ color: "red" }}>*</span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div
                              className="form-floating mb-3"
                              style={{ width: "auto" }}
                            >
                              <input
                                type="text"
                                className="form-control"
                                id="floatingInput"
                                placeholder="Name"
                                value={occ}
                                onChange={(e) => setocc(e.target.value)}
                                required
                              />
                              <label htmlFor="floatingInput">
                                Occupation{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                            </div>
                          </div>
                          <div className="col">
                            <div
                              className="form-floating mb-3"
                              style={{ width: "auto" }}
                            >
                              <input
                                type="number"
                                className="form-control"
                                id="floatingInput"
                                placeholder="Name"
                                value={anin}
                                onChange={(e) => setanin(e.target.value)}
                                required
                              />
                              <label htmlFor="floatingInput">
                                Annual income(2lakhs per annum){" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-4">
                            <div className="form-floating mb-3">
                              <input
                                type="tel"
                                className="form-control"
                                id="floatingInput"
                                placeholder=""
                                pattern="[0-9]{10}"
                                value={pmo}
                                onChange={(e) => setpmo(e.target.value)}
                                required
                              />
                              <label htmlFor="floatingInput">
                                Parent Mobile No.{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                            </div>
                          </div>

                          <div className="col">
                            <div className="form-floating mb-3">
                              <input
                                type="date"
                                className="form-control"
                                id="floatingInput"
                                placeholder=""
                                value={cur}
                                onChange={(e) => setcur(e.target.value)}
                                required
                              />

                              <label
                                htmlFor="floatingInput"
                                className="text-dark"
                              >
                                Current Date
                                <span style={{ color: "red" }}>*</span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                    <div
                      style={{
                        backgroundColor: "rosybrown",
                        borderRadius: "10px",
                      }}
                      className="m-2 bg-danger"
                    >
                      <fieldset className="m-5">
                        <legend style={{ textAlign: "center" }}>
                          Office Use
                        </legend>

                        <div className="row">
                          <div className="row">
                            <div className="col">
                              <div className="form-floating mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder=""
                                  value={remark}
                                  onChange={(e) => setremark(e.target.value)}
                                  required
                                />
                                <label htmlFor="floatingInput">
                                  Remark<span style={{ color: "red" }}>*</span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <div className="form-floating mb-3">
                                <input
                                  type="date"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder=""
                                  value={nextcdate}
                                  onChange={(e) => setnextcdate(e.target.value)}
                                  required
                                />
                                <label htmlFor="floatingInput">
                                  next call date
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                    {/* Animation */}
                    <div
                      className="form mb-3 mt-1 btn"
                      style={{
                        margin: "45%",
                        animation: sd === 0 ? "fadeIn 2s" : "fadeOut 2s",
                      }}
                    >
                      <input
                        type="submit"
                        className="form-control btn btn-success"
                        id="submit"
                        onClick={sumbitdata}
                      />
                    </div>
                  </div>
                </div>
                <div className="row" id="com_name">
                  <ContactPage />
                </div>
                <div className="row" id="com_name">
                  <Footer />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ marginLeft: "20%", marginTop: "50%" }}>
          <div className="spinner-grow text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <div className="spinner-grow text-secondary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <div className="spinner-grow text-success" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <div className="spinner-grow text-danger" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <div className="spinner-grow text-warning" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <div className="spinner-grow text-info" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <div className="spinner-grow text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <div className="spinner-grow text-dark" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <br />
          <h1>Loading</h1>
        </div>
      );
    }
  };
  return rend();
};

export default Enquiryentryforall;
