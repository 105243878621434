import { NavLink } from "react-router-dom";
const Startexam = () => {
  return (
    <>
      <ol>
        <li>1. Exam will take 60 minutes</li>
        <li>
          2. Dont shift to other window. after three illegal action exam will
          close automatically
        </li>
        <li>3. After completion of Exam you will get your mark.</li>
        <li>4. Accept the mark and choose reexam or certificate generation</li>
        <li>
          5. If Exam stoped inbetween kindly close and resume after checking
          your internet connection
        </li>
      </ol>
      <NavLink to="/examwindow">
        <button style={{ float: "right" }} className="btn btn-primary">
          Start Exam
        </button>
      </NavLink>
    </>
  );
};

export default Startexam;
