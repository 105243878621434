import axios from "axios";
import { useEffect, useState } from "react";
import { url } from "../link";

const Checkexam = () => {
  const [data, setData] = useState([]);
  const [i, seti] = useState(0);
  var xr = {
    1: "c",
    2: "cplus",
    3: "java",
    4: "python",
    5: "mysql",
    6: "js",
  };

  useEffect(() => {
    axios.post(url + "/edusphere/viewquestion").then((response) => {
      setData(response.data);
    });
  }, []);
  const checking = () => {
    var x = data[i];
    if (x[9] === "image") {
      return (
        <img
          src={url + "/static/" + "question/" + xr[x[8]] + "/" + x[1]}
          alt=""
        />
      );
    }
    {
      return (
        <p>
          {data[i][0]}-{i + 1}.{data[i][1]}
        </p>
      );
    }
  };
  return (
    <>
      {data.length !== 0 && (
        <>
          <h5>
            {checking()}
            <br />
            <p>{data[i][2]}</p>
            <p>{data[i][3]}</p>
            <p>{data[i][4]}</p>
            <p>{data[i][5]}</p>
            <p>{data[i][6]}</p>
            <p>sub-{data[i][8]}</p>
            <button
              className="btn btn-primary"
              onClick={() => {
                seti(i - 1);
              }}
            >
              previous
            </button>
            <button
              className="btn btn-success"
              onClick={() => {
                seti(i + 1);
              }}
            >
              next
            </button>
          </h5>
        </>
      )}
    </>
  );
};

export default Checkexam;
