import Footer from "./Footer";
import Header from "./Header";
const Springboot = () => {
  return (
    <div className="container-fuild">
      <Header />
      <div className="container">
        <div className="row border border-dark m-1">
          <div className="row text-center m-3">
            <h1>
              <b>
                <u> SpringBoot</u>
              </b>
            </h1>
          </div>

          <div className="row m-3">
            <h4>
              Spring Boot is an open source Java-based framework used to create
              a Micro Service. It is developed by Pivotal Team. It is easy to
              create a stand-alone and production ready spring applications
              using Spring Boot. Spring Boot contains a comprehensive
              infrastructure support for developing a micro service and enables
              you to develop enterprise-ready applications that you can “just
              run”.
            </h4>
          </div>

          <div className="row m-5 h4">
            <fieldset>
              <legend style={{ width: "40%" }}>Topics:</legend>
              <ul>
                <li>Introduction to Spring Boot</li>
                <li>Bootstrapping</li>
                <li>Tomcat Deployment</li>
                <li>Build systems</li>
                <li>Code structure</li>
                <li>Beans and dependency Injection</li>
                <li>Runners</li>
                <li>Application Properties</li>
                <li>Logging</li>
                <li>Restful web Services</li>
                <li>Exception Handling</li>
                <li>Interceptor</li>
                <li>Servlet Filter</li>
                <li>Scheduling</li>
                <li>HTTPS</li>
              </ul>
            </fieldset>
          </div>

          <div className="row m-5">
            <h2>
              <b> Certificate:</b>
            </h2>
            <h2>
              <p>Programming in Spring Boot</p>
            </h2>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Springboot;
