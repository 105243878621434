import Footer from "./Footer";
import Header from "./Header";
const Msoffice = () => {
  return (
    <div className="container-fuild">
      <Header />
      <div className="container">
        <div className="row border border-dark m-1">
          <div className="row text-center m-3">
            <h1>
              <b>
                <u> MS Office</u>
              </b>
            </h1>
          </div>

          <div className="row m-3">
            <h4>MS Office Applications & its Functions</h4>
          </div>

          <div className="row m-5 h4">
            <fieldset>
              <legend style={{ width: "40%" }}>Topics:</legend>
              <ul>
                <li>MS Word</li>
                <li>MS Excel</li>
                <li>MS Power Point</li>
                <li>MS Outlook</li>
              </ul>
            </fieldset>
          </div>

          <div className="row m-5">
            <h2>
              <b> Certificate:</b>
            </h2>
            <h2>
              <p>MS Office</p>
            </h2>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Msoffice;
