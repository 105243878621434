import Footer from "./Footer";
import Header from "./Header";
const Ds = () => {
  return (
    <div className="container-fuild">
      <Header />
      <div className="container">
        <div className="row border border-dark m-1">
          <div className="row text-center m-3">
            <h1>
              <b>
                <u> Data Structures</u>
              </b>
            </h1>
          </div>

          <div className="row m-3">
            <h4>
              The primary purpose of most computer programs is not to perform
              calculations, but to store and retrieve information—usually as
              fast as possible. For this reason, the study of data structures
              and the algorithms that manipulate them is at the heart of
              computer science. And that is what this book is about—helping you
              to understand how to structure information to support efficient
              processing.
            </h4>
          </div>

          <div className="row m-5 h4">
            <fieldset>
              <legend style={{ width: "40%" }}>Topics:</legend>
              <ul>
                <li>Introduction to Data Structures</li>
                <li>Array</li>
                <li>Linked List</li>
                <li>Stack</li>
                <li>Queue</li>
                <li>Searching</li>
                <li>Sorting</li>
                <li>Tree</li>
                <li>Graphs</li>
              </ul>
            </fieldset>
          </div>

          <div className="row m-5">
            <h2>
              <b> Certificate:</b>
            </h2>
            <h2>
              <p>Programming in Data Structures</p>
            </h2>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Ds;
