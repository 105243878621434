import Footer from "./Footer";
import Header from "./Header";
const Reactjs = () => {
  return (
    <div className="container-fuild">
      <Header />
      <div className="container">
        <div className="row border border-dark m-1">
          <div className="row text-center m-3">
            <h1>
              <b>
                <u>React JS</u>
              </b>
            </h1>
          </div>

          <div className="row m-3">
            <h4>
              ReactJS is a declarative, efficient, and flexible JavaScript
              library for building reusable UI components. It is an open-source,
              component-based front end library which is responsible only for
              the view layer of the application. It was initially developed and
              maintained by Facebook and later used in its products like
              WhatsApp & Instagram.
            </h4>
          </div>

          <div className="row m-5 h4">
            <fieldset>
              <legend style={{ width: "40%" }}>Topics:</legend>
              <ul>
                <li>React Introduction</li>
                <li>React Version</li>
                <li>React Installation</li>
                <li>create-react-app</li>
                <li>React Features</li>
                <li>Pros & Cons</li>
                <li>ReactJS vs AngularJS</li>
                <li>ReactJS vs ReactNative</li>
                <li>React vs Vue</li>
                <li>React JSX</li>
                <li>React Components</li>
                <li>React State</li>
                <li>React Props</li>
                <li>React Props Validation</li>
                <li>React State vs Props</li>
                <li>React Constructor</li>
                <li>React Component API</li>
                <li>Component Life Cycle</li>
                <li>React Forms</li>
                <li>Controlled vs Uncontrolled</li>
                <li>React Events</li>
                <li>Conditional Rendering</li>
                <li>React Lists</li>
                <li>React Keys</li>
                <li>React Refs</li>
                <li>React Fragments</li>
                <li>React Router</li>
                <li>React CSS</li>
                <li>React Animation</li>
                <li>React Bootstrap</li>
                <li>React Map</li>
                <li>React Table</li>
                <li>Higher-Order Components</li>
                <li>React Code Splitting</li>
                <li>React Context</li>
                <li>React Hooks</li>
                <li>React Flux Concept</li>
                <li>React Flux Vs MVC</li>
                <li>React Redux</li>
                <li>React Redux Example</li>
                <li>React Portals</li>
                <li>React Error Boundaries</li>
                <li>Loop Array in React JS</li>
                <li>React Axios Delete Request Example</li>
                <li>React Multiple Checkbox</li>
                <li>React-icons</li>
                <li>React Date Picker</li>
                <li>React Helmet</li>
                <li>Inline Style in React</li>
                <li>jQuery vs. React</li>
                <li>ReactJS Architecture</li>
                <li>ReactJS PropTypes</li>
                <li>BrowserRouter in React</li>
                <li>React vs. Svelte</li>
                <li>Button in React</li>
                <li>What is Dom in React</li>
                <li>Unit Testing in React</li>
                <li>Carousel in React</li>
                <li>React-Paginate</li>
                <li>What is the useState in React</li>
                <li>React Time-Picker</li>
              </ul>
            </fieldset>
          </div>

          <div className="row m-5">
            <h2>
              <b> Certificate:</b>
            </h2>
            <h2>
              <p>React Js</p>
            </h2>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Reactjs;
