import axios from "axios";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Nav from "../process/Nav";
import { url } from "../link";
const Addproject23payment = () => {
  const { state } = useLocation();

  const [pid, setpid] = useState(state[0]);
  const data = state[4];
  const [amountpaid, setamountpaid] = useState("");
  const [balancetobepaid, setbalancetobepaid] = useState(state[3]);
  const [paiddate, setpaiddate] = useState("");
  const nav = useNavigate();
  const submitdata = () => {
    var x = new Date();
    const value = {
      pid: pid,
      amountpaid: amountpaid,
      balancetobepaid: balancetobepaid,
      total: state[1],
      all: data,
      paiddate:
        x.getFullYear() +
        "/" +
        String(x.getMonth() + 1).padStart(2, "0") +
        "/" +
        String(x.getDate()).padStart(2, "0"),
    };
    axios.post(url + "/edusphere/insertproject23payment", value).then((res) => {
      alert("success");
      nav("/viewproject2023");
    });
  };
  return (
    <div>
      <Nav />

      <h1>Add project23payment</h1>
      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setpid(e.target.value)}
          value={pid}
          placeholder="Enter pid"
        />
        <label htmlFor="pid">pid</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input type="text" className="form-control" value={state[1]} />
        <label htmlFor="pid">Total</label>
      </div>
      <div className="form-floating mb-3 mt-3">
        <input type="text" className="form-control" value={state[2]} />
        <label htmlFor="pid">Alread paid</label>
      </div>
      <div className="form-floating mb-3 mt-3">
        <input type="text" className="form-control" value={state[3]} />
        <label htmlFor="pid">Pending amount</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="number"
          className="form-control"
          onChange={(e) => {
            if (e.target.value.length !== 0) {
              setamountpaid(parseInt(e.target.value));
              var k = parseInt(e.target.value);
              setbalancetobepaid(state[3] - k);
            } else {
              setamountpaid("");
              setbalancetobepaid(state[3]);
            }
          }}
          value={amountpaid}
          placeholder="Enter amountpaid"
        />
        <label htmlFor="amountpaid">amountpaid</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          value={balancetobepaid}
          placeholder="Enter balancetobepaid"
          disabled
        />
        <label htmlFor="balancetobepaid">balancetobepaid</label>
      </div>

      <input
        type="submit"
        className="btn btn-primary"
        onClick={submitdata}
        style={{ width: "100%" }}
      />
    </div>
  );
};
export default Addproject23payment;
