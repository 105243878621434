import axios from "axios";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Nav from "../process/Nav";
import { NavLink } from "react-router-dom";
import { url } from "../link";
const Updatequestion = () => {
  var rx = 0;
  const { state } = useLocation();
  const [qno, setqno] = useState(state[rx++]);
  const [qname, setqname] = useState(state[rx++]);
  const [o1, seto1] = useState(state[rx++]);
  const [o2, seto2] = useState(state[rx++]);
  const [o3, seto3] = useState(state[rx++]);
  const [o4, seto4] = useState(state[rx++]);
  const [correctanswer, setcorrectanswer] = useState(state[rx++]);
  const [mark, setmark] = useState(state[rx++]);
  const [tid, settid] = useState(state[rx++]);
  const nav = useNavigate();
  const submitdata = () => {
    const value = {
      qno: qno,
      qname: qname,
      o1: o1,
      o2: o2,
      o3: o3,
      o4: o4,
      correctanswer: correctanswer,
      mark: mark,
      tid: tid,
    };
    axios.post(url + "/edusphere/updatequestion", value).then((response) => {
      nav("/viewquestion");
    });
  };
  return (
    <div>
      <Nav />
      <nav className="navbar navbar-expand-sm bg-primary navbar-dark">
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink className="nav-link" to="/addquestion">
                Add Question
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/viewquestion">
                View Question
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/bulkquestion">
                Bulk upload
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
      <h1>Add question</h1>
      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setqno(e.target.value)}
          value={qno}
          placeholder="Enter qno"
          disabled
        />
        <label htmlFor="qno">qno</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setqname(e.target.value)}
          value={qname}
          placeholder="Enter qname"
        />
        <label htmlFor="qname">qname</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => seto1(e.target.value)}
          value={o1}
          placeholder="Enter o1"
        />
        <label htmlFor="o1">o1</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => seto2(e.target.value)}
          value={o2}
          placeholder="Enter o2"
        />
        <label htmlFor="o2">o2</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => seto3(e.target.value)}
          value={o3}
          placeholder="Enter o3"
        />
        <label htmlFor="o3">o3</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => seto4(e.target.value)}
          value={o4}
          placeholder="Enter o4"
        />
        <label htmlFor="o4">o4</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setcorrectanswer(e.target.value)}
          value={correctanswer}
          placeholder="Enter correctanswer"
        />
        <label htmlFor="correctanswer">correctanswer</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setmark(e.target.value)}
          value={mark}
          placeholder="Enter mark"
        />
        <label htmlFor="mark">mark</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => settid(e.target.value)}
          value={tid}
          placeholder="Enter tid"
        />
        <label htmlFor="tid">tid</label>
      </div>

      <input
        type="submit"
        className="btn btn-primary"
        onClick={submitdata}
        style={{ width: "100%" }}
      />
    </div>
  );
};
export default Updatequestion;
