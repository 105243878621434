import Footer from "./Footer";
import Header from "./Header";
const Python = () => {
  return (
    <div className="container-fuild">
      <Header />
      <div className="container">
        <div className="row border border-dark m-1">
          <div className="row text-center m-3">
            <h1>
              <b>
                <u> Core Python</u>
              </b>
            </h1>
          </div>

          <div className="row m-3">
            <h4>
              Python is one of the easiest programming languages to learn
              because of its easy-to-use syntax. The simplicity of Python syntax
              allows you to focus on the logic rather than focusing on syntax.
            </h4>
          </div>

          <div className="row m-5 h4">
            <fieldset>
              <legend style={{ width: "40%" }}>Topics:</legend>
              <ul>
                <li>Introduction to Python</li>
                <li>Basic Syntax</li>
                <li>Conditional Statement</li>
                <li>Loop</li>
                <li>Patterns</li>
                <li>List</li>
                <li>Tuple</li>
                <li>Set</li>
                <li>Dictonary</li>
                <li>String</li>
                <li>Functions</li>
                <li>Class and Objects</li>
                <li>Constructor</li>
                <li>File Handling</li>
                <li>Sqlite3</li>
                <li>Zip</li>
                <li>Json</li>
                <li>Filter</li>
                <li>Map</li>
                <li>Lamda</li>
                <li>xlsx</li>
                <li>Exception Handling</li>
                <li>Regular Expression</li>
                <li>Web Scrapping</li>
                <li>Numpy</li>
                <li>Pandas</li>
                <li>Mat plot</li>
              </ul>
            </fieldset>
          </div>

          <div className="row m-5">
            <h2>
              <b> Certificate:</b>
            </h2>
            <h2>
              <p>Programming in Core Python</p>
            </h2>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Python;
