import axios from "axios";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { url } from "../link";
const Updatelaptop = () => {
  var rx = 0;
  const { state } = useLocation();
  const nav = useNavigate();
  const [lid, setlid] = useState(state[rx++]);
  const [laptopname, setlaptopname] = useState(state[rx++]);
  const [addons, setaddons] = useState(state[rx++]);
  const [givendate, setgivendate] = useState(state[rx++]);
  const [deliverydate, setdeliverydate] = useState(state[rx++]);
  const [commiment, setcommiment] = useState(state[rx++]);
  const [pid, setpid] = useState(state[rx++]);
  const [receivedby, setreceivedby] = useState(state[rx++]);
  const submitdata = () => {
    const value = {
      lid: lid,
      laptopname: laptopname,
      addons: addons,
      givendate: givendate,
      deliverydate: deliverydate,
      commiment: commiment,
      pid: pid,
      receivedby: receivedby,
    };
    axios.post(url + "/edusphere/updatelaptop", value).then((response) => {
      nav("/viewlaptop");
    });
  };
  return (
    <div>
      <h1>Add laptop</h1>
      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setlid(e.target.value)}
          value={lid}
          placeholder="Enter lid"
        />
        <label htmlFor="lid">lid</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setlaptopname(e.target.value)}
          value={laptopname}
          placeholder="Enter laptopname"
        />
        <label htmlFor="laptopname">laptopname</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setaddons(e.target.value)}
          value={addons}
          placeholder="Enter addons"
        />
        <label htmlFor="addons">addons</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setgivendate(e.target.value)}
          value={givendate}
          placeholder="Enter givendate"
        />
        <label htmlFor="givendate">givendate</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setdeliverydate(e.target.value)}
          value={deliverydate}
          placeholder="Enter deliverydate"
        />
        <label htmlFor="deliverydate">deliverydate</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setcommiment(e.target.value)}
          value={commiment}
          placeholder="Enter commiment"
        />
        <label htmlFor="commiment">commiment</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setpid(e.target.value)}
          value={pid}
          placeholder="Enter pid"
        />
        <label htmlFor="pid">pid</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setreceivedby(e.target.value)}
          value={receivedby}
          placeholder="Enter receivedby"
        />
        <label htmlFor="receivedby">receivedby</label>
      </div>

      <input
        type="submit"
        className="btn btn-primary"
        onClick={submitdata}
        style={{ width: "100%" }}
      />
    </div>
  );
};
export default Updatelaptop;
