import { useState } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { url } from "../link";
const Home = () => {
  const [fname, setfname] = useState("");
  const [lname, setlname] = useState("");
  const [cname, setcname] = useState("");
  const [mo, setmo] = useState("");
  const [dob, setdob] = useState("");
  const [email, setemail] = useState("");
  const [gender, setgender] = useState("male");
  const [degree, setdegree] = useState("");
  const [year, setyear] = useState("");
  const [address, setaddress] = useState("");
  const [city, setcity] = useState("");
  const [pincode, setpincode] = useState("");
  const [pname, setpname] = useState("");
  const [occ, setocc] = useState("");
  const [anin, setanin] = useState("");
  const [pmo, setpmo] = useState("");
  const [Co, setCo] = useState("");
  const [remark, setremark] = useState("");
  const [nextcdate, setnextcdate] = useState("");
  const [sd, setsd] = useState(0);
  var MyDate = new Date();
  var MyDateString =
    MyDate.getFullYear() +
    "-" +
    ("0" + (MyDate.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + MyDate.getDate()).slice(-2);
  const [cur, setcur] = useState(MyDateString);
  const sumbitdata = () => {
    if (mo === "" || email === "") {
      setsd(1);
      alert("kindly fill mobile or email");
    } else {
      const value = {
        fname: fname,
        lname: lname,
        cname: cname,
        mo: mo,
        dob: dob,
        email: email,
        gender: gender,
        degree: degree,
        year: year,
        address: address,
        city: city,
        pincode: pincode,
        pname: pname,
        occ: occ,
        anin: anin,
        pmo: pmo,
        Co: Co,
        remark: remark,
        nextcdate: nextcdate,
        cur: cur,
      };

      axios.post(url + "/edusphere/", value).then((response) => {
        alert(response.data);
        if (response.data === "success") {
          setfname("");
          setlname("");
          setcname("");
          setmo("");
          setdob("");
          setemail("");
          setgender("");
          setdegree("");
          setyear("");
          setaddress("");
          setcity("");
          setpincode("");
          setpname("");
          setocc("");
          setanin("");
          setpmo("");
          setCo("");
          setremark("");
          setnextcdate("");
          const timer = setTimeout(() => {
            setsd(0);
          }, 1000);
          return () => clearTimeout(timer);
        }
      });
    }
  };
  const setv = (e) => {
    setfname(e);
    var k = e.charAt(0).toUpperCase() + e.slice(1) + " " + lname;
    setcname(k);
  };
  const setv1 = (e) => {
    setlname(e);
    var k =
      fname.charAt(0).toUpperCase() +
      fname.slice(1) +
      " " +
      e.charAt(0).toUpperCase() +
      e.slice(1);
    setcname(k);
  };
  const rend = () => {
    if (sd === 0) {
      return (
        <div>
          <div className="main">
            <div className="page-container">
              <div className="container" id="main">
                <div className="row" id="com_name">
                  <center>
                    <h1 style={{ color: "#03b6fc" }}>
                      Edusphere Software Training and Development Institute
                    </h1>
                  </center>
                </div>

                <div className="row">
                  <div className="col-1"></div>
                  <div className="col-11">
                    <h3 style={{ marginLeft: "30%" }}>
                      <u> Enquiry Form</u>
                    </h3>
                  </div>
                </div>
                <div className="row">
                  <div className="col-3">
                    <NavLink to="/login">
                      <button className="btn btn-primary">Login</button>
                    </NavLink>
                  </div>
                  <div className="col-7">
                    <h5 style={{ marginLeft: "5%" }}>
                      <u> Student Information</u>
                    </h5>
                  </div>
                  <div className="col-1">
                    <NavLink to="/c">
                      <button className="btn btn-primary">Course</button>
                    </NavLink>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <div
                      className="form-floating mb-3"
                      style={{ width: "auto" }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        placeholder="Name"
                        value={fname}
                        onChange={(e) => setv(e.target.value)}
                        required
                      />
                      <label htmlFor="floatingInput">
                        First Name <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                  </div>
                  <div className="col">
                    <div
                      className="form-floating mb-3"
                      style={{ width: "auto" }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        placeholder="Name"
                        value={lname}
                        onChange={(e) => setv1(e.target.value)}
                        required
                      />
                      <label htmlFor="floatingInput">
                        Last Name <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div
                      className="form-floating mb-3"
                      style={{ width: "auto" }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        placeholder="Name"
                        value={cname}
                        onChange={(e) => setcname(e.target.value)}
                        required
                      />
                      <label htmlFor="floatingInput">
                        Certificate Name <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-4">
                    <div className="form-floating mb-3">
                      <input
                        type="tel"
                        className="form-control"
                        id="floatingInput"
                        placeholder=""
                        pattern="[0-9]{10}"
                        value={mo}
                        onChange={(e) => setmo(e.target.value)}
                        required
                      />
                      <label htmlFor="floatingInput">
                        Student Mobile No.{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                  </div>

                  <div className="col-sm-3">
                    <div className="form-floating mb-3">
                      <input
                        type="date"
                        className="form-control"
                        id="floatingInput"
                        placeholder=""
                        value={dob}
                        onChange={(e) => setdob(e.target.value)}
                        required
                      />
                      <label htmlFor="floatingInput">
                        Date of Birth<span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                  </div>

                  <div className="col">
                    <div className="form-floating mb-3">
                      <input
                        type="email"
                        className="form-control"
                        id="floatingInput"
                        placeholder=""
                        value={email}
                        onChange={(e) => setemail(e.target.value)}
                        required
                      />
                      <label htmlFor="floatingInput">
                        Email<span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <div className="form-floating mb-3">
                      <select
                        name="course"
                        id="course"
                        className="form-select"
                        value={gender}
                        onChange={(e) => setgender(e.target.value)}
                      >
                        <option>male </option>
                        <option>female</option>
                      </select>
                      <label htmlFor="floatingInput">
                        Gender<span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                  </div>

                  <div className="col">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        placeholder="B.tech"
                        value={degree}
                        onChange={(e) => setdegree(e.target.value)}
                      />
                      <label htmlFor="floatingInput">
                        Degree&dept(B.tech(Mech))
                        <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        placeholder=""
                        value={year}
                        onChange={(e) => setyear(e.target.value)}
                        required
                      />
                      <label htmlFor="floatingInput">
                        Year<span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                  </div>
                </div>

                <fieldset>
                  <legend>Address</legend>

                  <div className="row">
                    <div className="row">
                      <div className="col">
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            id="floatingInput"
                            placeholder=""
                            value={address}
                            onChange={(e) => setaddress(e.target.value)}
                            required
                          />
                          <label htmlFor="floatingInput">
                            Address<span style={{ color: "red" }}>*</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            id="floatingInput"
                            placeholder=""
                            value={city}
                            onChange={(e) => setcity(e.target.value)}
                            required
                          />
                          <label htmlFor="floatingInput">
                            City<span style={{ color: "red" }}>*</span>
                          </label>
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            id="floatingInput"
                            placeholder=""
                            value={pincode}
                            onChange={(e) => setpincode(e.target.value)}
                            required
                          />
                          <label htmlFor="floatingInput">
                            Pincode<span style={{ color: "red" }}>*</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>

                <fieldset>
                  <center>
                    <legend>
                      <u> Parent Information</u>
                    </legend>
                  </center>
                  <div className="row">
                    <div className="col">
                      <div
                        className="form-floating mb-3"
                        style={{ width: "auto" }}
                      >
                        <input
                          type="text"
                          className="form-control"
                          id="floatingInput"
                          placeholder="Name"
                          value={pname}
                          onChange={(e) => setpname(e.target.value)}
                          required
                        />
                        <label htmlFor="floatingInput">
                          Name <span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div
                        className="form-floating mb-3"
                        style={{ width: "auto" }}
                      >
                        <input
                          type="text"
                          className="form-control"
                          id="floatingInput"
                          placeholder="Name"
                          value={occ}
                          onChange={(e) => setocc(e.target.value)}
                          required
                        />
                        <label htmlFor="floatingInput">
                          Occupation <span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                    </div>
                    <div className="col">
                      <div
                        className="form-floating mb-3"
                        style={{ width: "auto" }}
                      >
                        <input
                          type="number"
                          className="form-control"
                          id="floatingInput"
                          placeholder="Name"
                          value={anin}
                          onChange={(e) => setanin(e.target.value)}
                          required
                        />
                        <label htmlFor="floatingInput">
                          Annual income(2lakhs per annum){" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="form-floating mb-3">
                        <input
                          type="tel"
                          className="form-control"
                          id="floatingInput"
                          placeholder=""
                          pattern="[0-9]{10}"
                          value={pmo}
                          onChange={(e) => setpmo(e.target.value)}
                          required
                        />
                        <label htmlFor="floatingInput">
                          Parent Mobile No.{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-floating mb-3">
                        <select
                          name="course"
                          id="course"
                          className="form-select"
                          value={Co}
                          onChange={(e) => setCo(e.target.value)}
                        >
                          <option>Select </option>
                          <option>C </option>
                          <option>C++ </option>
                          <option>Java </option>
                          <option>Python </option>
                          <option>C# </option>
                          <option>HTML </option>
                          <option>Css </option>
                          <option>JavaScript </option>
                          <option>jquery </option>
                          <option>Bootstrap </option>
                          <option>ReactJs </option>
                          <option>My Sql </option>
                          <option>Sql Server </option>
                          <option>Mongo db </option>
                          <option>Java Full Stack </option>
                          <option> Python Web Development </option>
                          <option> Java Web Development </option>
                          <option> Data Science </option>
                          <option> Flask </option>
                          <option> Django </option>
                          <option> JSP </option>
                          <option> Servelets </option>
                          <option> Springboot </option>
                          <option> Hibernate </option>
                          <option> Selenium </option>
                          <option> Tally Prime </option>
                          <option> Advanced Excel </option>
                          <option> MS Office </option>
                          <option> Data Structures </option>
                        </select>
                        <label htmlFor="floatingInput">
                          Course <span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                    </div>
                    <div className="col">
                      <div className="row">
                        <div className="form-floating mb-3">
                          <input
                            type="date"
                            className="form-control"
                            id="floatingInput"
                            placeholder=""
                            value={cur}
                            onChange={(e) => setcur(e.target.value)}
                            required
                          />

                          <label htmlFor="floatingInput">
                            Current Date<span style={{ color: "red" }}>*</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
                <fieldset>
                  <legend>Office Use</legend>

                  <div className="row">
                    <div className="row">
                      <div className="col">
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            id="floatingInput"
                            placeholder=""
                            value={remark}
                            onChange={(e) => setremark(e.target.value)}
                            required
                          />
                          <label htmlFor="floatingInput">
                            Remark<span style={{ color: "red" }}>*</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="form-floating mb-3">
                          <input
                            type="date"
                            className="form-control"
                            id="floatingInput"
                            placeholder=""
                            value={nextcdate}
                            onChange={(e) => setnextcdate(e.target.value)}
                            required
                          />
                          <label htmlFor="floatingInput">
                            next call date
                            <span style={{ color: "red" }}>*</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
                <div className="form mb-3 mt-3">
                  <input
                    type="submit"
                    className="form-control btn btn-primary"
                    id="submit"
                    onClick={sumbitdata}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ marginLeft: "20%", marginTop: "50%" }}>
          <div className="spinner-grow text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <div className="spinner-grow text-secondary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <div className="spinner-grow text-success" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <div className="spinner-grow text-danger" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <div className="spinner-grow text-warning" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <div className="spinner-grow text-info" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <div className="spinner-grow text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <div className="spinner-grow text-dark" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <br />
          <h1>Loading</h1>
        </div>
      );
    }
  };
  return rend();
};

export default Home;
