import { useState } from "react";

const AddModal = ({ isOpen, onClose, getcount }) => {
  const [leave, setleave] = useState("");
  const [permission, setpermission] = useState("");

  const handleSubmit = () => {
    getcount({ leave: leave, permission: permission });
    setleave("");
    setpermission("");
  };

  return (
    <div
      className={`modal ${isOpen ? "show" : ""}`}
      style={{ display: isOpen ? "block" : "none" }}
      tabIndex="-1"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Request Permission</h5>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
            ></button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label htmlFor="leave">Leave Count</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setleave(e.target.value);
                }}
                value={leave}
              />
            </div>
            <div className="form-group">
              <label htmlFor="leave">Permisson Count</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setpermission(e.target.value);
                }}
                value={permission}
              />
            </div>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onClose}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddModal;
