import { useState } from "react";
import { useLocation } from "react-router-dom";
import Nav from "./Nav";
import axios from "axios";
import { url } from "../link";
import { useNavigate } from "react-router-dom";
const Updateenquiry = () => {
  const { state } = useLocation();
  const nav = useNavigate();
  const [eid] = useState(state[0]);
  const [fname, setfname] = useState(state[1]);
  const [lname, setlname] = useState(state[2]);
  const [cname, setcname] = useState(state[3]);
  const [mo, setmo] = useState(state[4]);
  const [dob, setdob] = useState(state[5]);
  const [email, setemail] = useState(state[6]);
  const [gender, setgender] = useState(state[7]);
  const [degree, setdegree] = useState(state[8]);
  const [year, setyear] = useState(state[9]);
  const [address, setaddress] = useState(state[10]);
  const [city, setcity] = useState(state[11]);
  const [pincode, setpincode] = useState(state[12]);
  const [pname, setpname] = useState(state[13]);
  const [occ, setocc] = useState(state[14]);
  const [anin, setanin] = useState(state[15]);
  const [pmo, setpmo] = useState(state[17]);
  const [Co, setCo] = useState(state[16]);
  const [remark, setremark] = useState(state[18]);
  const [nextcdate, setnextcdate] = useState(state[19]);
  const [cur, setcur] = useState(state[23]);
  const [status, setstatus] = useState(state[20]);
  const sumbitdata = () => {
    if (mo === "" || email === "") {
      alert("kindly fill mobile or email");
    } else {
      const value = {
        eid: eid,
        fname: fname,
        lname: lname,
        cname: cname,
        mo: mo,
        dob: dob,
        email: email,
        gender: gender,
        degree: degree,
        year: year,
        address: address,
        city: city,
        pincode: pincode,
        pname: pname,
        occ: occ,
        anin: anin,
        pmo: pmo,
        Co: Co,
        remark: remark,
        nextcdate: nextcdate,
        cur: cur,
        status: status,
      };

      axios.post(url + "/edusphere/updateenquiry", value).then((response) => {
        if (response.data === "success") {
          alert("update successfully");
          nav("/showenquiry");
        }
      });
    }
  };
  return (
    <>
      <>
        <Nav />
        <div className="main">
          <div className="page-container">
            <div className="container" id="main">
              <div className="row" id="com_name">
                <center>
                  <h1 style={{ color: "#03b6fc" }}>
                    Edusphere Software Training and Development Institute
                  </h1>
                </center>
              </div>

              <div className="row">
                <div className="col-1"></div>
                <div className="col-11">
                  <h3 style={{ marginLeft: "30%" }}>
                    <u> Enquiry Form</u>
                  </h3>
                </div>
              </div>
              <div className="row">
                <div className="col-10">
                  <h2 style={{ marginLeft: "35%" }}>
                    <u> Student Information</u>
                  </h2>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <div className="form-floating mb-3" style={{ width: "auto" }}>
                    <input
                      type="text"
                      className="form-control"
                      id="floatingInput"
                      placeholder="Name"
                      value={fname}
                      onChange={(e) => setfname(e.target.value)}
                      required
                    />
                    <label htmlFor="floatingInput">
                      First Name <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                </div>
                <div className="col">
                  <div className="form-floating mb-3" style={{ width: "auto" }}>
                    <input
                      type="text"
                      className="form-control"
                      id="floatingInput"
                      placeholder="Name"
                      value={lname}
                      onChange={(e) => setlname(e.target.value)}
                      required
                    />
                    <label htmlFor="floatingInput">
                      Last Name <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-floating mb-3" style={{ width: "auto" }}>
                    <input
                      type="text"
                      className="form-control"
                      id="floatingInput"
                      placeholder="Name"
                      value={cname}
                      onChange={(e) => setcname(e.target.value)}
                      required
                    />
                    <label htmlFor="floatingInput">
                      Certificate Name <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-4">
                  <div className="form-floating mb-3">
                    <input
                      type="tel"
                      className="form-control"
                      id="floatingInput"
                      placeholder=""
                      pattern="[0-9]{10}"
                      value={mo}
                      onChange={(e) => setmo(e.target.value)}
                      required
                    />
                    <label htmlFor="floatingInput">
                      Student Mobile No. <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                </div>

                <div className="col-sm-3">
                  <div className="form-floating mb-3">
                    <input
                      type="date"
                      className="form-control"
                      id="floatingInput"
                      placeholder=""
                      value={dob}
                      onChange={(e) => setdob(e.target.value)}
                      required
                    />
                    <label htmlFor="floatingInput">
                      Date of Birth<span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                </div>

                <div className="col">
                  <div className="form-floating mb-3">
                    <input
                      type="email"
                      className="form-control"
                      id="floatingInput"
                      placeholder=""
                      value={email}
                      onChange={(e) => setemail(e.target.value)}
                      required
                    />
                    <label htmlFor="floatingInput">
                      Email<span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <div className="form-floating mb-3">
                    <select
                      name="course"
                      id="course"
                      className="form-select"
                      value={gender}
                      onChange={(e) => setgender(e.target.value)}
                    >
                      <option>male </option>
                      <option>female</option>
                    </select>
                    <label htmlFor="floatingInput">
                      Gender<span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                </div>

                <div className="col">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingInput"
                      placeholder=""
                      value={degree}
                      onChange={(e) => setdegree(e.target.value)}
                      required
                    />
                    <label htmlFor="floatingInput">
                      Degree<span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                </div>
                <div className="col">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingInput"
                      placeholder=""
                      value={year}
                      onChange={(e) => setyear(e.target.value)}
                      required
                    />
                    <label htmlFor="floatingInput">
                      Year<span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                </div>
              </div>

              <fieldset>
                <legend>Address</legend>

                <div className="row">
                  <div className="row">
                    <div className="col">
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="floatingInput"
                          placeholder=""
                          value={address}
                          onChange={(e) => setaddress(e.target.value)}
                          required
                        />
                        <label htmlFor="floatingInput">
                          Address<span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="floatingInput"
                          placeholder=""
                          value={city}
                          onChange={(e) => setcity(e.target.value)}
                          required
                        />
                        <label htmlFor="floatingInput">
                          City<span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="floatingInput"
                          placeholder=""
                          value={pincode}
                          onChange={(e) => setpincode(e.target.value)}
                          required
                        />
                        <label htmlFor="floatingInput">
                          Pincode<span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>

              <fieldset>
                <center>
                  <legend>
                    <u> Parent Information</u>
                  </legend>
                </center>
                <div className="row">
                  <div className="col">
                    <div
                      className="form-floating mb-3"
                      style={{ width: "auto" }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        placeholder="Name"
                        value={pname}
                        onChange={(e) => setpname(e.target.value)}
                        required
                      />
                      <label htmlFor="floatingInput">
                        Name <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div
                      className="form-floating mb-3"
                      style={{ width: "auto" }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        placeholder="Name"
                        value={occ}
                        onChange={(e) => setocc(e.target.value)}
                        required
                      />
                      <label htmlFor="floatingInput">
                        Occupation <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                  </div>
                  <div className="col">
                    <div
                      className="form-floating mb-3"
                      style={{ width: "auto" }}
                    >
                      <input
                        type="number"
                        className="form-control"
                        id="floatingInput"
                        placeholder="Name"
                        value={anin}
                        onChange={(e) => setanin(e.target.value)}
                        required
                      />
                      <label htmlFor="floatingInput">
                        Annual income <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4">
                    <div className="form-floating mb-3">
                      <input
                        type="tel"
                        className="form-control"
                        id="floatingInput"
                        placeholder=""
                        pattern="[0-9]{10}"
                        value={pmo}
                        onChange={(e) => setpmo(e.target.value)}
                        required
                      />
                      <label htmlFor="floatingInput">
                        Parent Mobile No.{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-floating mb-3">
                      <select
                        name="course"
                        id="course"
                        className="form-select"
                        value={Co}
                        onChange={(e) => setCo(e.target.value)}
                      >
                        <option>Select </option>
                        <option>C </option>
                        <option>C++ </option>
                        <option>Java </option>
                        <option>Python </option>
                        <option>C# </option>
                        <option>HTML </option>
                        <option>Css </option>
                        <option>JavaScript </option>
                        <option>jquery </option>
                        <option>Bootstrap </option>
                        <option>ReactJs </option>
                        <option>My Sql </option>
                        <option>Sql Server </option>
                        <option>Mongo db </option>
                        <option>Java Full Stack </option>
                        <option> Python Web Development </option>
                        <option> Java Web Development </option>
                        <option> Data Science </option>
                        <option> Flask </option>
                        <option> Django </option>
                        <option> JSP </option>
                        <option> Servelets </option>
                        <option> Springboot </option>
                        <option> Hibernate </option>
                        <option> Selenium </option>
                        <option> Tally Prime </option>
                        <option> Advanced Excel </option>
                        <option> MS Office </option>
                        <option> Data Structures </option>
                      </select>
                      <label htmlFor="floatingInput">
                        Course <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                  </div>
                  <div className="col">
                    <div className="row">
                      <div className="form-floating mb-3">
                        <input
                          type="date"
                          className="form-control"
                          id="floatingInput"
                          placeholder=""
                          value={cur}
                          onChange={(e) => setcur(e.target.value)}
                          required
                        />

                        <label htmlFor="floatingInput">
                          Current Date<span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <legend>Office Use</legend>

                <div className="row">
                  <div className="row">
                    <div className="col">
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="floatingInput"
                          placeholder=""
                          value={remark}
                          onChange={(e) => setremark(e.target.value)}
                          required
                        />
                        <label htmlFor="floatingInput">
                          Remark<span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-floating mb-3">
                        <input
                          type="date"
                          className="form-control"
                          id="floatingInput"
                          placeholder=""
                          value={nextcdate}
                          onChange={(e) => setnextcdate(e.target.value)}
                          required
                        />
                        <label htmlFor="floatingInput">
                          next call date<span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-floating mb-3">
                      <select
                        onChange={(e) => setstatus(e.target.value)}
                        className="form-select"
                      >
                        <option>open</option>
                        <option>joined</option>
                        <option>closed</option>
                        <option>discontinued</option>
                      </select>

                      <label htmlFor="floatingInput">
                        status<span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                  </div>
                </div>
              </fieldset>

              <div className="form mb-3 mt-3">
                <input
                  type="submit"
                  className="form-control btn btn-primary"
                  id="submit"
                  onClick={sumbitdata}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default Updateenquiry;
