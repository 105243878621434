import axios from "axios";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { url } from "../link";
const Updateamountavailble = () => {
  var rx = 0;
  const { state } = useLocation();
  const [aid, setaid] = useState(state[rx++]);
  const [amount, setamount] = useState(state[rx++]);
  const [mode, setmode] = useState(state[rx++]);
  const nav = useNavigate();
  const submitdata = () => {
    const value = { aid: aid, amount: amount, mode: mode };
    axios
      .post(url + "/edusphere/updateamountavailble", value)
      .then((response) => {
        nav("/viewamountavailble");
      });
  };
  return (
    <div>
      <h1>Add amountavailble</h1>
      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setaid(e.target.value)}
          value={aid}
          placeholder="Enter aid"
        />
        <label htmlFor="aid">aid</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setamount(e.target.value)}
          value={amount}
          placeholder="Enter amount"
        />
        <label htmlFor="amount">amount</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setmode(e.target.value)}
          value={mode}
          placeholder="Enter mode"
        />
        <label htmlFor="mode">mode</label>
      </div>

      <input
        type="submit"
        className="btn btn-primary"
        onClick={submitdata}
        style={{ width: "100%" }}
      />
    </div>
  );
};
export default Updateamountavailble;
