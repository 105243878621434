import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Nav from "../process/Nav";
import { url } from "../link";
import DataTable from "react-data-table-component";

const Viewexam = () => {
  const nav = useNavigate();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filteredwithData, setFilteredwithData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("scheduled");
  const [searchQuery, setSearchQuery] = useState("");
  const [x, setx] = useState(0);
  const [s, sets] = useState([]);
  const [pending, setPending] = useState(true);
  const [mark, setMark] = useState("");
  const [att, setAtt] = useState("");
  const [project, setProject] = useState("");
  const [total, setTotal] = useState("");
  const [name, setName] = useState("");
  const [courseTitle, setCourseTitle] = useState("");
  const [duration, setDuration] = useState("");
  const [reg, setReg] = useState("");
  const [rid, setRid] = useState("");

  const column = [
    {
      name: "Eid",
      selector: (row) => row.Eid,
      sortable: true,
    },
    {
      name: "Rid",
      selector: (row) => row.Rid,
      sortable: true,
    },
    {
      name: "Subject_id",
      selector: (row) => row.Subject_id,
      sortable: true,
    },
    {
      name: "Starttime",
      selector: (row) => row.Starttime,
      sortable: true,
    },
    {
      name: "Endtime",
      selector: (row) => row.Endtime,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.Status,
      sortable: true,
    },
    {
      name: "Mark",
      selector: (row) => row.Mark,
      sortable: true,
    },
    {
      name: "Delete",
      cell: (row) => (
        <button
          className="btn btn-danger"
          onClick={() => {
            axios
              .post(url + "/edusphere/deleteexams", {
                id: row.Eid,
                rid: row.Rid,
              })
              .then(() => {
                setx(x + 1);
              });
          }}
        >
          Delete
        </button>
      ),
    },
    {
      name: "Generate",
      cell: (row) =>
        row.Status === "completed" ? (
          <button
            className="btn btn-success"
            onClick={() => {
              axios
                .post(url + "/edusphere/viewexammark", { rid: row.Rid })
                .then((response) => {
                  setReg(response.data[1]);
                  setName(response.data[0][1]);
                  setCourseTitle(response.data[0][6]);
                  setMark(row.Mark);
                  setAtt(response.data[0][0]);
                  setDuration(response.data[0][5]);
                  setRid(row.Rid);
                })
                .catch((err) => {
                  console.log(err);
                });
            }}
            data-bs-toggle="modal"
            data-bs-target="#myModal"
          >
            Generate
          </button>
        ) : row.Status === "certificate generated" ? (
          <button
            className="btn btn-warning"
            onClick={() => {
              var k = new Date();
              axios
                .post(url + "/edusphere/updatecertificates", {
                  rid: row.Rid,
                  issue:
                    k.getFullYear() +
                    "-" +
                    String(k.getMonth() + 1).padStart(2, "0") +
                    "-" +
                    String(k.getDate() + 1).padStart(2, "0"),
                })
                .then((response) => {
                  setx(x + 1);
                })
                .catch((err) => {
                  console.log(err);
                });
            }}
          >
            issue
          </button>
        ) : null,
    },
    {
      name: "Issued",
      selector: (row) => row.Issuedate,
      sortable: true,
    },
  ];

  useEffect(() => {
    axios.post(url + "/edusphere/viewexam").then((response) => {
      const e = ["All"];
      const r = response.data.map((k) => {
        if (!e.includes(k[5].toLowerCase())) {
          e.push(k[5].toLowerCase());
        }
        return {
          Eid: k[0],
          Rid: k[1],
          Subject_id: k[2],
          Starttime: k[3],
          Endtime: k[4],
          Status: k[5],
          Mark: k[6],
          Issuedate: k[7],
          delete: null, // to be defined in column definition
          Remark: null, // to be defined in column definition
        };
      });
      const filteredResults = r.filter(
        (item) => item.Status.toLowerCase() === "scheduled".toLowerCase()
      );
      setData(r);
      setFilteredData(filteredResults);
      setFilteredwithData(r);
      sets(e);
      setPending(false);
    });
  }, [x]);

  const filterByStatus = (status) => {
    setSelectedStatus(status);
    if (status === "All") {
      setFilteredData(data);
    } else {
      const filteredResults = data.filter(
        (item) => item.Status.toLowerCase() === status.toLowerCase()
      );
      setFilteredData(filteredResults);
      setFilteredwithData(filteredResults);
    }
  };

  const customStyles = {
    table: {
      style: {
        border: "1px solid #dee2e6",
      },
    },
    headRow: {
      style: {
        borderBottom: "1px solid #dee2e6",
      },
    },
    rows: {
      style: {
        minHeight: "72px",
        borderBottom: "1px solid #dee2e6",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        fontSize: "1.2em",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        fontSize: "1em",
        borderRight: "1px solid #dee2e6",
      },
    },
  };

  return (
    <div>
      <Nav />
      <div className="main">
        <h3>Attendance closed</h3>
        <label htmlFor="statusFilter">Filter by Status:</label>
        <select
          id="statusFilter"
          value={selectedStatus}
          onChange={(e) => filterByStatus(e.target.value)}
        >
          {s.map((status, index) => (
            <option key={index} value={status}>
              {status}
            </option>
          ))}
        </select>
        <div className="dataTableContainer">
          <input
            type="search"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              const filtered = filteredwithData.filter((item) =>
                Object.values(item).some((value) =>
                  value
                    ? value
                        .toString()
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                    : false
                )
              );
              setFilteredData(filtered);
            }}
            placeholder="Search..."
          />
          <DataTable
            columns={column}
            data={filteredData}
            pagination
            fixedHeader
            fixedHeaderScrollHeight="70vh"
            customStyles={customStyles}
            progressPending={pending}
          />
        </div>
      </div>
      <div
        className="modal fade"
        id="myModal"
        tabIndex="-1"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="myModalLabel">
                Enter mark
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <h5>Name: {name}</h5>
              <h5>Course Title: {courseTitle}</h5>
              <h5>Attendance: {att}</h5>
              <h5>Mark Scored: {mark}</h5>
              <input
                type="number"
                className="form-control"
                value={total}
                onChange={(e) => setTotal(e.target.value)}
                placeholder="Total questions"
              />
              <br />
              <input
                type="number"
                className="form-control"
                value={project}
                onChange={(e) => setProject(e.target.value)}
                placeholder="Project mark"
              />
              <br />
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  var e = 0;
                  if (att >= 70) {
                    e = 5;
                  } else if (att > 60) {
                    e = 3;
                  } else {
                    e = 1;
                  }
                  e = (e / 5) * 100;
                  var m = (mark / total) * 100;

                  var total_score = Math.round((parseInt(project) + m + e) / 3);
                  var month = 0;
                  if (duration <= 40) {
                    month = 1;
                  } else if (duration <= 60) {
                    month = 2;
                  } else if (duration <= 80) {
                    month = 3;
                  } else if (duration <= 160) {
                    month = 4;
                  } else {
                    month = 6;
                  }
                  var grade = "";
                  if (total_score >= 85) {
                    grade = "Outstanding";
                  } else if (total_score >= 75) {
                    grade = "Excellent";
                  } else if (total_score >= 65) {
                    grade = "Good";
                  } else if (total_score >= 65) {
                    grade = "Fair";
                  } else if (total_score >= 40) {
                    grade = "Average";
                  }
                  var d = new Date();
                  var mo = String(d.getMonth() + 1).padStart(2, "0");
                  var year = d.getFullYear();

                  var dx = String(parseInt(reg[0].slice(-5)) + 1);
                  var de = String(parseInt(reg[1].slice(-5)) + 1);
                  var details = {
                    name: name,
                    reg_no: reg[0].slice(0, -dx.length) + dx,
                    course_description: courseTitle,
                    certificate_no: reg[0].slice(0, -dx.length) + de,
                    grade: grade,
                    certificate_name: courseTitle,
                    year: year,
                    mo: mo,
                    months: month,
                    rid: rid,
                  };
                  if (window.confirm(JSON.stringify(details)) == true) {
                    axios
                      .post(url + "/edusphere/generatecertificate", details)
                      .then((response) => {})
                      .then(() => {
                        window.location.reload();
                      });
                  }
                }}
              >
                Generate Certificate
              </button>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Viewexam;
