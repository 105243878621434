import React, { useState } from "react";

const PermissionModal = ({ isOpen, onClose, staffId, getpermison }) => {
  const [permissionStarttime, setPermissionStarttime] = useState("");
  const [permissionEndtime, setPermissionEndtime] = useState("");

  const handleSubmit = () => {
    const now = new Date();
    const formattedDateTime =
      now.getFullYear() +
      "-" +
      String(now.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(now.getDate()).padStart(2, "0") +
      " " +
      now.getHours() +
      ":" +
      now.getMinutes() +
      ":" +
      now.getSeconds();
    const value = {
      staffid: staffId,
      starttime: permissionStarttime,
      endtime: permissionEndtime,
    };
    getpermison(value);
  };

  return (
    <div
      className={`modal ${isOpen ? "show" : ""}`}
      style={{ display: isOpen ? "block" : "none" }}
      tabIndex="-1"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Request Permission</h5>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
            ></button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label htmlFor="permissionStarttime">Permission Start Time</label>
              <input
                type="datetime-local"
                className="form-control"
                onChange={(e) => {
                  const dateTimeValue = new Date(e.target.value);

                  const formattedDateTime =
                    dateTimeValue.getFullYear() +
                    "-" +
                    String(dateTimeValue.getMonth() + 1).padStart(2, "0") +
                    "-" +
                    String(dateTimeValue.getDate()).padStart(2, "0") +
                    " " +
                    String(dateTimeValue.getHours()).padStart(2, "0") +
                    ":" +
                    String(dateTimeValue.getMinutes()).padStart(2, "0") +
                    ":" +
                    String(dateTimeValue.getSeconds()).padStart(2, "0");

                  setPermissionStarttime(formattedDateTime);
                }}
                value={permissionStarttime}
              />
            </div>
            <div className="form-group">
              <label htmlFor="permissionEndtime">Permission End Time</label>
              <input
                type="datetime-local"
                className="form-control"
                onChange={(e) => {
                  const dateTimeValue = new Date(e.target.value);

                  const formattedDateTime =
                    dateTimeValue.getFullYear() +
                    "-" +
                    String(dateTimeValue.getMonth() + 1).padStart(2, "0") +
                    "-" +
                    String(dateTimeValue.getDate()).padStart(2, "0") +
                    " " +
                    String(dateTimeValue.getHours()).padStart(2, "0") +
                    ":" +
                    String(dateTimeValue.getMinutes()).padStart(2, "0") +
                    ":" +
                    String(dateTimeValue.getSeconds()).padStart(2, "0");

                  setPermissionEndtime(formattedDateTime);
                }}
                value={permissionEndtime}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onClose}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PermissionModal;
