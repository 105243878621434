import Footer from "./Footer";
import Header from "./Header";
const Mongodb = () => {
  return (
    <div className="container-fuild">
      <Header />
      <div className="container">
        <div className="row border border-dark m-1">
          <div className="row text-center m-3">
            <h1>
              <b>
                <u> Mongo DB</u>
              </b>
            </h1>
          </div>

          <div className="row m-3">
            <h4>
              MongoDB tutorial provides basic and advanced concepts of SQL. Our
              MongoDB tutorial is designed for beginners and professionals.
              MongoDB is a No SQL database. It is an open-source,
              cross-platform, document-oriented database written in C++.
            </h4>
          </div>

          <div className="row m-5 h4">
            <fieldset>
              <legend style={{ width: "40%" }}>Topics:</legend>
              <ul>
                <li>Introduction to MongoDB</li>
                <li>Operators</li>
                <li>Database Commands</li>
                <li>Collection</li>
                <li>CRUD document</li>
                <li>Mongo Shell</li>
                <li>Cloud</li>
                <li>Tools</li>
                <li>Differences</li>
                <li>Connectivity</li>
                <li>Misc</li>
              </ul>
            </fieldset>
          </div>

          <div className="row m-5">
            <h2>
              <b> Certificate:</b>
            </h2>
            <h2>
              <p>Mongo DB</p>
            </h2>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Mongodb;
