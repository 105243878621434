import React from 'react';
import Header from './course_header'


import Footer from './course_footer';
import AboutPage from './course_about';

const Only_about = () => {


  return (
    <>
      <Header />

   
          {/* Your contact page content here */}
          <AboutPage />
     


      <Footer />
    </>
  );
};

export default Only_about;
