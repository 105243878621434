import axios from "axios";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../components/Navbar";
import { url } from "../link";
const Addprojectcommentment = () => {
  const { state } = useLocation();
  const [commited, setcommited] = useState("");
  const [status, setstatus] = useState("requested");
  const [pid, setpid] = useState(state);

  const submitdata = () => {
    const value = { commited: commited, status: status, pid: pid };
    axios
      .post(url + "/edusphere/insertprojectcommentment", value)
      .then((res) => {
        alert("success");
        setcommited("");
      });
  };
  return (
    <div>
      <Navbar />
      <h1>Add projectcommentment</h1>
      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setcommited(e.target.value)}
          value={commited}
          placeholder="Enter commited"
        />
        <label htmlFor="commited">commited</label>
      </div>

      {/* <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setstatus(e.target.value)}
          value={status}
          placeholder="Enter status"
        />
        <label htmlFor="status">status</label>
      </div> */}

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setpid(e.target.value)}
          value={pid}
          readOnly
          placeholder="Enter pid"
        />
        <label htmlFor="pid">pid</label>
      </div>

      <input
        type="submit"
        className="btn btn-primary"
        onClick={submitdata}
        style={{ width: "100%" }}
      />
    </div>
  );
};
export default Addprojectcommentment;
