import axios from "axios";
import { useState } from "react";
import Nav from "./Nav";
import { url } from "../link";
const Addcoursecat = () => {
  const [name, setname] = useState("");
  const [price, setprice] = useState("");
  const [duration, setduration] = useState("");
  const [coursetitle, setcoursetitle] = useState("");
  const submitdata = () => {
    const value = {
      name: name,
      price: price,
      duration: duration,
      coursetitle: coursetitle,
    };
    axios.post(url + "/edusphere/insertcoursecat", value);
    alert("success");
    setname("");
    setprice("");
    setduration("");
    setcoursetitle("");
  };
  return (
    <div>
      <Nav />
      <div className="main">
        <div className="page-container">
          <h3>View Course</h3>

          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => setname(e.target.value)}
              value={name}
              placeholder="Enter name"
            />
            <label htmlFor="name">name</label>
          </div>

          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => setprice(e.target.value)}
              value={price}
              placeholder="Enter price"
            />
            <label htmlFor="price">price</label>
          </div>

          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => setduration(e.target.value)}
              value={duration}
              placeholder="Enter duration"
            />
            <label htmlFor="duration">duration</label>
          </div>

          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => setcoursetitle(e.target.value)}
              value={coursetitle}
              placeholder="Enter coursetitle"
            />
            <label htmlFor="coursetitle">coursetitle</label>
          </div>

          <input
            type="submit"
            className="btn btn-primary"
            onClick={submitdata}
            style={{ width: "100%" }}
          />
        </div>
      </div>
    </div>
  );
};
export default Addcoursecat;
