import Footer from "./Footer";
import Header from "./Header";
const Cs = () => {
  return (
    <div className="container-fuild">
      <Header />
      <div className="container">
        <div className="row border border-dark m-1">
          <div className="row text-center m-3">
            <h1>
              <b>
                <u> C# Progarmming</u>
              </b>
            </h1>
          </div>

          <div className="row m-3">
            <h4>
              C# (C Sharp) is one of the most popular programming languages
              which is widely used for building Windows applications, mobile
              applications, and games. This course is taught in a practical GOAL
              oriented way to learn C# programming. It is recommended for you to
              practice the code assignments given after each C Sharp tutorial to
              learn C Sharp fast and easily.
            </h4>
          </div>

          <div className="row m-5 h4">
            <fieldset>
              <legend style={{ width: "40%" }}>Topics:</legend>
              <ul>
                <li>Introduction to C#</li>
                <li>Basic Syntax</li>
                <li>Conditional Statement</li>
                <li>Loop</li>
                <li>Patterns</li>
                <li>Array</li>
                <li>Multi Dimension Array</li>
                <li>String</li>
                <li>Functions</li>
                <li>Files</li>
                <li>OOPS Concepts</li>
                <li>Class and Objects</li>
                <li>Collections</li>
                <li>Exception handling</li>
                <li>File handling</li>
                <li>Polymorphism</li>
                <li>Inheritance</li>
                <li>Database</li>
              </ul>
            </fieldset>
          </div>

          <div className="row m-5">
            <h2>
              <b> Certificate:</b>
            </h2>
            <h2>
              <p>Programming in C#</p>
            </h2>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Cs;
