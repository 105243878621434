import React, { useState } from 'react';
import CourseDownloadButton from './CourseDownloadButton';

const CourseList = () => {
  const initialCourses = [
    {
      id: 1,
    title: 'C',
    category: 'IT Programs',
    details: [
      {
        title: 'Introduction to C',
        subTopics: [],
      },
      {
        title: 'Variables and Data Types',
        subTopics: [],
      },
      {
        title: 'Control Statements',
        subTopics: [],
      },
      {
        title: 'Loops',
        subTopics: ['While Loop', 'For Loop', 'Do-While Loop'],
      },
      {
        title: 'Arrays',
        subTopics: ['One-Dimensional Array', 'Multi-Dimensional Array'],
      },
      {
        title: 'Pointers',
        subTopics: [],
      },
      {
        title: 'Functions',
        subTopics: [],
      },
      {
        title: 'Strings',
        subTopics: [],
      },
      {
        title: 'File Handling',
        subTopics: [],
      },
      {
        title: 'Structures',
        subTopics: [],
      },
    ],
    instructor: 'Hariharan, Rajasekar, Rajesh',
    duration: '4 weeks',
    schedule: 'Week Days and Week Ends',
    certificate: 'Programming in C',
  },
  {
    id: 2,
  title: 'C++',
  category: 'IT Programs',
  details: [
    {
      title: 'Introduction to C++',
      subTopics: [],
    },
    {
      title: 'Variables and Data Types',
      subTopics: [],
    },
    {
      title: 'Control Statements',
      subTopics: [],
    },
    {
      title: 'Loops',
      subTopics: ['While Loop', 'For Loop', 'Do-While Loop'],
    },
    {
      title: 'Arrays',
      subTopics: ['One-Dimensional Array', 'Multi-Dimensional Array'],
    },
    {
      title: 'Functions',
      subTopics: [],
    },
    {
      title: 'Strings',
      subTopics: [],
    },
    {
      title: 'File Handling',
      subTopics: [],
    },
    {
      title: 'Class and Objects',
      subTopics: [],
    },
    {
      title: 'Abstraction',
      subTopics: [],
    },
    {
      title: 'Encapsulation',
      subTopics: ['Friend Function','Friend Class'],
    },
    {
      title: 'Static',
      subTopics: ['Static Function','Static Class'],
    },
    {
      title: 'Scope of variable',
      subTopics: [],
    },
    {
      title: 'Enum',
      subTopics: [],
    },
    {
      title: 'Constructor and Destructor',
      subTopics: [],
    },
    {
      title: 'Exception Handling',
      subTopics: [],
    },
    {
      title: 'Polymorphism',
      subTopics: [],
    },
    {
      title: 'Inheritance',
      subTopics: [],
    },
    {
      title: 'Virtual base class',
      subTopics: [],
    },
  ],
  instructor: 'Hariharan, Rajasekar, Rajesh',
  duration: '4 weeks',
  schedule: 'Week Days and Week Ends',
  certificate: 'Programming in C++',
},
{
  id: 3,
title: 'Java',
category: 'IT Programs',
details: [
  {
    title: 'Introduction to Java',
    subTopics: [],
  },
  {
    title: 'Variables and Data Types',
    subTopics: [],
  },
  {
    title: 'Control Statements',
    subTopics: [],
  },
  {
    title: 'Loops',
    subTopics: ['While Loop', 'For Loop', 'Do-While Loop'],
  },
  {
    title: 'Arrays',
    subTopics: ['One-Dimensional Array', 'Multi-Dimensional Array'],
  },
  {
    title: 'Functions',
    subTopics: [],
  },
  {
    title: 'Strings',
    subTopics: ['String Basics', 'String Functions','String Buffer','String Builder'],
  },
  {
    title: 'File Handling',
    subTopics: [],
  },
  {
    title: 'Class and Objects',
    subTopics: [],
  },
  {
    title: 'Abstraction',
    subTopics: [],
  },
  {
    title: 'Encapsulation',
    subTopics: [],
  },
  {
    title: 'Static',
    subTopics: [],
  },
  {
    title: 'Wrapper Class',
    subTopics: [],
  },
  {
    title: 'Access Specifiers',
    subTopics: [],
  },
  {
    title: 'Constructor',
    subTopics: [],
  },
  {
    title: 'Exception Handling',
    subTopics: [],
  },
  {
    title: 'Polymorphism',
    subTopics: [],
  },
  {
    title: 'Inheritance',
    subTopics: [],
  },
  {
    title: 'Super, Final',
    subTopics: [],
  },
  {
    title: 'Instance of',
    subTopics: [],
  },
  {
    title: 'Interface',
    subTopics: [],
  },
  {
    title: 'Polymorphism',
    subTopics: [],
  },
  {
    title: 'Inner class',
    subTopics: [],
  },
  {
    title: 'Collections',
    subTopics: [],
  },
  {
    title: 'Generics',
    subTopics: [],
  },
  {
    title: 'Threading',
    subTopics: [],
  },
  {
    title: 'Regular Expression',
    subTopics: [],
  },
  {
    title: 'Jdbc',
    subTopics: [],
  },
],
instructor: 'Hariharan, Rajasekar',
duration: '6 weeks',
schedule: 'Week Days and Week Ends',
certificate: 'Programming in Java',
},

{
  id: 4,
title: 'Python',
category: 'IT Programs',
details: [
  {
    title: 'Introduction to Python',
    subTopics: [],
  },
  {
    title: 'Variables and Data Types',
    subTopics: [],
  },
  {
    title: 'Control Statements',
    subTopics: [],
  },
  {
    title: 'Loops',
    subTopics: ['While Loop', 'For Loop', 'Do-While Loop'],
  },
  {
    title: 'List',
    subTopics: [],
  },
  {
    title: 'Functions',
    subTopics: [],
  },
  {
    title: 'Strings',
    subTopics: ['String Basics', 'String Functions'],
  },
  {
    title: 'File Handling',
    subTopics: [],
  },
  {
    title: 'Class and Objects',
    subTopics: [],
  },
  {
    title: 'Tuple',
    subTopics: [],
  },
  {
    title: 'Set',
    subTopics: [],
  },
  {
    title: 'Dictionary',
    subTopics: [],
  },
  {
    title: 'SQLite',
    subTopics: [],
  },
  {
    title: 'Zip',
    subTopics: [],
  },
  {
    title: 'Map',
    subTopics: [],
  },
  {
    title: 'Exception Handling',
    subTopics: [],
  },
  {
    title: 'Lambda',
    subTopics: [],
  },
  {
    title: 'Filter',
    subTopics: [],
  },
  {
    title: 'JSon',
    subTopics: [],
  },
  {
    title: 'Module',
    subTopics: [],
  },
  {
    title: 'XLSX',
    subTopics: [],
  },
  {
    title: 'Web Scrapping',
    subTopics: [],
  },
  {
    title: 'Regular Expression',
    subTopics: [],
  },
  {
    title: 'Numpy',
    subTopics: [],
  },
  {
    title: 'Pandas',
    subTopics: [],
  },
  {
    title: 'Matplot',
    subTopics: [],
  },
],
instructor: 'Hariharan, Rajasekar',
duration: '6 weeks',
schedule: 'Week Days and Week Ends',
certificate: 'Programming in Python',
},

{
  id: 5,
title: 'My SQL',
category: 'IT Programs',
details: [
  {
    title: 'Introduction to SQL',
    subTopics: [],
  },
  {
    title: 'RDBMS',
    subTopics: ['DDL','DML','DQL','TCL','DCL'],
  },
  {
    title: 'Create Database',
    subTopics: [],
  },
  {
    title: 'Table',
    subTopics: ['Create table', 'Rename table', 'Drop table'],
  },
  {
    title: 'Insert',
    subTopics: ['Insert into','Insert into select','Insert on duplicate key update','Insert ignore'],
  },
  {
    title: 'Data types',
    subTopics: ['Numeric','Text','Date/Time'],
  },
  {
    title: 'Constraints',
    subTopics: ['Not Null', 'Unique','Primary Key',"Foreign Key",'check','default','Auto Increment'],
  },
  {
    title: 'Select',
    subTopics: [],
  },
  {
    title: 'Update',
    subTopics: [],
  },
  {
    title: 'Alter',
    subTopics: [],
  },
  {
    title: 'As',
    subTopics: [],
  },
  {
    title: 'Filter',
    subTopics: [],
  },
  {
    title: 'Logical Operators',
    subTopics: [],
  },
  {
    title: 'Pattern Matching',
    subTopics: [],
  },
  {
    title: 'Delete',
    subTopics: [],
  },
  {
    title: 'Roll back',
    subTopics: [],
  },
  {
    title: 'Commit',
    subTopics: [],
  },
  {
    title: 'Truncate',
    subTopics: [],
  },
  {
    title: 'Function',
    subTopics: ['String function','Numeric function','Date function','Aggregate function'],
  },
  {
    title: 'Group by',
    subTopics: [],
  },
  {
    title: 'Order by',
    subTopics: [],
  },
  {
    title: 'Having',
    subTopics: [],
  },
  {
    title: 'Sorting',
    subTopics: [],
  },
  {
    title: 'Union',
    subTopics: [],
  },
  {
    title: 'Joins',
    subTopics: [],
  },
  {
    title: 'Sub Queries',
    subTopics: [],
  },
  
  {
    title: 'Exists, Case ,If, Limit,',
    subTopics: [],
  },
  {
    title: 'Coalesce, If null',
    subTopics: [],
  },
  
  {
    title: 'Views',
    subTopics: [],
  },
  {
    title: 'Temporary table',
    subTopics: [],
  },
  
  {
    title: 'Index',
    subTopics: [],
  },
  {
    title: 'Show, Describe, Set, Explain',
    subTopics: [],
  },
  
  {
    title: 'Stored Function',
    subTopics: [],
  },
  {
    title: 'Stored Procedure',
    subTopics: [],
  },
  
  {
    title: 'Trigger',
    subTopics: [],
  },
],
instructor: 'Hariharan, Rajasekar',
duration: '6 weeks',
schedule: 'Week Days and Week Ends',
certificate: 'Database Management using My SQl',
},

{
  id: 6,
title: 'HTML',
category: 'IT Programs',
details: [
  {
    title: 'Introduction to HTML',
    subTopics: [],
  },
  {
    title: 'Basic HTML Tags',
    subTopics: [],
  },
  {
    title: 'Formatting Tags',
    subTopics: [],
  },
  {
    title: 'Forms and Input Tags',
    subTopics: [],
  },
  {
    title: 'Multi Media Tags',
    subTopics: [],
  },
  {
    title: 'Link',
    subTopics: [],
  },
  {
    title: 'List',
    subTopics: [],
  },
  {
    title: 'Table',
    subTopics: [],
  },
  {
    title: 'Styles and Semantics',
    subTopics: [],
  },
  
],
instructor: 'Hariharan, Rajasekar',
duration: '2 weeks',
schedule: 'Week Days and Week Ends',
certificate: 'Web design using HTML',
},

{
  id: 7,
title: 'CSS',
category: 'IT Programs',
details: [
  {
    title: 'Introduction to CSS',
    subTopics: [],
  },
  {
    title: 'Selectors',
    subTopics: [],
  },
  {
    title: 'Colors',
    subTopics: ['Colors','Names','RGB colors','HEX colors'],
  },
  {
    title: 'Background',
    subTopics: ['Background','Image','Repeat-x','Repeat-y','Position','Attachment'],
  },
  {
    title: 'Box Model',
    subTopics: [],
  },
  {
    title: 'Borders',
    subTopics: ['Style','Width','Rounded Border'],
  },
  {
    title: 'Text',
    subTopics: ['Text','Alignment','Decoration','Transform','Spacing','Shadow'],
  },
  {
    title: 'Fonts',
    subTopics: ['Fonts','Family','Style','Size','Google font'],
  },
  {
    title: 'Icons',
    subTopics: ['CSS Icons','Unicode Icons','Google Icons'],
  },
  {
    title: 'Table',
    subTopics: ['String Basics', 'String Functions'],
  },
  {
    title: 'List',
    subTopics: [],
  },
  {
    title: 'Link',
    subTopics: [],
  },
  {
    title: 'Max-width',
    subTopics: [],
  },
  {
    title: 'Align',
    subTopics: [],
  },
  {
    title: 'Navigation bar',
    subTopics: [],
  },
  {
    title: 'Dropdown',
    subTopics: [],
  },
  {
    title: 'Float',
    subTopics: [],
  },
  {
    title: 'Important',
    subTopics: [],
  },
  
],
instructor: 'Hariharan, Rajasekar',
duration: '2 weeks',
schedule: 'Week Days and Week Ends',
certificate: 'Web Design using CSS',
},

{
  id: 8,
title: 'Java Script',
category: 'IT Programs',
details: [
  {
    title: 'Introduction to Java Script',
    subTopics: [],
  },
  {
    title: 'Variables',
    subTopics: [],
  },
  {
    title: 'Let',
    subTopics: [],
  },
  {
    title: 'Constant',
    subTopics: [],
  },
  {
    title: 'Data Type',
    subTopics: [],
  },
  {
    title: 'Statement',
    subTopics: ['Conditional','Looping'],
  },
  {
    title: 'Conditional',
    subTopics: ['If','If else','Switch'],
  },
  {
    title: 'Switch',
    subTopics: ['String Basics', 'String Functions'],
  },
  {
    title: 'loop',
    subTopics: ['For','While','Do while'],
  },
  {
    title: 'Operators',
    subTopics: ['Assignment Operator','Comparison Operators','Logical Operators','Conditional Operators'],
  },
  {
    title: 'Function',
    subTopics: [],
  },
  {
    title: 'Object',
    subTopics: ['Array Object','String Object','Math Object','Date Object','Global Object','Number Object'],
  },
  {
    title: 'String',
    subTopics: [],
  },
  {
    title: 'Array',
    subTopics: [],
  },
  {
    title: 'Dates',
    subTopics: [],
  },
  {
    title: 'Random',
    subTopics: [],
  },
  {
    title: 'Set',
    subTopics: [],
  },
  {
    title: 'Map',
    subTopics: [],
  },
  {
    title: 'RegExp',
    subTopics: [],
  },
  {
    title: 'Scope',
    subTopics: [],
  },
  {
    title: 'Json',
    subTopics: [],
  },
],
instructor: 'Hariharan, Rajasekar',
duration: '3 weeks',
schedule: 'Week Days and Week Ends',
certificate: 'Front End design using Java Script',
},

{
  id: 9,
title: 'Jquery',
category: 'IT Programs',
details: [
  {
    title: 'Introduction to Jquery',
    subTopics: [],
  },
  {
    title: 'Syntax',
    subTopics: [],
  },
  {
    title: 'Selectors',
    subTopics: [],
  },
{
    title: 'Effects',
    subTopics: ['Sliding','Fading','Hide/Show','Delete'],
  },
  {
    title: 'Event',
    subTopics: ['Working with Events'],
  },
  {
    title: 'Animate',
    subTopics: [],
  },
  {
    title: 'Callback',
    subTopics: [],
  },
  {
    title: 'Data Type',
    subTopics: [],
  },
  {
    title: 'Function',
    subTopics: [],
  },
  {
    title: 'Get',
    subTopics: [],
  },
  {
    title: 'Set',
    subTopics: [],
  },
  {
    title: 'Traversing',
    subTopics: ['Travasal functions'],
  },
  {
    title: 'Ajax an Json',
    subTopics: ['Ajax with jQuery','Load method','jQuery get and getJson methods','jQuery POST request','Retrieving js file','Helper methods'],
  },
],
instructor: 'Hariharan, Rajasekar',
duration: '3 weeks',
schedule: 'Week Days and Week Ends',
certificate: 'Front End Desig using JQuery',
},

{
  id: 10,
title: 'SQL Server',
category: 'IT Programs',
details: [
  {
    title: 'SQL Database',
    subTopics: [],
  },
  {
    title: 'SQL Table',
    subTopics: [],
  },
  {
    title: 'SQL Select',
    subTopics: [],
  },
  {
    title: 'SQL Clause',
    subTopics: [],
  },
  {
    title: 'SQL Order By',
    subTopics: [],
  },
  {
    title: 'SQL Insert',
    subTopics: [],
  },
  {
    title: 'SQL Update',
    subTopics: [],
  },
  {
    title: 'SQL Delete',
    subTopics: [],
  },
  {
    title: 'SQL Join',
    subTopics: [],
  },
  {
    title: 'SQL Keys',
    subTopics: [],
  },
  {
    title: 'SQL Injection',
    subTopics: [],
  },
  {
    title: 'SQL String Functions',
    subTopics: [],
  },
  {
    title: 'Misc',
    subTopics: [],
  },
],
instructor: 'Hariharan, Rajasekar',
duration: '6 weeks',
schedule: 'Week Days and Week Ends',
certificate: 'Database Management in SQL(Server)',
},

{
  id: 11,
title: 'C#',
category: 'IT Programs',
details: [
  {
    title: 'Introduction to C#',
    subTopics: [],
  },
  {
    title: 'Basic Syntax',
    subTopics: [],
  },
  {
    title: 'Conditional Statement',
    subTopics: [],
  },
  {
    title: 'Loops',
    subTopics: ['While Loop', 'For Loop', 'Do-While Loop'],
  },
  {
    title: 'Patterns',
    subTopics: [],
  },
  {
    title: 'Array',
    subTopics: [],
  },
  {
    title: 'Multi Dimension Array',
    subTopics: [],
  },
  {
    title: 'File Handling',
    subTopics: [],
  },
  {
    title: 'String',
    subTopics: [],
  },
  {
    title: 'Functions',
    subTopics: [],
  },
  {
    title: 'Files',
    subTopics: [],
  },
  {
    title: 'OOPs Concepts',
    subTopics: [],
  },
  {
    title: 'Class and Objects',
    subTopics: [],
  },
  {
    title: 'Collections',
    subTopics: [],
  },
  {
    title: 'Exception handling',
    subTopics: [],
  },
  {
    title: 'File handling',
    subTopics: [],
  },
  {
    title: 'Polymorphism',
    subTopics: [],
  },
  {
    title: 'Inheritance',
    subTopics: [],
  },
  {
    title: 'Database',
    subTopics: [],
  },
],
instructor: 'Hariharan, Rajasekar',
duration: '6 weeks',
schedule: 'Week Days and Week Ends',
certificate: 'Programming in C#',
},

{
  id: 12,
title: 'Data Structure',
category: 'IT Programs',
details: [
  {
    title: 'Introduction to Data Structure',
    subTopics: [],
  },
  {
    title: 'Array',
    subTopics: [],
  },
  {
    title: 'Linked List',
    subTopics: [],
  },
  {
    title: 'Stack',
    subTopics: [],
  },
  {
    title: 'Queue',
    subTopics: [],
  },
  {
    title: 'Searching',
    subTopics: [],
  },
  {
    title: 'Sorting',
    subTopics: [],
  },
  {
    title: 'Tree',
    subTopics: [],
  },
  {
    title: 'Graphs',
    subTopics: [],
  },
],
instructor: 'Hariharan, Rajasekar',
duration: '6 weeks',
schedule: 'Week Days and Week Ends',
certificate: 'Programming in Data Structure ',
},

{
  id: 13,
title: 'React js',
category: 'IT Programs',
details: [
  {
    title: 'React Introduction',
    subTopics: [],
  },
  {
    title: 'React Version',
    subTopics: [],
  },
  {
    title: 'React Installation',
    subTopics: [],
  },
  {
    title: 'create-react-app',
    subTopics: [],
  },
  {
    title: 'React Features',
    subTopics: [],
  },
  {
    title: 'Pros & Cons',
    subTopics: [],
  },
  {
    title: 'StReactJS vs AngularJSrings',
    subTopics: [],
  },
  {
    title: 'ReactJS vs ReactNative',
    subTopics: [],
  },
  {
    title: 'React vs Vue',
    subTopics: [],
  },
  {
    title: 'React JSX',
    subTopics: [],
  },
  {
    title: 'React Components',
    subTopics: [],
  },
  {
    title: 'React State',
    subTopics: [],
  },
  {
    title: 'React Props',
    subTopics: [],
  },
  {
    title: 'React Props Validation',
    subTopics: [],
  },
  {
    title: 'React State vs Props',
    subTopics: [],
  },
  {
    title: 'React Constructor',
    subTopics: [],
  },
  {
    title: 'React Component API',
    subTopics: [],
  },
  {
    title: 'Component Life Cycle',
    subTopics: [],
  },
  {
    title: 'JSReact Formson',
    subTopics: [],
  },
  {
    title: 'Controlled vs Uncontrolled',
    subTopics: [],
  },
  {
    title: 'React Events',
    subTopics: [],
  },
  {
    title: 'Conditional Rendering',
    subTopics: [],
  },
  {
    title: 'React Lists',
    subTopics: [],
  },
  {
    title: 'React Keys',
    subTopics: [],
  },
  {
    title: 'React Refs',
    subTopics: [],
  },
  {
    title: 'React Fragments',
    subTopics: [],
  },
  {
    title: 'React Router',
    subTopics: [],
  },
  {
    title: 'React CSS',
    subTopics: [],
  },
  {
    title: 'React Animation',
    subTopics: [],
  },
  {
    title: 'React Bootstrap',
    subTopics: [],
  },
  {
    title: 'React Map',
    subTopics: [],
  },
  {
    title: 'React Table',
    subTopics: [],
  },
  {
    title: 'Higher-Order Components',
    subTopics: [],
  },
  {
    title: 'React Code Splitting',
    subTopics: [],
  },
  {
    title: 'React Context',
    subTopics: [],
  },
  {
    title: 'React Hooks',
    subTopics: [],
  },
  {
    title: 'React Flux Concept',
    subTopics: [],
  },
  {
    title: 'React Flux Vs MVC',
    subTopics: [],
  },
  {
    title: 'React Redux',
    subTopics: [],
  },
  {
    title: 'React Redux Example',
    subTopics: [],
  },
  {
    title: 'React Portals',
    subTopics: [],
  },
  {
    title: 'React Error Boundaries',
    subTopics: [],
  },
  {
    title: 'Loop Array in React JS',
    subTopics: [],
  },
  {
    title: 'React Axios Delete Request Example',
    subTopics: [],
  },
  {
    title: 'React Multiple Checkbox',
    subTopics: [],
  },
  {
    title: 'React-icons',
    subTopics: [],
  },
  {
    title: 'React Date Picker',
    subTopics: [],
  },
  {
    title: 'React Helmet',
    subTopics: [],
  },
  {
    title: 'Inline Style in React',
    subTopics: [],
  },
  {
    title: 'jQuery vs. React',
    subTopics: [],
  },
  {
    title: 'ReactJS Architecture',
    subTopics: [],
  },
  {
    title: 'ReactJS PropTypes',
    subTopics: [],
  },
  {
    title: 'BrowserRouter in React',
    subTopics: [],
  },
  {
    title: 'React vs. Svelte',
    subTopics: [],
  },
  {
    title: 'Button in React',
    subTopics: [],
  },
  {
    title: 'What is Dom in React',
    subTopics: [],
  },
  {
    title: 'Unit Testing in React',
    subTopics: [],
  },
  {
    title: 'Carousel in React',
    subTopics: [],
  },
  {
    title: 'React-Paginate',
    subTopics: [],
  },
  {
    title: 'What is the useState in React',
    subTopics: [],
  },
  {
    title: 'React Time-Picker',
    subTopics: [],
  },  
],
instructor: 'Hariharan',
duration: '6 weeks',
schedule: 'Week Days and Week Ends',
certificate: 'Front End Design using React js',
},

{
  id: 14,
title: 'Mongo DB',
category: 'IT Programs',
details: [
  {
    title: 'Introduction to Mongo DB',
    subTopics: [],
  },
  {
    title: 'Operators',
    subTopics: [],
  },
  {
    title: 'Database Commands',
    subTopics: [],
  },
  {
    title: 'Collection',
    subTopics: [],
  },
  {
    title: 'CRUD document',
    subTopics: [],
  },
  {
    title: 'Mongo Shell',
    subTopics: [],
  },
  {
    title: 'Cloud',
    subTopics: [],
  },
  {
    title: 'Tools',
    subTopics: [],
  },
  {
    title: 'Differences',
    subTopics: [],
  },
  {
    title: 'Connectivity',
    subTopics: [],
  },
  {
    title: 'Misc',
    subTopics: [],
  },
],
instructor: 'Hariharan, Rajasekar',
duration: '2 weeks',
schedule: 'Week Days and Week Ends',
certificate: 'Mongo DB',
},

{
  id: 15,
title: 'Selenium with Java or Python',
category: 'IT Programs',
details: [
  {
    title: 'Introduction to Selenium',
    subTopics: [],
  },
  {
    title: 'Selenium WebDriver',
    subTopics: [],
  },
  {
    title: 'Web driver installation',
    subTopics: [],
  },
  {
    title: 'Web driver commands',
    subTopics: [],
  },
  {
    title: 'Running test on Chrome',
    subTopics: [],
  },
  {
    title: 'Running test on Firefox',
    subTopics: [],
  },
  {
    title: 'Running test on IE',
    subTopics: [],
  },
  {
    title: 'Locating Strategies',
    subTopics: [],
  },
  {
    title: 'Handling Drop downs',
    subTopics: [],
  },
  {
    title: 'Drag Drop',
    subTopics: [],
  },
  {
    title: 'Alerts',
    subTopics: [],
  },
  {
    title: 'Scrolling',
    subTopics: [],
  },
  {
    title: 'Browser command',
    subTopics: [],
  },
  {
    title: 'Navigation command',
    subTopics: [],
  },
  {
    title: 'Web element command',
    subTopics: [],
  },
  {
    title: 'Raido button',
    subTopics: [],
  },
  {
    title: 'Check box',
    subTopics: [],
  },
  {
    title: 'Assertions',
    subTopics: [],
  },
  {
    title: 'Grid',
    subTopics: [],
  },
],
instructor: 'Rajasekar',
duration: '8 weeks',
schedule: 'Week Days and Week Ends',
certificate: 'Selenium Testing',
},

{
  id: 16,
title: 'Spring Boot',
category: 'IT Programs',
details: [
  {
    title: 'Introduction to Spring Boot',
    subTopics: [],
  },
  {
    title: 'Bootstrapping',
    subTopics: [],
  },
  {
    title: 'Tomcat Deployment',
    subTopics: [],
  },
  {
    title: 'Build systems',
    subTopics: [],
  },
  {
    title: 'Code structure',
    subTopics: [],
  },
  {
    title: 'Beans and dependency Injection',
    subTopics: [],
  },
  {
    title: 'Runners',
    subTopics: [],
  },
  {
    title: 'Application Properties',
    subTopics: [],
  },
  {
    title: 'Logging',
    subTopics: [],
  },
  {
    title: 'Restful web Services',
    subTopics: [],
  },
  {
    title: 'Exception Handling',
    subTopics: [],
  },
  {
    title: 'Interceptor',
    subTopics: [],
  },
  {
    title: 'Servlet Filter',
    subTopics: [],
  },
  {
    title: 'Scheduling',
    subTopics: [],
  },
  {
    title: 'HTTPS',
    subTopics: [],
  },
],
instructor: 'Hariharan',
duration: '3 weeks',
schedule: 'Week Days and Week Ends',
certificate: 'Programming in Spring Boot',
},

{
  id: 17,
title: 'Hibernate',
category: 'IT Programs',
details: [
  {
    title: 'Introduction to Hibernate',
    subTopics: [],
  },
  {
    title: 'Log4j',
    subTopics: [],
  },
  {
    title: 'Inheritance Mapping',
    subTopics: [],
  },
  {
    title: 'Hibernate Mapping',
    subTopics: [],
  },
  {
    title: 'HQL',
    subTopics: [],
  },
  {
    title: 'HCQL',
    subTopics: [],
  },
  {
    title: 'Named Query',
    subTopics: [],
  },
  {
    title: 'Hiberanate Caching',
    subTopics: [],
  },
  {
    title: 'Second level cache',
    subTopics: [],
  },
  {
    title: 'Intergration',
    subTopics: [],
  },
  {
    title: 'Misc',
    subTopics: [],
  },
],
instructor: 'Hariharan',
duration: '3 weeks',
schedule: 'Week Days and Week Ends',
certificate: 'Hibernate',
},

{
  id: 18,
title: 'JSP',
category: 'IT Programs',
details: [
  {
    title: 'Introduction to JSP',
    subTopics: [],
  },
  {
    title: 'Actions',
    subTopics: [],
  },
  {
    title: 'Implicit object',
    subTopics: [],
  },
  {
    title: 'Conditional Statement',
    subTopics: [],
  },
  {
    title: 'Client Request',
    subTopics: [],
  },
  {
    title: 'Server Response',
    subTopics: [],
  },
  {
    title: 'HTTP status codes',
    subTopics: [],
  },
  {
    title: 'Form processing',
    subTopics: [],
  },
  {
    title: 'Filters',
    subTopics: [],
  },
  {
    title: 'Files',
    subTopics: [],
  },
  {
    title: 'Mail',
    subTopics: [],
  },
  {
    title: 'Database Access',
    subTopics: [],
  },
  {
    title: 'XML data',
    subTopics: [],
  },
  {
    title: 'Java Beans',
    subTopics: [],
  },
  {
    title: 'Custom tags',
    subTopics: [],
  },
  {
    title: 'Expression Language',
    subTopics: [],
  },
  {
    title: 'Expression Handling',
    subTopics: [],
  },
  {
    title: 'Debugging',
    subTopics: [],
  },
  {
    title: 'Security',
    subTopics: [],
  },
  {
    title: 'Internationalization',
    subTopics: [],
  },
  
],
instructor: 'Hariharan',
duration: '3 weeks',
schedule: 'Week Days and Week Ends',
certificate: 'Programming in JSP',
},

{
  id: 19,
title: 'Servlets',
category: 'IT Programs',
details: [
  {
    title: 'Introduction to Servlets',
    subTopics: [],
  },
  {
    title: 'Client Request to Servlet',
    subTopics: [],
  },
  {
    title: 'Server response',
    subTopics: [],
  },
  {
    title: 'Request dispatcher Interface',
    subTopics: [],
  },
  {
    title: 'Filter API',
    subTopics: [],
  },
  {
    title: 'Config and Servlet Context',
    subTopics: [],
  },
  {
    title: 'Events and listeners',
    subTopics: [],
  },
  {
    title: 'Excepting Handling',
    subTopics: [],
  },
  {
    title: 'Cookies',
    subTopics: [],
  },
  {
    title: 'Session Tracking',
    subTopics: [],
  },
  {
    title: 'IO Streams',
    subTopics: [],
  },
  {
    title: 'File upload',
    subTopics: [],
  },
  {
    title: 'Mail',
    subTopics: [],
  },
  {
    title: 'ZiJDBC',
    subTopics: [],
  },
],
instructor: 'Hariharan',
duration: '3 weeks',
schedule: 'Week Days and Week Ends',
certificate: 'Programming in Servlets',
},

{
  id: 20,
title: 'Flask',
category: 'IT Programs',
details: [
  {
    title: 'Introduction to Flask',
    subTopics: [],
  },
  {
    title: 'App roputing',
    subTopics: [],
  },
  {
    title: 'URL building',
    subTopics: [],
  },
  {
    title: 'HTTP methods',
    subTopics: [],
  },
  {
    title: 'Templates',
    subTopics: [],
  },
  {
    title: 'Request Object',
    subTopics: [],
  },
  {
    title: 'Cookies',
    subTopics: [],
  },
  {
    title: 'Database',
    subTopics: [],
  },
  {
    title: 'Session',
    subTopics: [],
  },
  {
    title: 'Uploading',
    subTopics: [],
  },
  {
    title: 'Redirect and erroret',
    subTopics: [],
  },
  {
    title: 'Flashing',
    subTopics: [],
  },
  {
    title: 'Mail extension',
    subTopics: [],
  },
  {
    title: 'Sqlite',
    subTopics: [],
  },
  {
    title: 'WTF',
    subTopics: [],
  },
],
instructor: 'Hariharan, Rajasekar',
duration: '4 weeks',
schedule: 'Week Days and Week Ends',
certificate: 'Programming in Flask',
},

{
  id: 21,
title: 'Django',
category: 'IT Programs',
details: [
  {
    title: 'Introduction to Django',
    subTopics: [],
  },
  {
    title: 'Django Features',
    subTopics: [],
  },
  {
    title: 'Django Pros and Cons',
    subTopics: [],
  },
  {
    title: 'Django MTV Architecture',
    subTopics: [],
  },
  {
    title: 'Django Models',
    subTopics: [],
  },
  {
    title: 'Django Views',
    subTopics: [],
  },
  {
    title: 'Django URLs and URL Conf',
    subTopics: [],
  },
  {
    title: 'Django Database',
    subTopics: [],
  },
  {
    title: 'Django Cookies Handling',
    subTopics: [],
  },
  {
    title: 'Django Form Handling and Validation',
    subTopics: [],
  },
  {
    title: 'Django File Upload',
    subTopics: [],
  },
  {
    title: 'Django Bootstrap',
    subTopics: [],
  },
  {
    title: 'Django Request and Response',
    subTopics: [],
  },
  {
    title: 'Django Migrations',
    subTopics: [],
  },
  {
    title: 'AJAX in Django',
    subTopics: [],
  },
  {
    title: 'Django Web Hosting',
    subTopics: [],
  },
  {
    title: 'Django CMS',
    subTopics: [],
  },
  {
    title: 'Django REST Framework',
    subTopics: [],
  },
  {
    title: 'Django Logging',
    subTopics: [],
  },
],
instructor: 'Hariharan, Rajasekar',
duration: '6 weeks',
schedule: 'Week Days and Week Ends',
certificate: 'Programming in Django ',
},
{
  id: 28,
title: 'Java Full Stack',
category: 'Career/Long Term',
details: [
  {
    title: 'Term-1: Foundation Program in Java Web development',
    subTopics: ['HTML','CSS','Java Script','JQuery','Bootstrap','Core Java',"My SQL"],
  },
  {
    title: 'Term-2: Java Full Stack Developer',
    subTopics: ['SpringBoot',"Hibernate","Mongo DB","React Js"],
  },
],
instructor: 'Hariharan',
duration: '14 weeks',
schedule: 'Week Days and Week Ends',
certificate: 'Web development in Java',
},

{
  id: 29,
title: 'Python Full Stack',
category: 'Career/Long Term',
details: [
  {
    title: 'Term-1: Foundation Program in Python Web development',
    subTopics: ['HTML','CSS','Java Script','JQuery','Bootstrap','Core Python',"My SQL"],
  },
  {
    title: 'Term-2: Python Full Stack Developer',
    subTopics: ['Flask',"Django","Mongo DB",'React Js'],
  },
],
instructor: 'Hariharan, Rajasekar',
duration: '3 months',
schedule: 'Week Days and Week Ends',
certificate: 'Web development in Python',
},

// {
//   id: 23,
// title: 'Java Full Stack',
// category: 'Career/Long Term',
// details: [
//   {
//     title: 'Term-1: Foundation Program in Java Web development',
//     subTopics: ['HTML','CSS','Java Script','JQuery','Bootstrap','Java','My SQL'],
//   },
//   {
//     title: 'Term-2: Java Web development',
//     subTopics: ['JSP',"Servlet",'SpringBoot',"Hibernate","Mongo DB"],
//   },
//   {
//     title: 'Term-3: Full Stack development',
//     subTopics: ['React JS','React Native','Selenium'],
//   },
// ],
// instructor: 'Hariharan',
// duration: '24 Weeks',
// schedule: 'Week Days and Week Ends',
// certificate: 'Full Stack in Java',
// },

// {
//   id: 24,
// title: 'Full Stack Python',
// category: 'Career/Long Term',
// details: [
//   {
//     title: 'Term-1: Foundation Program in Java Web development',
//     subTopics: ['HTML','CSS','Java Script','JQuery','Bootstrap','Core Python','My SQL'],
//   },
//   {
//     title: 'Term-2: Python web development',
//     subTopics: ['Flask','Django','Mongo DB'],
//   },
//   {
//     title: 'Trem-3: Full Stack development',
//     subTopics: ['React JS',"React Native","Selenium"],
//   },
// ],
// instructor: 'Hariharan, Rajasekar',
// duration: '24 weeks',
// schedule: 'Week Days and Week Ends',
// certificate: 'Full Stack in Python',
// },

{
  id: 34,
title: 'Data Analyst',
category: 'Career/Long Term',
details: [
  {
    title: 'Introduction to Data analyist',
    subTopics: [],
  },
  {
    title: 'Excel',
    subTopics: ['Intro to Excel','Importing data','Formatting in Excel','Excel Formulae','Data Validation','Calculations'],
  },
  {
    title: 'SQL',
    subTopics: ['Introduction to SQL','DDL Statements','DML Statements','DQL Statements'],
  },
  {
    title: 'Python',
    subTopics: [],
  },
  {
    title: 'Python Objects + List Comprehension',
    subTopics: [],
  },
  {
    title: 'User-defined and Lamda Functions',
    subTopics: [],
  },
  {
    title: 'Numpy',
    subTopics: [],
  },
  {
    title: 'Pandas',
    subTopics: [],
  },
  {
    title: 'Data Frame Manipulation',
    subTopics: [],
  },
  {
    title: 'Viszualisation',
    subTopics: [],
  },
  {
    title: 'EDA',
    subTopics: [],
  },
  {
    title: 'Statistics for Data Science',
    subTopics: [],
  },
  {
    title: 'Data viszualisation with Power BI',
    subTopics: [],
  },
],
instructor: 'Rajasekar',
duration: '15 weeks',
schedule: 'Week Days and Week Ends',
certificate: 'Data analyist',
},

{
  id: 22,
title: 'Data science',
category: 'Career/Long Term',
details: [
  {
    title: 'Introduction to Data Science',
    subTopics: [],
  },
  {
    title: 'Basic Python',
    subTopics: [],
  },
  {
    title: 'Data type in python',
    subTopics: [],
  },
  {
    title: 'Pandas',
    subTopics: [],
  },
  {
    title: 'Numpy',
    subTopics: [],
  },
  {
    title: 'Sci-ki learn',
    subTopics: [],
  },
  {
    title: 'Mat plot',
    subTopics: [],
  },
  {
    title: 'CSV',
    subTopics: [],
  },
  {
    title: 'Python data objects',
    subTopics: [],
  },
  {
    title: 'Manipulating Data',
    subTopics: [],
  },
  {
    title: 'Statistics Basics',
    subTopics: [],
  },
  {
    title: 'Probability basics',
    subTopics: [],
  },
  {
    title: 'Standard deviation',
    subTopics: [],
  },
  {
    title: 'Bais variance Tradeoff',
    subTopics: [],
  },
  {
    title: 'Distance metrics',
    subTopics: [],
  },
  {
    title: 'Error metrics',
    subTopics: [],
  },
  {
    title: 'Machine Learning',
    subTopics: [],
  },
  {
    title: 'Unsuprevised Learning',
    subTopics: [],
  },
  {
    title: 'SVM',
    subTopics: [],
  },
  {
    title: 'ML alogrithms',
    subTopics: [],
  },
  {
    title: 'AI',
    subTopics: [],
  },
  {
    title: 'NLP',
    subTopics: [],
  },
  {
    title: 'Power BI',
    subTopics: [],
  },
  {
    title: 'Data Base',
    subTopics: [],
  },
],
instructor: 'Hariharan, Rajasekar',
duration: '16 weeks',
schedule: 'Week Days and Week Ends',
certificate: 'Data Science',
},

{
  id: 25,
title: 'React Native',
category: 'IT Programs',
details: [
  {
    title: 'Introduction to React Native',
    subTopics: [],
  },
  {
    title: 'Core components',
    subTopics: [],
  },
  {
    title: 'Styling components',
    subTopics: [],
  },
  {
    title: 'Navigation',
    subTopics: [],
  },
  {
    title: 'Next step',
    subTopics: [],
  },
  {
    title: 'Storage',
    subTopics: [],
  },
  {
    title: 'APIs',
    subTopics: [],
  },
  {
    title: 'Misc',
    subTopics: [],
  },
],
instructor: 'Hariharan',
duration: '4 weeks',
schedule: 'Week Days and Week Ends',
certificate: 'Programming in React native',
},

{
  id: 26,
title: 'Node js',
category: 'IT Programs',
details: [
  {
    title: 'Introduction to Node js',
    subTopics: [],
  },
  {
    title: 'Setup Dev Environment',
    subTopics: [],
  },
  {
    title: 'Node JS Modules',
    subTopics: ['Functions','Buffer','Module','Module Types','Core Modules','Local Modules','Module.Exports'],
  },
  {
    title: 'Node Package Mananger',
    subTopics: [],
  },
  {
    title: 'Creating Web server',
    subTopics: ['Creating web server','Handling http requests','Sending requests'],
  },
  {
    title: 'File System',
    subTopics: ['Fs.readFile','Writing a File','Writing a file asynchronously','Opening a file','Deleting a file','Other IO Operations'],
  },
  {
    title: 'Debugging Node JS Application',
    subTopics: ['Core Node JS debugger', 'Debugging with Visual Studio'],
  },
  {
    title: 'Events',
    subTopics: ['EventEmitter class','Returning event emitter','Inhering events'],
  },
  {
    title: 'Express.JS',
    subTopics: ['Configuring routes','Working with express'],
  },
  {
    title: 'Serving Static Resources',
    subTopics: [],
  },
  {
    title: 'Database connectivity',
    subTopics: [],
  },
  {
    title: 'Template Engines',
    subTopics: [],
  },
],
instructor: 'Hariharan, Rajasekar',
duration: '3 weeks',
schedule: 'Week Days and Week Ends',
certificate: 'Programming in Node js',
},

{
  id: 27,
title: 'Express Js',
category: 'IT Programs',
details: [
  {
    title: 'Introduction to Express Js',
    subTopics: [],
  },
  {
    title: 'Express.js Request',
    subTopics: [],
  },
  {
    title: 'Express.js Response',
    subTopics: [],
  },
  {
    title: 'Express.js Get',
    subTopics: [],
  },
  {
    title: 'Express.js Post',
    subTopics: [],
  },
  {
    title: 'Express.js Routing',
    subTopics: [],
  },
  {
    title: 'Express.js Cookies',
    subTopics: [],
  },
  {
    title: 'Express.js File Upload',
    subTopics: [],
  },
  {
    title: 'Express.js Middleware',
    subTopics: [],
  },
  {
    title: 'Express.js Scaffolding',
    subTopics: [],
  },
  {
    title: 'Express.js Template',
    subTopics: [],
  },
],
instructor: 'Hariharan, Rajasekar',
duration: '3 weeks',
schedule: 'Week Days and Week Ends',
certificate: 'Programming in Express Js',
},


{
  id: 30,
title: 'Ui/Ux',
category: 'Career/Long Term',
details: [
  {
    title: 'Ux Research',
    subTopics: [],
  },
  {
    title: 'Data Analysis in UX',
    subTopics: [],
  },
  {
    title: 'Design stratergy/ Direction in UX',
    subTopics: [],
  },
  {
    title: 'User Testing in UX',
    subTopics: [],
  },
  {
    title: 'UI Research',
    subTopics: [],
  },
  {
    title: 'Data Analysis in UI',
    subTopics: [],
  },
  {
    title: 'Design Strategy/Direction in UI',
    subTopics: [],
  },
  {
    title: 'User Testing in UI',
    subTopics: [],
  },
],
instructor: 'Rajasekar',
duration: '20 weeks',
schedule: 'Week Days and Week Ends',
certificate: 'Wed Designing in Ui/Ux',
},

{
  id: 31,
title: 'Digital Marketing',
category: 'Career/Long Term',
details: [
  {
    title: 'Basics Digital Marketing',
    subTopics: [],
  },
  {
    title: 'Analysis and Keyword Research',
    subTopics: [],
  },
  {
    title: 'Search Engine Optimization',
    subTopics: [],
  },
  {
    title: 'On Page optimization',
    subTopics: [],
  },
  {
    title: 'Off Page optimization',
    subTopics: [],
  },
  {
    title: 'Seo Updates and analysis',
    subTopics: [],
  },
  {
    title: 'Local Business & Google Mapping',
    subTopics: [],
  },
  {
    title: 'Google Adwords or Pay per click Marketing',
    subTopics: [],
  },
  {
    title: 'Social media Optimization',
    subTopics: [],
  },
  {
    title: 'Social Media marketing',
    subTopics: [],
  },
  {
    title: 'Google web Analytics',
    subTopics: [],
  },
  {
    title: 'Webmaster tools',
    subTopics: [],
  },
  {
    title: 'Creating a new simple website',
    subTopics: [],
  },
],
instructor: 'Rajasekar, Rajesh',
duration: '12 weeks',
schedule: 'Week Days and Week Ends',
certificate: 'Digital marketing',
},

{
  id: 32,
title: 'AWS',
category: 'Career/Long Term',
details: [
  {
    title: 'Creating AWS Accoun',
    subTopics: [],
  },
  {
    title: 'Understanding ec2 Instance',
    subTopics: [],
  },
  {
    title: 'Introduction to Amazon Simple Storage Service (S3)',
    subTopics: [],
  },
  {
    title: 'Create Bucket in S3',
    subTopics: [],
  },
  {
    title: 'Upload - Make Public - Rename - Delete Filein S3',
    subTopics: [],
  },
  {
    title: 'Delete and Restore Files in S3',
    subTopics: [],
  },
  {
    title: 'Testing',
    subTopics: ['JUNIT', 'JEST'],
  },
],
instructor: 'Hariharan, Rajasekar',
duration: '6 weeks',
schedule: 'Week Days and Week Ends',
certificate: 'AWS',
},

{
  id: 33,
title: 'SAP FICO',
category: 'Career/Long Term',
details: [
  {
    title: 'Introduction to SAP',
    subTopics: [],
  },
  {
    title: 'Navigation',
    subTopics: [],
  },
  {
    title: 'Basic Settings-Financial Accounting',
    subTopics: [],
  },
  {
    title: 'General Ledger',
    subTopics: [],
  },
  {
    title: 'Currencies',
    subTopics: [],
  },
  {
    title: 'Accounting Documents',
    subTopics: [],
  },
  {
    title: 'Reversals',
    subTopics: [],
  },
  {
    title: 'Bank Accounting',
    subTopics: [],
  },
  {
    title: 'Validations and Substitutions',
    subTopics: [],
  },
  {
    title: 'Accounts Payables',
    subTopics: [],
  },
  {
    title: 'Accounts Receivables',
    subTopics: [],
  },
  {
    title: 'Management of Account',
    subTopics: [],
  },
  {
    title: 'Taxes',
    subTopics: [],
  },
  {
    title: 'Asset Accounting',
    subTopics: [],
  },
  {
    title: 'Cash Journal',
    subTopics: [],
  },
  {
    title: 'Closing Operations',
    subTopics: [],
  },
  {
    title: 'Integration with Other Modules',
    subTopics: [],
  },
  {
    title: 'General Controlling.',
    subTopics: [],
  },
  {
    title: 'Cost Element Accounting',
    subTopics: [],
  },
  {
    title: 'Cost Centre Accounting',
    subTopics: [],
  },
  {
    title: 'Internal Orders',
    subTopics: [],
  },
  {
    title: 'Profit Centre Accounting',
    subTopics: [],
  },
  {
    title: 'Profitability Analysis.',
    subTopics: [],
  },
],
instructor: 'Rajesh',
duration: '12 weeks',
schedule: 'Week Days and Week Ends',
certificate: 'SAP',
},

{
  id: 35,
title: 'Angular',
category: 'IT Programs',
details: [
  {
    title: 'Introduction to Web & Angular',
    subTopics: ['Introduction to Web','Brushing up with web ( HTML,CSS, JavaScript )','Introduction to AngularJS','MVC Architecture','Conceptual Overview','Setting up the Environment','First Application','Understanding ng attributes'],
  },
  {
    title: 'AngularJS Building Blocks For Building SPA',
    subTopics: ['AngularJS Bootstrap Process','Template & live Data Binding','Model, View, Controller (MVC)','Depending Injection (AngularJS Services)'],
  },
  {
    title: 'Modules',
    subTopics: ['Why Module?','Module Loading and Dependencies','Recommended Setup of Application','Creation vs Retrieval'],
  },
  {
    title: 'Forms',
    subTopics: [],
  },
  {
    title: 'Filters',
    subTopics: [],
  },
  {
    title: 'Functions',
    subTopics: [],
  },
  {
    title: 'Expressions and Data Binding',
    subTopics: ['String Basics', 'String Functions'],
  },
  {
    title: 'Communication',
    subTopics: [],
  },
  {
    title: 'Services',
    subTopics: [],
  },
  {
    title: 'AngularJS Internal',
    subTopics: [],
  },
  {
    title: 'Directives',
    subTopics: [],
  },
  {
    title: 'Routing and Navigation',
    subTopics: [],
  },
],
instructor: 'Rajasekar',
duration: '6 weeks',
schedule: 'Week Days and Week Ends',
certificate: 'Web Designing in Angular',
},

{
  id: 36,
title: 'Advanced Excel',
category: 'Non-IT Programs',
details: [
  {
    title: 'Introduction to Advanced Excel',
    subTopics: [],
  },
  {
    title: 'Customizing excel',
    subTopics: [],
  },
  {
    title: 'Control Statements',
    subTopics: [],
  },
  {
    title: 'Functions',
    subTopics: [],
  },
  {
    title: 'Formatting and Proofing',
    subTopics: [],
  },
  {
    title: 'Math fucntions',
    subTopics: [],
  },
  {
    title: 'Proctecting Excel',
    subTopics: [],
  },
  {
    title: 'Text Functions',
    subTopics: [],
  },
  {
    title: 'Data and time Functions',
    subTopics: [],
  },
  {
    title: 'Advanced paste',
    subTopics: [],
  },
  {
    title: 'Sorting and Filtering',
    subTopics: [],
  },
  {
    title: 'What if Analysis',
    subTopics: [],
  },
  {
    title: 'Logical functions',
    subTopics: [],
  },
  {
    title: 'Data validation',
    subTopics: [],
  },
  {
    title: 'Lookup function',
    subTopics: [],
  },
  {
    title: 'Pivot table',
    subTopics: [],
  },
  {
    title: 'Charts and Slicers',
    subTopics: [],
  },
  {
    title: 'Macros',
    subTopics: [],
  },
  {
    title: 'Mail fucntion',
    subTopics: [],
  },
],
instructor: 'Rajesh',
duration: '4 weeks',
schedule: 'Week Days and Week Ends',
certificate: 'Advanced Excel',
},

{
  id: 37,
title: 'Ms-Office',
category: 'Non-IT Programs',
details: [
  {
    title: 'Ms-Word',
    subTopics: [],
  },
  {
    title: 'Ms-Excel',
    subTopics: [],
  },
  {
    title: 'Ms- Power point',
    subTopics: [],
  },
],
instructor: 'Rajesh',
duration: '4 weeks',
schedule: 'Week Days and Week Ends',
certificate: 'Ms-office',
},
{
  id: 38,
title: 'Tally',
category: 'Non-IT Programs',
details: [
  {
    title: 'Groups',
    subTopics: [],
  },
  {
    title: 'Ledger',
    subTopics: [],
  },
  {
    title: 'Stock group',
    subTopics: [],
  },
  {
    title: 'Stock category',
    subTopics: [],
  },
  {
    title: 'Stock item',
    subTopics: [],
  },
  {
    title: 'Vouchers',
    subTopics: [],
  },
  {
    title: 'Accounting Features',
    subTopics: [],
  },
  {
    title: 'Bill wise entries',
    subTopics: [],
  },
  {
    title: 'Multiple currencies',
    subTopics: [],
  },
  {
    title: 'Interest calculation',
    subTopics: [],
  },
  {
    title: 'Cost centre and cost category',
    subTopics: [],
  },
  {
    title: 'Budget control',
    subTopics: [],
  },
  {
    title: 'Scenario manager',
    subTopics: [],
  },
  {
    title: 'Bank reconciliation',
    subTopics: [],
  },
  {
    title: 'Voucher class',
    subTopics: [],
  },
  {
    title: 'Inventory features',
    subTopics: [],
  },
  {
    title: 'Purchase order',
    subTopics: [],
  },
  {
    title: 'Sales order',
    subTopics: [],
  },
  {
    title: 'Receipt and delivery note',
    subTopics: [],
  },
  {
    title: 'Additional cost charges',
    subTopics: [],
  },
  {
    title: 'Actual vs build',
    subTopics: [],
  },
  {
    title: 'Multiple price level',
    subTopics: [],
  },
  {
    title: 'Regular Expression',
    subTopics: [],
  },
  {
    title: 'Zero valid entry',
    subTopics: [],
  },
  {
    title: 'Bill of material',
    subTopics: [],
  },
  {
    title: 'Gst',
    subTopics: [],
  },
],
instructor: 'Rajesh',
duration: '6 weeks',
schedule: 'Week Days and Week Ends',
certificate: 'Accounting in Tally Prime with GST',
},



    // Add more courses here
     ];

  const [courses, setCourses] = useState(initialCourses);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const [expandedCourseId, setExpandedCourseId] = useState(null);

  const handleCourseClick = (id) => {
    setExpandedCourseId((prevId) => (prevId === id ? null : id));
  };

  return (
    <div className="container" >
      <div className="m-2">
        <h2 style={{ textAlign: 'center' }} className="text-white">Available Courses</h2>
        <p style={{ textAlign: 'center', marginBottom: '20px', fontSize:'0.5em',color:'white' }}>*Note: Course Content may vary according to the update of software. <br /> (These Syllabus is for References).</p>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
          <div style={{ display: 'flex' }}>
            {['All Courses', ...Array.from(new Set(courses.map((course) => course.category)))].map((category) => (
              <button
                key={category}
                type="button"
                style={{
                  border: '1px solid #007bff',
                  backgroundColor: selectedCategory === category ? '#007bff' : '#fff',
                  color: selectedCategory === category ? '#fff' : '#007bff',
                  padding: '8px 16px',
                  marginRight: '5px',
                  borderRadius: '5px',
                  cursor: 'pointer',
                }}
                onClick={() => handleCategoryClick(category)}
              >
                {category}
              </button>
            ))}
          </div>
        </div>

        <div>
        {courses.map((course) => (
  (selectedCategory === null || course.category === selectedCategory) && (
    <div
      key={course.id}
      style={{
        border: '1px solid #ccc',
        borderRadius: '5px',
        marginBottom: '10px',
        backgroundColor: '#fff',
      }}
    >
      <div style={{ padding: '10px', cursor: 'pointer' }} onClick={() => handleCourseClick(course.id)}>
        <h5>{course.title}</h5>
        {expandedCourseId === course.id && (
          <div>
            <ul>
              {course.details.map((detail, index) => (
                <li key={index}>
                  {detail.title}
                  {detail.subTopics.length > 0 && (
                    <ul>
                      {detail.subTopics.map((subTopic, subIndex) => (
                        <li key={subIndex}>{subTopic}</li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
            <p><strong>Instructor:</strong> {course.instructor}</p>
            <p><strong>Duration:</strong> {course.duration}</p>
            <p><strong>Next Batch:</strong> {course.schedule}</p>
            <p><strong>Certification Name:</strong> {course.certificate}</p>
            <CourseDownloadButton title={course.title} details={course.details.join('\n')} />
          </div>
        )}
      </div>
    </div>
  )
))}
        </div>
      </div>
    </div>
  );
};

export default CourseList;