import axios from "axios";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { url } from "../link";
const Updatemonthlyexpense = () => {
  var rx = 0;
  const { state } = useLocation();
  const [id, setid] = useState(state[rx++]);
  const [name, setname] = useState(state[rx++]);
  const [amount, setamount] = useState(state[rx++]);
  const [paymentdate, setpaymentdate] = useState(state[rx++]);
  const [status, setstatus] = useState(state[rx++]);
  const nav = useNavigate();
  const submitdata = () => {
    const value = {
      id: id,
      name: name,
      amount: amount,
      paymentdate: paymentdate,
      status: status,
    };
    axios
      .post(url + "/edusphere/updatemonthlyexpense", value)
      .then((response) => {
        nav("/viewmonthlyexpense");
      });
  };
  return (
    <div>
      <h1>Add monthlyexpense</h1>
      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setid(e.target.value)}
          value={id}
          placeholder="Enter id"
        />
        <label htmlFor="id">id</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setname(e.target.value)}
          value={name}
          placeholder="Enter name"
        />
        <label htmlFor="name">name</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setamount(e.target.value)}
          value={amount}
          placeholder="Enter amount"
        />
        <label htmlFor="amount">amount</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setpaymentdate(e.target.value)}
          value={paymentdate}
          placeholder="Enter paymentdate"
        />
        <label htmlFor="paymentdate">paymentdate</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setstatus(e.target.value)}
          value={status}
          placeholder="Enter status"
        />
        <label htmlFor="status">status</label>
      </div>

      <input
        type="submit"
        className="btn btn-primary"
        onClick={submitdata}
        style={{ width: "100%" }}
      />
    </div>
  );
};
export default Updatemonthlyexpense;
