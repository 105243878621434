import Footer from "./Footer";
import Header from "./Header";
const C = () => {
  return (
    <div className="container-fuild">
      <Header />
      <div className="container">
        <div className="row border border-dark m-1">
          <div className="row text-center m-3">
            <h1>
              <b>
                <u> C Progarmming</u>
              </b>
            </h1>
          </div>
          <div className="row m-3">
            <h4>
              C is one of the most efficient programming languages that help you
              understand how the software and hardware interact with each other.
              That's why we believe every programmer should learn C, as it
              allows you to work directly with computer memory and helps
              understand programming from a deeper level. Master C programming
              in the most interactive way possible, solving challenges and
              building projects.
            </h4>
          </div>
          <div className="row m-5 h4">
            <fieldset>
              <legend style={{ width: "40%" }}>Topics:</legend>
              <ul>
                <li>Introduction to C</li>
                <li>Basic Syntax</li>
                <li>Conditional Statement</li>
                <li>Loop</li>
                <li>Patterns</li>
                <li>Array</li>
                <li>Multi Dimension Array</li>
                <li>String</li>
                <li>Pointer</li>
                <li>Functions</li>
                <li>Structures</li>
                <li>Files</li>
              </ul>
            </fieldset>
          </div>
          <div className="row m-5">
            <h2>
              <b> Certificate:</b>
            </h2>
            <h2>
              <p>Programming in C</p>
            </h2>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default C;
