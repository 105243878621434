import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { url } from "../link";
import Navbar from "../components/Navbar";
const Viewcoursecat = () => {
  const nav = useNavigate();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");

  const [s, sets] = useState([]);
  const [pending, setPending] = useState(true);

  const column = [
    {
      name: "Cid",
      selector: (row) => row.Cid,
      sortable: true,
    },
    {
      name: "Cname",
      selector: (row) => row.Cname,
      sortable: true,
    },
    {
      name: "Price",
      selector: (row) => row.Price,
      sortable: true,
    },
    {
      name: "Duration",
      selector: (row) => row.Duration,
      sortable: true,
    },
    {
      name: "Coursetitle",
      selector: (row) => row.Coursetitle,
      sortable: true,
    },

    {
      name: "Delete",
      selector: (row) => row.Delete,
      sortable: true,
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      axios.post(url + "/edusphere/viewcoursecat").then((response) => {
        var r = response.data.map((k) => {
          return {
            Cid: k[0],
            Cname: k[1],
            Price: k[2],
            Duration: k[3],
            Coursetitle: k[4],

            Delete: (
              <button className="btn btn-primary" onClick={() => deletec(k[0])}>
                Delete
              </button>
            ),
          };
        });

        setData(r);
        setFilteredData(r);

        setPending(false);
      });
      return () => clearTimeout(timeout);
    }, 1000);
  }, []);

  const deletec = (e) => {
    axios
      .post(url + "/edusphere/deletebill", {
        bid: e,
      })
      .then((response) => {});
  };

  //  Internally, customStyles will deep merges your customStyles with the default styling.
  const customStyles = {
    rows: {
      style: {
        minHeight: "72px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontSize: "1.2em",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        fontSize: "1em",
      },
    },
  };

  return (
    <div>
      <Navbar />
      <div className="main">
        <h3>View Course</h3>

        <div className="dataTableContainer">
          <input
            type="search"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);

              const filtered = data.filter((item) =>
                Object.values(item).some((value) => {
                  if (value !== null && value !== undefined) {
                    return value
                      .toString()
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase());
                  }
                  return false; // Handle null or undefined value, e.g., consider it not matching.
                })
              );
              setFilteredData(filtered);
            }}
            placeholder="Search..."
          />
          <DataTable
            columns={column}
            data={filteredData}
            pagination
            fixedHeader
            fixedHeaderScrollHeight="70vh"
            customStyles={customStyles}
            progressPending={pending}
          />
        </div>
      </div>
    </div>
  );
};
export default Viewcoursecat;

// import axios from "axios";
// import { useState } from "react";
// import { useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import Nav from "./Nav";

// import $ from "jquery";
// const Viewcoursecat = () => {
//   const nav = useNavigate();
//   const [data, setData] = useState([]);
//   const [value, setvalue] = useState([]);
//   useEffect(() => {
//     axios
//       .post(url+"/edusphere/viewcoursecat")
//       .then((response) => {
//         setData(response.data);
//         setvalue(response.data);
//         $(document).ready(function () {
//           var eventFired = function (type) {
//             var n = $("#demo_info")[0];

//             n.scrollTop = n.scrollHeight;
//           };

//           $("#example")
//             .on("order.dt", function () {
//               eventFired("Order");
//             })
//             .on("search.dt", function () {
//               eventFired("Search");
//             })
//             .on("page.dt", function () {
//               eventFired("Page");
//             })
//             .DataTable();
//         });
//       });
//   }, []);
//   const viewcoursecat = (e) => {
//     nav("/viewcoursecat", { state: e });
//   };
//   const deletec = (e) => {
//     axios
//       .post(url+"/edusphere/deletecoursecat", {
//         id: e,
//       })
//       .then((response) => {
//         axios
//           .post(url+"/edusphere/viewcoursecat")
//           .then((response) => {
//             setData(response.data);
//             setvalue(response.data);
//             $(document).ready(function () {
//               var eventFired = function (type) {
//                 var n = $("#demo_info")[0];

//                 n.scrollTop = n.scrollHeight;
//               };

//               $("#example")
//                 .on("order.dt", function () {
//                   eventFired("Order");
//                 })
//                 .on("search.dt", function () {
//                   eventFired("Search");
//                 })
//                 .on("page.dt", function () {
//                   eventFired("Page");
//                 })
//                 .DataTable();
//             });
//           });
//       });
//   };

//   return (
//     <div>
//       <Nav />
//       <div className="main">
//         <div className="page-container">
//           <h3>All Registration</h3>
//           <div className="table-responsive p-0 pb-2">
//             <div
//               id="demo_info"
//               className="box"
//               style={{ display: "none" }}
//             ></div>
//             <table id="example" className="display" style={{ width: "100%" }}>
//               <thead>
//                 <tr>
//                   <th>id</th>
//                   <th>name</th>
//                   <th>price</th>
//                   <th>duration</th>
//                   <th>coursetitle</th>
//                   <th></th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {data.map((d) => {
//                   return (
//                     <tr key={d[0]} className="odd">
//                       <td>
//                         <button
//                           className="btn btn-primary"
//                           onClick={() => viewcoursecat(d)}
//                         >
//                           {d[0]}
//                         </button>
//                       </td>
//                       <td>{d[1]}</td>
//                       <td>{d[2]}</td>
//                       <td>{d[3]}</td>
//                       <td>{d[4]}</td>
//                       <td>
//                         <button
//                           className="btn btn-primary"
//                           onClick={() => deletec(d[0])}
//                         >
//                           delete
//                         </button>
//                       </td>
//                     </tr>
//                   );
//                 })}
//               </tbody>
//             </table>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };
// export default Viewcoursecat;
