import Footer from "./Footer";
import Header from "./Header";
const Django = () => {
  return (
    <div className="container-fuild">
      <Header />
      <div className="container">
        <div className="row border border-dark m-1">
          <div className="row text-center m-3">
            <h1>
              <b>
                <u> Django</u>
              </b>
            </h1>
          </div>

          <div className="row m-3">
            <h4>
              Intellipaat Django course masters you in Django REST framework,
              Django Models, Django AJAX, Django jQuery, etc. In this best
              Python Django course, you will learn web framework by doing
              real-time industry projects with top Industry mentors.
            </h4>
          </div>

          <div className="row m-5 h4">
            <fieldset>
              <legend style={{ width: "40%" }}>Topics:</legend>
              <ul>
                <li>Introduction to Django</li>
                <li>Django Features</li>
                <li>Django Pros and Cons</li>
                <li>Django MTV Architecture</li>
                <li>Django Models</li>
                <li>Django Views</li>
                <li>Django URLs and URL Conf</li>
                <li>Django Database</li>
                <li>Django Cookies Handling</li>
                <li>Django Form Handling and Validation</li>
                <li>Django File Upload</li>
                <li>Django Bootstrap</li>
                <li>Django Request and Response</li>
                <li>Django Migrations</li>
                <li>AJAX in Django</li>
                <li>Django Web Hosting</li>
                <li>Django CMS</li>
                <li>Django REST Framework</li>
                <li>Django Logging</li>
              </ul>
            </fieldset>
          </div>

          <div className="row m-5">
            <h2>
              <b> Certificate:</b>
            </h2>
            <h2>
              <p>Django</p>
            </h2>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Django;
