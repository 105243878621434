import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { url } from "../link";
import Navbar from "../components/Navbar";

const Viewstaffattendance = () => {
  const nav = useNavigate();
  const [data, setData] = useState([]);
  const [value, setValue] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    axios.post(url + "/edusphere/viewstaffattendance").then((response) => {
      setData(response.data);
      setValue(response.data);
    });
  }, []);

  const deletec = (e) => {
    axios
      .post(url + "/edusphere/deletestaffattendance", { id: e })
      .then((response) => {
        axios.post(url + "/edusphere/viewstaffattendance").then((response) => {
          setData(response.data);
          setValue(response.data);
        });
      });
  };

  const searchdata = (e) => {
    const r = [];
    for (var k of value) {
      var v = 0;
      for (var n of k) {
        n = "" + n;
        if (n.toLowerCase().indexOf(e.toLowerCase()) !== -1) {
          v = 1;
          break;
        }
      }
      if (v === 1) {
        r.push(k);
      }
    }
    setData(r);
  };

  const filterByDate = () => {
    const filteredData = value.filter((item) => {
      const morningStartTime = new Date(item[2]);
      const morningEndTime = new Date(item[3]);
      const eveningStartTime = new Date(item[4]);
      const eveningEndTime = new Date(item[5]);

      return (
        (!startDate ||
          morningStartTime >= new Date(startDate) ||
          morningEndTime >= new Date(startDate) ||
          eveningStartTime >= new Date(startDate) ||
          eveningEndTime >= new Date(startDate)) &&
        (!endDate ||
          morningStartTime <= new Date(endDate) ||
          morningEndTime <= new Date(endDate) ||
          eveningStartTime <= new Date(endDate) ||
          eveningEndTime <= new Date(endDate))
      );
    });
    setData(filteredData);
  };

  return (
    <div>
      <Navbar />
      <h3>Staff Attendance</h3>
      <input
        type="search"
        onChange={(e) => searchdata(e.target.value)}
        className="form-select"
        placeholder="Search"
      />
      <div className="row">
        <div className="col-4">
          <label>Start Date:</label>
          <input
            type="date"
            onChange={(e) => setStartDate(e.target.value)}
            value={startDate}
            className="form-control"
          />
        </div>
        <div className="col-4">
          <label>End Date:</label>
          <input
            type="date"
            onChange={(e) => setEndDate(e.target.value)}
            value={endDate}
            className="form-control"
          />
        </div>
        <div className="col-4">
          <button onClick={filterByDate} className="btn btn-danger">
            Filter
          </button>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-bordered" id="table_id">
          <thead>
            <tr>
              <th>Said</th>
              <th>Staffname</th>
              <th>Morningstarttime</th>
              <th>Morningendtime</th>
              <th>Eveningstarttime</th>
              <th>Eveningendtime</th>
              <th>Leave</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {data.map((d) => {
              return (
                <tr key={d[0]}>
                  <td>{d[0]}</td>
                  <td>{d[1]}</td>
                  <td>{d[2]}</td>
                  <td>{d[3]}</td>
                  <td>{d[4]}</td>
                  <td>{d[5]}</td>
                  <td>{d[6]}</td>
                  <td>{d[7]}</td>
                  <td>
                    <button
                      className="btn btn-primary"
                      onClick={() => deletec(d[0])}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Viewstaffattendance;
