import Footer from "./Footer";
import Header from "./Header";
const Jsp = () => {
  return (
    <div className="container-fuild">
      <Header />
      <div className="container">
        <div className="row border border-dark m-1">
          <div className="row text-center m-3">
            <h1>
              <b>
                <u> JSP and Servlet</u>
              </b>
            </h1>
          </div>

          <div className="row m-3">
            <h4>
              JSP technology is used to create web application just like Servlet
              technology. It can be thought of as an extension to Servlet
              because it provides more functionality than servlet such as
              expression language, JSTL, etc.
            </h4>
          </div>

          <div className="row m-5 h4">
            <fieldset>
              <legend style={{ width: "40%" }}>Topics:</legend>
              <ul>
                <li>Introduction to JSP</li>
                <li>Actions</li>
                <li>Implicit object</li>
                <li>Conditional Statement</li>
                <li>Client Request</li>
                <li>Server Response</li>
                <li>HTTP status codes</li>
                <li>Form processing</li>
                <li>Filters</li>
                <li>Files</li>
                <li>Mail</li>
                <li>Database Access</li>
                <li>XML data</li>
                <li>Java Beans</li>
                <li>Custom tags</li>
                <li>Expression Language</li>
                <li>Expression Handling</li>
                <li>Debugging</li>
                <li>Security</li>
                <li>Internationalization</li>
              </ul>
              <legend style={{ width: "40%" }} id="ser">
                Servlets:
              </legend>
              <ul>
                <li>Introduction to Servlets</li>
                <li>Client Request to Servlet</li>
                <li>Server response</li>
                <li>Request dispatcher Interface</li>
                <li>Filter API</li>
                <li>Config and Servlet Context</li>
                <li>Events and listeners</li>
                <li>Excepting Handling</li>
                <li>Cookies</li>
                <li>Session Tracking</li>
                <li>IO Streams</li>
                <li>File upload</li>
                <li>Mail</li>
                <li>JDBC</li>
              </ul>
            </fieldset>
          </div>

          <div className="row m-5">
            <h2>
              <b> Certificate:</b>
            </h2>
            <h2>
              <p>Programming in JSP and Servlets</p>
            </h2>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Jsp;
