import axios from "axios";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Nav from "../process/Nav";
import { url } from "../link";
const Updatesubject = () => {
  var rx = 0;
  const { state } = useLocation();
  const nav = useNavigate();
  const [sid, setsid] = useState(state[rx++]);
  const [subject_name, setsubject_name] = useState(state[rx++]);
  const submitdata = () => {
    const value = { sid: sid, subject_name: subject_name };
    axios
      .post("192.168.1.110:5000:5000/edusphere/updatesubject", value)
      .then(() => {
        nav("/addsubject");
      });
    setsid("");
    setsubject_name("");
  };
  return (
    <div>
      <Nav />
      <nav
        className="navbar navbar-expand-sm bg-success navbar-dark"
        style={{ marginTop: "5%" }}
      >
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink className="nav-link" to="/viewsubject">
                Subject
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/viewtopics">
                Topics
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/viewsubtopics">
                Subtopics
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/viewcontent">
                Content
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
      <h1>Add subject</h1>
      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setsid(e.target.value)}
          value={sid}
          placeholder="Enter sid"
        />
        <label htmlFor="sid">sid</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setsubject_name(e.target.value)}
          value={subject_name}
          placeholder="Enter subject_name"
        />
        <label htmlFor="subject_name">subject_name</label>
      </div>

      <input
        type="submit"
        className="btn btn-primary"
        onClick={submitdata}
        style={{ width: "100%" }}
      />
    </div>
  );
};
export default Updatesubject;
