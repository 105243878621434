import axios from "axios";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Nav from "../process/Nav";
import { url } from "../link";
const Updatesubtopics = () => {
  var rx = 0;
  const { state } = useLocation();
  const [stid, setstid] = useState(state[rx++]);
  const [subtopic_name, setsubtopic_name] = useState(state[rx++]);
  const [tid, settid] = useState(state[rx++]);
  const nav = useNavigate();
  const submitdata = () => {
    const value = { stid: stid, subtopic_name: subtopic_name, tid: tid };
    axios.post(url + "/edusphere/updatesubtopics", value).then(() => {
      nav("/addsubject");
    });
    setstid("");
    setsubtopic_name("");
    settid("");
  };
  return (
    <div>
      <Nav />
      <nav
        className="navbar navbar-expand-sm bg-success navbar-dark"
        style={{ marginTop: "5%" }}
      >
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink className="nav-link" to="/viewsubject">
                Subject
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/viewtopics">
                Topics
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/viewsubtopics">
                Subtopics
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/viewcontent">
                Content
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
      <h1>Add subtopics</h1>
      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setstid(e.target.value)}
          value={stid}
          placeholder="Enter stid"
        />
        <label htmlFor="stid">stid</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setsubtopic_name(e.target.value)}
          value={subtopic_name}
          placeholder="Enter subtopic_name"
        />
        <label htmlFor="subtopic_name">subtopic_name</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => settid(e.target.value)}
          value={tid}
          placeholder="Enter tid"
        />
        <label htmlFor="tid">tid</label>
      </div>

      <input
        type="submit"
        className="btn btn-primary"
        onClick={submitdata}
        style={{ width: "100%" }}
      />
    </div>
  );
};
export default Updatesubtopics;
