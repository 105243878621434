import Nav from "../process/Nav";
import { url } from "../link";
import React, { useState } from "react";
import axios from "axios";
const Bulkquestion = () => {
  const [file, setFile] = useState();

  function handleChange(event) {
    setFile(event.target.files[0]);
  }

  function handleSubmit(event) {
    event.preventDefault();

    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", file.name);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    axios
      .post(url + "/edusphere/uploadFile", formData, config)
      .then((response) => {
        alert("success");
      });
  }
  return (
    <div>
      <Nav />
      <div className="main">
        <div
          className="App"
          style={{
            border: "1px solid black",
            width: "75%",
          }}
        >
          <form onSubmit={handleSubmit} style={{ marginTop: "10%" }}>
            <label className="form-label" htmlFor="customFile">
              File Upload for Question
            </label>
            <input
              type="file"
              className="form-control"
              id="customFile"
              onChange={handleChange}
            />
            <br />
            <button
              type="submit"
              className="btn btn-primary"
              style={{ width: "50%", marginLeft: "20%" }}
            >
              Upload
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Bulkquestion;
