import axios from "axios";
import { useState } from "react";
import { url } from "../link";
import { useLocation, useNavigate } from "react-router-dom";
const Updatetodayattendance = () => {
  var rx = 0;
  const { state } = useLocation();
  const nav = useNavigate();
  const [taid, settaid] = useState(state[rx++]);
  const [aid, setaid] = useState(state[rx++]);
  const [status, setstatus] = useState(state[rx++]);
  const [remark, setremark] = useState(state[rx++]);
  const submitdata = () => {
    const value = { taid: taid, aid: aid, status: status, remark: remark };
    axios
      .post(url + "/edusphere/updatetodayattendance", value)
      .then((response) => {
        nav("/viewtodayattendance");
      });
  };
  return (
    <div>
      <h1>Add todayattendance</h1>
      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => settaid(e.target.value)}
          value={taid}
          placeholder="Enter taid"
        />
        <label htmlFor="taid">taid</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setaid(e.target.value)}
          value={aid}
          placeholder="Enter aid"
        />
        <label htmlFor="aid">aid</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setstatus(e.target.value)}
          value={status}
          placeholder="Enter status"
        />
        <label htmlFor="status">status</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setremark(e.target.value)}
          value={remark}
          placeholder="Enter remark"
        />
        <label htmlFor="remark">remark</label>
      </div>

      <input
        type="submit"
        className="btn btn-primary"
        onClick={submitdata}
        style={{ width: "100%" }}
      />
    </div>
  );
};
export default Updatetodayattendance;
