import axios from "axios";
import { useState } from "react";
import Nav from "../process/Nav";
import { url } from "../link";
const Addproject = () => {
  const [name, setname] = useState("");
  const [phone_number, setphone_number] = useState("");
  const [college, setcollege] = useState("");
  const [project_title, setproject_title] = useState("");
  const [total_amount, settotal_amount] = useState("");
  const [total_paid, settotal_paid] = useState("");
  const [balance, setbalance] = useState("");
  const [vendor, setvendor] = useState("");
  const [total, settotal] = useState("");
  const [paid, setpaid] = useState("");
  const [project_status, setproject_status] = useState("");
  const [report, setreport] = useState("");
  const submitdata = () => {
    const value = {
      name: name,
      phone_number: phone_number,
      college: college,
      project_title: project_title,
      total_amount: total_amount,
      total_paid: total_paid,
      balance: balance,
      vendor: vendor,
      vendortotal: total,
      paid: paid,
      project_status: project_status,
      report: report,
    };
    axios.post(url + "/edusphere/insertproject", value);
    alert("success");
    setname("");
    setphone_number("");
    setcollege("");
    setproject_title("");
    settotal_amount("");
    settotal_paid("");
    setbalance("");
    setvendor("");
    settotal("");
    setpaid("");
    setproject_status("");
    setreport("");
  };
  return (
    <div>
      <Nav />
      <div className="main">
        <div className="page-container">
          <h1>Add project</h1>
          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => setname(e.target.value)}
              value={name}
              placeholder="Enter name"
            />
            <label htmlFor="name">name</label>
          </div>

          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => setphone_number(e.target.value)}
              value={phone_number}
              placeholder="Enter phone_number"
            />
            <label htmlFor="phone_number">phone_number</label>
          </div>

          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => setcollege(e.target.value)}
              value={college}
              placeholder="Enter college"
            />
            <label htmlFor="college">college</label>
          </div>

          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => setproject_title(e.target.value)}
              value={project_title}
              placeholder="Enter project_title"
            />
            <label htmlFor="project_title">project_title</label>
          </div>

          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => settotal_amount(e.target.value)}
              value={total_amount}
              placeholder="Enter total_amount"
            />
            <label htmlFor="total_amount">total_amount</label>
          </div>

          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => settotal_paid(e.target.value)}
              value={total_paid}
              placeholder="Enter total_paid"
            />
            <label htmlFor="total_paid">total_paid</label>
          </div>

          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => setbalance(e.target.value)}
              value={balance}
              placeholder="Enter balance"
            />
            <label htmlFor="balance">balance</label>
          </div>

          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => setvendor(e.target.value)}
              value={vendor}
              placeholder="Enter vendor"
            />
            <label htmlFor="vendor">vendor</label>
          </div>

          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => settotal(e.target.value)}
              value={total}
              placeholder="Enter total"
            />
            <label htmlFor="total">total</label>
          </div>

          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => setpaid(e.target.value)}
              value={paid}
              placeholder="Enter paid"
            />
            <label htmlFor="paid">paid</label>
          </div>

          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => setproject_status(e.target.value)}
              value={project_status}
              placeholder="Enter project_status"
            />
            <label htmlFor="project_status">project_status</label>
          </div>

          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => setreport(e.target.value)}
              value={report}
              placeholder="Enter report"
            />
            <label htmlFor="report">report</label>
          </div>

          <input
            type="submit"
            className="btn btn-primary"
            onClick={submitdata}
            style={{ width: "100%" }}
          />
        </div>
      </div>
    </div>
  );
};
export default Addproject;
