import React, { useState } from 'react';
// import { BiEnvelope, BiInstagram, BiFacebook } from 'react-icons/bi';

const ContactPage = () => {
  const companyInfo = {
    instagram: 'https://www.instagram.com/eduspheresolutions/',
    facebook: 'https://www.facebook.com/profile.php?id=100077324284875/',
  };

  const teamMembers = [
    {
      name: 'R. Hariharan',
      designation: 'Managing Director',
      phoneNumber: '+91 9952363956',
      email: 'hariharan@eduspheresolutions.com',
      // image: 'https://via.placeholder.com/150',
    },
    {
      name: 'B. Rajasekar',
      designation: 'Chief Executive Officer',
      phoneNumber: '+91 8807796595',
      email: 'rajasekar.rk@eduspheresolutions.com',
      // image: 'https://via.placeholder.com/150',
    },
    {
      name: 'R. Rajesh',
      designation: 'Executive Officer',
      phoneNumber: '+91 7092043942',
      email: 'rajesh@eduspheresolutions.com',
      // image: 'https://via.placeholder.com/150',
    },
    // Add more team members here
  ];

  const [selectedMember, setSelectedMember] = useState(null);

  const handleViewDetails = (index) => {
    setSelectedMember(index === selectedMember ? null : index);
  };
  return (
    <div style={{ background: '#f9f9f9', padding: '20px', backgroundImage: 'url("https://i.pinimg.com/originals/07/0e/b3/070eb33b61e7f60a9481aa4c3a27051c.jpg")', backgroundSize: 'contain' }}>
      <h1 className="text-center mb-4 text-white">Contact Us</h1>

      <div className="row justify-content-center" style={{ opacity: '0.8' }}>
        <div className="col-md-6">
          <div className="card p-4 mb-4">
            <h4 className="mb-3">Follow us on social media:</h4>
            <p className="mb-2">
              <a href={companyInfo.instagram} target="_blank" rel="noopener noreferrer">
                Instagram
              </a>
            </p>
            <p className="mb-2">
              <a href={companyInfo.facebook} target="_blank" rel="noopener noreferrer">
                Facebook
              </a>
            </p>
          </div>
        </div>
      </div>

      <div className="row justify-content-center" style={{ opacity: '0.8' }}>
        {teamMembers.map((member, index) => (
          <div className="col-md-4 col-sm-6" key={index}>
            <div className="card p-4 mb-4" style={{ textAlign: 'center' }}>
              {/* <img src={member.image} alt={member.name} className="img-fluid rounded-circle mb-3" /> */}
              <h4 className="mb-3">{member.name}</h4>
              <p className="mb-3">{member.designation}</p>
              {selectedMember === index ? (
                <div style={{ textAlign: 'center' }}>
                  <p className="mb-2">
                    {member.email}
                  </p>
                  <p className="mb-2">
                    {member.phoneNumber}
                  </p>
                  <button className="btn btn-primary" onClick={() => handleViewDetails(index)}>
                    Hide Details
                  </button>
                </div>
              ) : (
                <button className="btn btn-primary" onClick={() => handleViewDetails(index)}>
                  View Details
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContactPage;
