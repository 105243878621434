import Footer from "./Footer";
import Header from "./Header";
const Advexcel = () => {
  return (
    <div className="container-fuild">
      <Header />
      <div className="container">
        <div className="row border border-dark m-1">
          <div className="row text-center m-3">
            <h1>
              <b>
                <u>Advanced Excel</u>
              </b>
            </h1>
          </div>

          <div className="row m-3">
            <h4>
              how to create and format Pivot Tables, automate complex tasks with
              Macros, learn few functions and formulas and much more. You can
              learn what you need as quickly as possible, with as little hassle
              as possible.
            </h4>
            <h3>Basic of Excel is Required</h3>
          </div>

          <div className="row m-5 h4">
            <fieldset>
              <legend style={{ width: "40%" }}>Topics:</legend>
              <ul>
                <li>Introduction to Advanced Excel</li>
                <li>Customizing excel</li>
                <li>Functions</li>
                <li>Formatting and Proofing</li>
                <li>Math fucntions</li>
                <li>Proctecting Excel</li>
                <li>Text Functions</li>
                <li>Data and time Functions</li>
                <li>Advanced paste</li>
                <li>Sorting and Filtering</li>
                <li>What if Analysis</li>
                <li>Logical functions</li>
                <li>Data validation</li>
                <li>Lookup function</li>
                <li>Pivot table</li>
                <li>Charts and Slicers</li>
                <li>Macros</li>
                <li>Mail fucntion</li>
              </ul>
            </fieldset>
          </div>

          <div className="row m-5">
            <h2>
              <b> Certificate:</b>
            </h2>
            <h2>
              <p>Advanced Excel</p>
            </h2>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Advexcel;
