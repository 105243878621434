import axios from "axios";
import { useEffect, useState } from "react";
import Nav from "./Nav";
import { useNavigate } from "react-router-dom";
import { url } from "../link";
const Coursechange = () => {
  const [data, setdata] = useState([]);
  const [course, setcourse] = useState([]);
  const [eid, seteid] = useState("");
  const [fee, setfee] = useState("");
  const [coursed, setcoursed] = useState();
  const nav = useNavigate();
  useEffect(() => {
    axios.post(url + "/edusphere/searchdata").then((response) => {
      setcourse(response.data["course"]);
    });
    axios.post(url + "/edusphere/searchcourse").then((response) => {
      setdata(response.data);
    });
  }, []);
  var x = 0;
  return (
    <>
      <Nav />
      <div className="form-floating mb-3 mt-3">
        <input
          list="browsers"
          name="browser"
          id="browser"
          className="form-control"
          value={eid}
          onChange={(e) => seteid(e.target.value)}
        />
        <datalist id="browsers">
          {data.map((d) => {
            return <option value={d} key={x++} />;
          })}
        </datalist>
        <label htmlFor="eid">rid</label>
      </div>
      {eid && (
        <>
          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              value={eid.split("-")[0]}
              placeholder="Enter paid"
            />
            <label htmlFor="eid">Rid</label>
          </div>
          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              value={eid.split("-")[1]}
              placeholder="Enter paid"
            />
            <label htmlFor="eid">Name</label>
          </div>
          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              value={eid.split("-")[2]}
              placeholder="Enter paid"
            />
            <label htmlFor="eid">Mobile</label>
          </div>
          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              value={eid.split("-")[3]}
              placeholder="Enter paid"
            />
            <label htmlFor="eid">Fees Joined</label>
          </div>
          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              value={eid.split("-")[4]}
              placeholder="Enter paid"
            />
            <label htmlFor="eid">Balance</label>
          </div>
          <div className="form-floating mb-3 mt-3">
            <input
              type="text"
              className="form-control"
              value={eid.split("-")[3] - eid.split("-")[4]}
              placeholder="Enter paid"
            />
            <label htmlFor="eid">Paid</label>
          </div>
          <div className="form-floating mb-3 mt-3">
            <input
              list="courses"
              name="course"
              id="browser"
              className="form-control"
              value={coursed}
              onChange={(e) => {
                setcoursed(e.target.value);
                setfee(e.target.value.split("-")[2]);
              }}
            />
            <datalist id="courses">
              {course.map((d) => {
                return <option value={d} key={x++} />;
              })}
            </datalist>

            <label htmlFor="course">course</label>
          </div>
          {coursed && (
            <>
              <div className="form-floating mb-3 mt-3">
                <input
                  type="text"
                  className="form-control"
                  value={fee}
                  placeholder="Enter paid"
                  onChange={(e) => setfee(e.target.value)}
                />
                <label htmlFor="eid">Paid</label>
              </div>
              <div className="form-floating mb-3 mt-3">
                <input
                  type="text"
                  className="form-control"
                  value={fee - (eid.split("-")[3] - eid.split("-")[4])}
                  placeholder="Enter paid"
                />
                <label htmlFor="eid">Balance to be Paid</label>
              </div>
              <input
                type="submit"
                className="btn btn-outline-primary form-control"
                onClick={() => {
                  axios
                    .post(url + "/edusphere/updatecourse", {
                      rid: eid.split("-")[0],
                      courseid: coursed.split("-")[0],
                      totalfee: fee,
                      bal: fee - (eid.split("-")[3] - eid.split("-")[4]),
                    })
                    .then((response) => {
                      nav("/viewregistration");
                    });
                }}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default Coursechange;
