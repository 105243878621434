import axios from "axios";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Nav from "../process/Nav";
import { url } from "../link";
const Updatemobileenquiry = () => {
  var rx = 0;
  const { state } = useLocation();

  const nav = useNavigate();
  const [mid, setmid] = useState(state[rx++]);
  const [mobile, setmobile] = useState(state[rx++]);
  const [course, setcourse] = useState(state[rx++]);
  const [remark, setremark] = useState(state[rx++]);
  const [curdate, setcurdate] = useState(state[rx++]);
  const [status, setstatus] = useState(state[rx++]);
  const [name, setname] = useState(state[rx++]);
  const [currentstatus, setcurrentstatus] = useState(state[rx++]);
  const submitdata = () => {
    const value = {
      mid: mid,
      mobile: mobile,
      course: course,
      remark: remark,
      curdate: curdate,
      status: status,
      name: name,
      currentstatus: currentstatus,
    };
    axios
      .post(url + "/edusphere/updatemobileenquiry", value)
      .then((response) => {
        nav("/viewmobileenquiry");
      });
  };
  return (
    <div>
      <Nav />
      <h1>Update mobileenquiry</h1>
      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setmid(e.target.value)}
          value={mid}
          placeholder="Enter mid"
        />
        <label htmlFor="mid">mid</label>
      </div>
      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setname(e.target.value)}
          value={name}
          placeholder="Enter name"
        />
        <label htmlFor="name">Name</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setcurrentstatus(e.target.value)}
          value={currentstatus}
          placeholder="Enter currentstatus"
        />
        <label htmlFor="currentstatus">Currentstatus</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setmobile(e.target.value)}
          value={mobile}
          placeholder="Enter mobile"
        />
        <label htmlFor="mobile">mobile</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setcourse(e.target.value)}
          value={course}
          placeholder="Enter course"
        />
        <label htmlFor="course">course</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setremark(e.target.value)}
          value={remark}
          placeholder="Enter remark"
        />
        <label htmlFor="remark">remark</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="date"
          className="form-control"
          onChange={(e) => setcurdate(e.target.value)}
          value={curdate}
          placeholder="Enter curdate"
        />
        <label htmlFor="curdate">curdate</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <select
          onChange={(e) => setstatus(e.target.value)}
          className="form-select"
          value={status}
        >
          <option>open</option>
          <option>close</option>
        </select>
        <label htmlFor="status">status</label>
      </div>

      <input
        type="submit"
        className="btn btn-primary"
        onClick={submitdata}
        style={{ width: "100%" }}
      />
    </div>
  );
};
export default Updatemobileenquiry;
