import Footer from "./Footer";
import Header from "./Header";
const Javawebdevelopment = () => {
  return (
    <div className="container-fuild">
      <Header />
      <div className="container">
        <div className="row border border-dark m-1">
          <div className="row text-center m-3">
            <h1>
              <b>
                <u>Java Web Development</u>
              </b>
            </h1>
          </div>

          <div className="row m-3"></div>

          <div className="row m-5 h4">
            <fieldset>
              <legend style={{ width: "40%" }}>Topics:</legend>
              <div className="card">
                <div className="card-header">
                  <a
                    className="btn"
                    data-bs-toggle="collapse"
                    href="#collapse1">
                    Java Term 1:(Foundation Programming in Java Web Development)
                  </a>
                </div>
                <div
                  id="collapse1"
                  className="collapse"
                  data-bs-parent="#accordion">
                  <div className="card-body">
                    <ul>
                      <li>
                        <a href="/syllabus/html.html">HTML</a>
                      </li>
                      <li>
                        <a href="/syllabus/java.html">Core Java</a>
                      </li>
                      <li>
                        <a href="/syllabus/mysql.html">My SQL</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <a
                    className="btn"
                    data-bs-toggle="collapse"
                    href="#collapse1">
                    Java Term 2: (Java Web Developer)
                  </a>
                </div>
                <div
                  id="collapse1"
                  className="collapse"
                  data-bs-parent="#accordion">
                  <div className="card-body">
                    <ul>
                      <li>
                        <a href="/syllabus/jsp.html">JSP and Servlet</a>
                      </li>
                      <li>
                        <a href="/syllabus/springboot.html">Spring Boot</a>
                      </li>
                      <li>
                        <a href="/syllabus/hibernate.html">Hibernate</a>
                      </li>
                      <li>
                        <a href="/syllabus/mongodb.html">Mongo DB</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>

          <div className="row m-5">
            <h2>
              <b> Certificate:</b>
            </h2>
            <h2>
              <p>Java Web Development</p>
            </h2>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Javawebdevelopment;
