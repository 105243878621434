import axios from "axios";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { url } from "../link";
const Updatemobileremark = () => {
  var rx = 0;
  const { state } = useLocation();
  const nav = useNavigate();
  const [mrid, setmrid] = useState(state[rx++]);
  const [remark, setremark] = useState(state[rx++]);
  const [trandate, settrandate] = useState(state[rx++]);
  const [mid, setmid] = useState(state[rx++]);
  const submitdata = () => {
    const value = { mrid: mrid, remark: remark, trandate: trandate, mid: mid };
    axios
      .post(url + "/edusphere/updatemobileremark", value)
      .then((response) => {
        nav("/viewmobileremark");
      });
  };
  return (
    <div>
      <h1>Add mobileremark</h1>
      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setmrid(e.target.value)}
          value={mrid}
          placeholder="Enter mrid"
        />
        <label htmlFor="mrid">mrid</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setremark(e.target.value)}
          value={remark}
          placeholder="Enter remark"
        />
        <label htmlFor="remark">remark</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => settrandate(e.target.value)}
          value={trandate}
          placeholder="Enter trandate"
        />
        <label htmlFor="trandate">trandate</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setmid(e.target.value)}
          value={mid}
          placeholder="Enter mid"
        />
        <label htmlFor="mid">mid</label>
      </div>

      <input
        type="submit"
        className="btn btn-primary"
        onClick={submitdata}
        style={{ width: "100%" }}
      />
    </div>
  );
};
export default Updatemobileremark;
