import axios from "axios";
import { useState } from "react";
import { url } from "../link";
const Addattendingdays = () => {
  const [aid, setaid] = useState("");
  const [days, setdays] = useState("");
  const submitdata = () => {
    const value = { aid: aid, days: days };
    axios.post(url + "/edusphere/insertattendingdays", value).then((res) => {
      alert("success");
      setaid("");
      setdays("");
    });
  };
  return (
    <div>
      <h1>Add attendingdays</h1>
      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setaid(e.target.value)}
          value={aid}
          placeholder="Enter aid"
        />
        <label htmlFor="aid">aid</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setdays(e.target.value)}
          value={days}
          placeholder="Enter days"
        />
        <label htmlFor="days">days</label>
      </div>

      <input
        type="submit"
        className="btn btn-primary"
        onClick={submitdata}
        style={{ width: "100%" }}
      />
    </div>
  );
};
export default Addattendingdays;
