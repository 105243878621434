import Footer from "./Footer";
import Header from "./Header";
const Tallyprime = () => {
  return (
    <div className="container-fuild">
      <Header />
      <div className="container">
        <div className="row border border-dark m-1">
          <div className="row text-center m-3">
            <h1>
              <b>
                <u>Tally Prime</u>
              </b>
            </h1>
          </div>

          <div className="row m-3">
            <h4>Accounting Software.</h4>
          </div>

          <div className="row m-5 h4">
            <fieldset>
              <legend style={{ width: "40%" }}>Topics:</legend>
              <ul>
                <li>Groups</li>
                <li>Ledger</li>
                <li>Stock group</li>
                <li>Stock category</li>
                <li>Stock item</li>
                <li>Vouchers</li>
                <li>Accounting Features </li>
                <li>Bill wise entries</li>
                <li>Multiple currencies</li>
                <li>Interest calculation</li>
                <li>Cost centre and cost category </li>
                <li>Budget control</li>
                <li>Scenario manager</li>
                <li>Bank reconciliation</li>
                <li> Voucher class</li>
                <li>Point of sale</li>
                <li>Inventory features </li>
                <li>Purchase order</li>
                <li>Sales order</li>
                <li>Receipt and delivery note</li>
                <li>Additional cost charges</li>
                <li>Actual vs build </li>
                <li>Multiple price level</li>
                <li>Zero valid entry</li>
                <li>Bill of material</li>
                <li>Gst</li>
              </ul>
            </fieldset>
          </div>

          <div className="row m-5">
            <h2>
              <b> Certificate:</b>
            </h2>
            <h2>
              <p>Accounting in Tally Prime with GST</p>
            </h2>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Tallyprime;
