import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/logo.png';
import homeIcon from '../images/home-icon.png';
import enquiryIcon from '../images/enquiry-icon.png';
import loginIcon from '../images/login-icon.png';
import courseIcon from '../images/course-icon.png';
import contactIcon from '../images/contact-icon.png';
import aboutIcon from '../images/about-icon.png';

const Header = () => {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const [hoveredIcon, setHoveredIcon] = useState(null);

  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  const handleIconHover = (iconName) => {
    setHoveredIcon(iconName);
  };

  const handleIconLeave = () => {
    setHoveredIcon(null);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsNavbarOpen(false);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <header
      style={{
        background: 'url("https://img.freepik.com/free-photo/smooth-blue-wooden-textured-background_53876-108424.jpg") center center / cover',
        backgroundColor: 'rgba(1, 1, 1, 0.7)',
        padding: '10px',
        marginBottom: '20px',
        color: '#fff',
        position: 'sticky', // Change from "fixed" to "sticky"
        top: 0, // Stick the header to the top of the viewport
        left: 0, // Align the header to the left of the viewport
        right: 0, // Align the header to the right of the viewport
        zIndex: 1000, // Set a higher z-index to make sure the header is on top of other content
      }}
    >
      
        <div className="row align-items-center">
          <div className="col-md-1">
            <Link to="/">
              <img
                src={logo}
                alt="Logo"
                style={{
                  width: '100px',
                  height: '100px',
                  borderRadius: '50%',
                  boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
                }}
                className="m-3 bg-light"
              />
            </Link>

          </div>
          <div className='col-md-7'>
          <div className='company-name'>
          <marque style={{fontSize:'2em'}}>
            Edusphere Software Training and Development Institute
          </marque>
          </div>
          
        
          </div>
          <div className="col-md-4 d-flex justify-content-md-end">
            <div className="d-flex flex-wrap justify-content-md-end">
              <Link
                to="/"
                className="nav-link"
                onMouseEnter={() => handleIconHover('Home')}
                onMouseLeave={handleIconLeave}
              >
                <div
                  className={`icon-container ${hoveredIcon === 'Home' ? 'icon-hovered' : ''}`}
                >
                  <img src={homeIcon} alt="Home" className="icon-style" />
                  <span className="link-name-style">{hoveredIcon === 'Home' ? 'Home' : ''}</span>
                </div>
              </Link>
              <Link
                to="/enquiry"
                className="nav-link"
                onMouseEnter={() => handleIconHover('Enquiry')}
                onMouseLeave={handleIconLeave}
              >
                <div
                  className={`icon-container ${hoveredIcon === 'Enquiry' ? 'icon-hovered' : ''}`}
                >
                  <img src={enquiryIcon} alt="Enquiry" className="icon-style" />
                  <span className="link-name-style">{hoveredIcon === 'Enquiry' ? 'Enquiry' : ''}</span>
                </div>
              </Link>
              <Link
                to="/login"
                className="nav-link"
                onMouseEnter={() => handleIconHover('Login')}
                onMouseLeave={handleIconLeave}
              >
                <div
                  className={`icon-container ${hoveredIcon === 'Login' ? 'icon-hovered' : ''}`}
                >
                  <img src={loginIcon} alt="Login" className="icon-style" />
                  <span className="link-name-style">{hoveredIcon === 'Login' ? 'Login' : ''}</span>
                </div>
              </Link>
              <Link
                to="/course_check"
                className="nav-link"
                onMouseEnter={() => handleIconHover('Course')}
                onMouseLeave={handleIconLeave}
              >
                <div
                  className={`icon-container ${hoveredIcon === 'Course' ? 'icon-hovered' : ''}`}
                >
                  <img src={courseIcon} alt="Course" className="icon-style" />
                  <span className="link-name-style">{hoveredIcon === 'Course' ? 'Course' : ''}</span>
                </div>
              </Link>
              <Link
                to="/contact"
                className="nav-link"
                onMouseEnter={() => handleIconHover('Contact')}
                onMouseLeave={handleIconLeave}
              >
                <div
                  className={`icon-container ${hoveredIcon === 'Contact' ? 'icon-hovered' : ''}`}
                >
                  <img src={contactIcon} alt="Contact" className="icon-style" />
                  <span className="link-name-style">{hoveredIcon === 'Contact' ? 'Contact' : ''}</span>
                </div>
              </Link>
              <Link
                to="/about"
                className="nav-link"
                onMouseEnter={() => handleIconHover('About')}
                onMouseLeave={handleIconLeave}
              >
                <div
                  className={`icon-container ${hoveredIcon === 'About' ? 'icon-hovered' : ''}`}
                >
                  <img src={aboutIcon} alt="About" className="icon-style" />
                  <span className="link-name-style">{hoveredIcon === 'About' ? 'About' : ''}</span>
                </div>
              </Link>
            </div>
          </div>
        </div>

       

      
      <style>
        {`
           .company-name {
            animation: waveAnimation 10s linear infinite;
            white-space: nowrap;
            overflow: hidden;
            text-align: center;
            width: auto;
          }

          .company-name h1:hover {
            animation-play-state: paused;
          }
          .icon-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            transition: transform 0.3s;
            margin-bottom: 10px;
            padding: 5px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            border-radius: 8px;
            transform: scale(1);
          }

          .icon-hovered {
            transform: scale(1.2);
          }

          .icon-style {
            width: 50px;
            height: 50px;
          }

          .link-name-style {
            font-size: 0.8rem;
            text-align: center;
            margin-top: 5px;
            color: white;
            visibility: hidden;
          }

          .icon-hovered .link-name-style {
            visibility: visible;
          }

          @keyframes waveAnimation {
            0%, 100% {
              transform: translateX(0); /* Start and end at the center */
            }
            25% {
              transform: translateX(calc(50% - 50vw)); /* Move from left to right */
            }
            75% {
              transform: translateX(calc(-50% + 50vw)); /* Move from right to left */
            }
          }
        `}
      </style>
    </header>
  );
};

export default Header;
