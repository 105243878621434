import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import axios from "axios";
import { url } from "../link";

const Changedate = () => {
  const [s, sets] = useState(false);
  const nav = useNavigate();
  return (
    <>
      <Navbar />
      <h1 style={{ color: "red", textAlign: "center" }}>
        Your system is not the currentdate. Move to Current date
      </h1>
      <input
        type="checkbox"
        onClick={() => {
          sets(!s);
        }}
        checked={s}
        class="form-check-input"
        style={{ fontSize: "50px", textAlign: "center" }}
      />
      <label
        class="form-check-label"
        for="flexCheckDefault"
        style={{ fontSize: "50px", textAlign: "center" }}
      >
        Check to change date
      </label>
      <br />
      <input
        type="submit"
        onClick={() => {
          if (s) {
            axios
              .post(url + "/edusphere/changedate", {
                user: window.localStorage.getItem("user").split(",")[1],
              })
              .then((res) => {
                alert("date changed");
                nav("/dashboard");
              });
          } else {
            alert("click on check box");
          }
        }}
        className="btn btn-primary"
        style={{ width: "100%" }}
      />
    </>
  );
};

export default Changedate;
