import axios from "axios";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { url } from "../link";
const Updatelaptopphoto = () => {
  var rx = 0;
  const { state } = useLocation();
  const nav = useNavigate();
  const [lpid, setlpid] = useState(state[rx++]);
  const [photoname, setphotoname] = useState(state[rx++]);
  const [lid, setlid] = useState(state[rx++]);
  const submitdata = () => {
    const value = { lpid: lpid, photoname: photoname, lid: lid };
    axios.post(url + "/edusphere/updatelaptopphoto", value).then((response) => {
      nav("/viewlaptopphoto");
    });
  };
  return (
    <div>
      <h1>Add laptopphoto</h1>
      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setlpid(e.target.value)}
          value={lpid}
          placeholder="Enter lpid"
        />
        <label htmlFor="lpid">lpid</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setphotoname(e.target.value)}
          value={photoname}
          placeholder="Enter photoname"
        />
        <label htmlFor="photoname">photoname</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setlid(e.target.value)}
          value={lid}
          placeholder="Enter lid"
        />
        <label htmlFor="lid">lid</label>
      </div>

      <input
        type="submit"
        className="btn btn-primary"
        onClick={submitdata}
        style={{ width: "100%" }}
      />
    </div>
  );
};
export default Updatelaptopphoto;
