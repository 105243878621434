import Footer from "./Footer";
import Header from "./Header";
const Flask = () => {
  return (
    <div className="container-fuild">
      <Header />
      <div className="container">
        <div className="row border border-dark m-1">
          <div className="row text-center m-3">
            <h1>
              <b>
                <u> Flask</u>
              </b>
            </h1>
          </div>

          <div className="row m-3">
            <h4>
              Flask is a web framework that provides libraries to build
              lightweight web applications in python. It is developed by Armin
              Ronacher who leads an international group of python enthusiasts
              (POCCO).
            </h4>
          </div>

          <div className="row m-5 h4">
            <fieldset>
              <legend style={{ width: "40%" }}>Topics:</legend>
              <ul>
                <li>Introduction to Flask</li>
                <li>App roputing</li>
                <li>URL building</li>
                <li>HTTP methods</li>
                <li>Templates</li>
                <li>Request Object</li>
                <li>Cookies</li>
                <li>Database</li>
                <li>Session</li>
                <li>Uploading</li>
                <li>Redirect and error</li>
                <li>Flashing</li>
                <li>Mail extension</li>
                <li>Sqlite</li>
                <li>WTF</li>
              </ul>
            </fieldset>
          </div>

          <div className="row m-5">
            <h2>
              <b> Certificate:</b>
            </h2>
            <h2>
              <p>Flask</p>
            </h2>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Flask;
