import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { url } from "../link";
import Navbar from "../components/Navbar";
const Viewregistration = () => {
  const nav = useNavigate();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filteredwithData, setFilteredwithData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");

  const [s, sets] = useState([]);
  const [pending, setPending] = useState(true);

  const column = [
    {
      name: "Rid",
      selector: (row) => row.Rid,
      sortable: true,
    },
    {
      name: "First_name",
      selector: (row) => row.First_name,
      sortable: true,
    },
    {
      name: "Full_name",
      selector: (row) => row.Full_name,
      sortable: true,
    },
    {
      name: "Mobile",
      selector: (row) => row.Mobile,
      sortable: true,
    },
    {
      name: "Regdate",
      selector: (row) => row.Regdate,
      sortable: true,
    },

    {
      name: "Email",
      selector: (row) => row.Email,
      sortable: true,
    },
    {
      name: "Course",
      selector: (row) => row.Course,
      sortable: true,
    },
    {
      name: "ClassBy",
      selector: (row) => row.ClassBy,
      sortable: true,
    },

    {
      name: "FeeJoined",
      selector: (row) => row.FeeJoined,
      sortable: true,
    },
    {
      name: "Bal",
      selector: (row) => row.Bal,
      sortable: true,
    },
    {
      name: "NextPayDate",
      selector: (row) => row.NextPayDate,
      sortable: true,
    },
    {
      name: "NextInstallment",
      selector: (row) => row.NextInstallment,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.Status,
      sortable: true,
    },
    {
      name: "Remark",
      selector: (row) => row.Remark,
      sortable: true,
    },
    {
      name: "History",
      selector: (row) => row.History,
      sortable: true,
    },
  ];
  useEffect(() => {
    const timeout = setTimeout(() => {
      axios.post(url + "/edusphere/viewregistration").then((response) => {
        var e = ["All"];

        var r = response.data.map((k) => {
          if (e.indexOf(k[13].toLowerCase()) === -1) {
            e.push(k[13].toLowerCase());
          }

          return {
            Rid: k[0],
            First_name: k[1],
            Full_name: k[14],
            Mobile: k[2],
            Regdate: k[10],
            Email: k[4],
            Course: k[5],
            ClassBy: k[6],
            FeeJoined: k[7],
            Bal: k[8],
            NextPayDate: k[9],
            NextInstallment: k[12],
            Status: k[13],
            Remark: (
              <button
                className="btn btn-danger"
                onClick={() => {
                  nav("/addregistrationremark", { state: k[0] });
                }}
              >
                remark
              </button>
            ),
            History: (
              <button
                className="btn btn-primary"
                onClick={() => viewregistration(k)}
              >
                History
              </button>
            ),
          };
        });

        setData(r);
        setFilteredData(r);
        setFilteredwithData(r);
        sets(e);
        setPending(false);
      });
      return () => clearTimeout(timeout);
    }, 1000);
  }, []);
  const viewregistration = (e) => {
    nav("/viewsinglereg", { state: e[0] });
  };
  const filterByStatus = (status) => {
    setSelectedStatus(status);

    if (status === "All") {
      setFilteredData(data);
    } else {
      const filteredResults = data.filter(
        (item) => item.Status.toLowerCase() === status.toLowerCase()
      );
      setFilteredData(filteredResults);
      setFilteredwithData(filteredResults);
    }
  };
  //  Internally, customStyles will deep merges your customStyles with the default styling.
  const customStyles = {
    table: {
      style: {
        border: "1px solid #dee2e6", // Table border
      },
    },
    headRow: {
      style: {
        borderBottom: "1px solid #dee2e6", // Header row border
      },
    },

    rows: {
      style: {
        minHeight: "72px", // override the row height
        borderBottom: "1px solid #dee2e6",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontSize: "1.2em",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        fontSize: "1em",
        borderRight: "1px solid #dee2e6",
      },
    },
  };

  return (
    <div>
      <Navbar />
      <div className="main">
        <h3>All Registration</h3>
        <label htmlFor="statusFilter">Filter by Status:</label>

        <select
          id="statusFilter"
          value={selectedStatus}
          onChange={(e) => filterByStatus(e.target.value)}
        >
          {s.map((status, index) => (
            <option key={index} value={status}>
              {status}
            </option>
          ))}
        </select>
        <div className="dataTableContainer">
          <input
            type="search"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              const filtered = filteredwithData.filter((item) =>
                Object.values(item).some((value) => {
                  if (value !== null && value !== undefined) {
                    return value
                      .toString()
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase());
                  }
                  return false; // Handle null or undefined value, e.g., consider it not matching.
                })
              );
              setFilteredData(filtered);
            }}
            placeholder="Search..."
          />
          <DataTable
            columns={column}
            data={filteredData}
            pagination
            fixedHeader
            fixedHeaderScrollHeight="70vh"
            customStyles={customStyles}
            progressPending={pending}
          />
        </div>
      </div>
    </div>
  );
};
export default Viewregistration;
