import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Nav from "../process/Nav";
import Navbar from "../components/Navbar";
import { url } from "../link";
const Viewsinglereg = () => {
  const { state } = useLocation();

  const nav = useNavigate();
  const [id] = useState(state);
  const [reg, setreg] = useState([[], []]);
  const [bill, setbill] = useState([[], []]);
  const [regcol, setregcol] = useState([[], []]);

  useEffect(() => {
    axios
      .post(url + "/edusphere/vieweachreg", {
        rid: id,
      })
      .then((response) => {
        setbill(response.data["bill"]);

        setreg(response.data["reg"]);
        setregcol(response.data["registrationremark"]);
      });
  }, []);
  const edit = (e) => {
    nav("/updateregistration", { state: reg[0] });
  };
  const printbill = (e) => {
    nav("/printbill", { state: e });
  };
  const viewenquiry = (e) => {
    var k = e.splice(1, e.length);

    nav("/viewenquiry", { state: k });
  };
  const setvalue = () => {
    var x1 = 0;
    var k1 = 0;

    if (bill !== undefined) {
      return (
        <div>
          <h1>Bill details</h1>

          <table className="table table-bordered">
            <thead>
              <tr>
                {bill[bill.length - 1].map((e) => {
                  return <th key={k1++}>{e}</th>;
                })}
                <th>print</th>
              </tr>
            </thead>
            <tbody>
              {bill.slice(0, bill.length - 1).map((bi) => {
                return (
                  <tr>
                    {bi.map((e) => {
                      return <td key={x1++}>{e}</td>;
                    })}
                    <td>
                      <button
                        className="btn btn-primary"
                        onClick={() => printbill(bill[0])}
                      >
                        print
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    } else {
      return <h1>No Bill available</h1>;
    }
  };

  const setremark = () => {
    var x1 = 0;
    var k1 = 0;

    if (regcol !== undefined) {
      return (
        <div>
          <h1>Registration Remark</h1>

          <table className="table table-bordered">
            <thead>
              <tr>
                {regcol[regcol.length - 1].map((e) => {
                  return <th key={k1++}>{e}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {regcol.slice(0, regcol.length - 1).map((bi) => {
                return (
                  <tr>
                    {bi.map((e) => {
                      return <td key={x1++}>{e}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    } else {
      return <h1>No Remark available</h1>;
    }
  };
  var x = 0;
  var k = 0;
  return (
    <>
      <Navbar />
      <div className="main">
        <div className="page-container">
          <h1>Registration details</h1>
          <button className="btn btn-primary" onClick={() => edit(reg[1])}>
            Edit
          </button>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th></th>
                {reg[1].map((e) => {
                  return <th key={k++}>{e}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <button
                    className="btn btn-primary"
                    onClick={() => viewenquiry(reg[0])}
                  >
                    view enq
                  </button>
                </td>
                <td>{reg[0][0]}</td>
                <td>{reg[0][1]}</td>
                <td>{reg[0][2]}</td>
                <td>{reg[0][3]}</td>
                <td>{reg[0][4]}</td>
                <td>{reg[0][5]}</td>
                <td>{reg[0][6]}</td>
                <td>{reg[0][7]}</td>
                <td>{reg[0][8]}</td>
                <td>{reg[0][9]}</td>
                <td>{reg[0][10]}</td>
                <td>{reg[0][11]}</td>
                <td>{reg[0][12]}</td>
              </tr>
            </tbody>
          </table>
          {setvalue()}
          {setremark()}
        </div>
      </div>
    </>
  );
};

export default Viewsinglereg;
