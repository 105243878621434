import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { url } from "../link";
import Navbar from "../components/Navbar";
import Loading from "../components/Loading";

const Viewattendancetoday = () => {
  const nav = useNavigate();
  const [data, setData] = useState([]);
  const [value, setValue] = useState([]);
  const [x, setx] = useState(0);

  useEffect(() => {
    setx(0);
    axios.post(url + "/edusphere/viewattendancetoday").then((response) => {
      setx(1);
      setData(response.data);
      setValue(response.data);
    });
  }, []);

  const viewattendance = (e) => {
    nav("/updateattendance", { state: e });
  };

  const deletec = (e) => {
    setx(0);
    axios
      .post(url + "/edusphere/deleteattendancetoday", { id: e })
      .then((response) => {
        axios.post(url + "/edusphere/viewattendancetoday").then((response) => {
          setx(1);
          setData(response.data);
          setValue(response.data);
        });
      });
  };

  const searchdata = (e) => {
    var e = e.toLowerCase();
    const r = [];
    for (var k of value) {
      var v = 0;
      for (var n of k) {
        n = "" + n;
        if (n.toLowerCase().indexOf(e) !== -1) {
          v = 1;
          break;
        }
      }
      if (v === 1) {
        r.push(k);
      }
    }
    setData(r);
  };
  const content = () => {
    if (x === 0) {
      return <Loading />;
    } else {
      return (
        <div>
          <Navbar />
          <h3>Attendance</h3>
          <input
            type="search"
            onChange={(e) => searchdata(e.target.value)}
            className="form-select"
            placeholder="Search"
          />
          <div className="table-responsive">
            <table className="table table-bordered" id="table_id">
              <thead>
                <tr>
                  <th>Rid</th>
                  <th>Name</th>
                  <th>Cname</th>
                  <th>Mobile</th>
                  <th>Classby</th>
                  <th>Startime</th>
                  <th>Endtime</th>
                  <th>Course</th>
                  <th>Status</th>
                  <th>Days</th>
                  <th>Balance</th>
                  <th>Next Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.map((d) => {
                  return (
                    <tr key={d[0]}>
                      <td>{d[1]}</td>
                      <td>{d[2]}</td>
                      <td>{d[3]}</td>
                      <td>{d[4]}</td>
                      <td>{d[5]}</td>
                      <td>{d[6]}</td>
                      <td>{d[7]}</td>
                      <td>{d[9]}</td>
                      <td>{d[8]}</td>
                      <td>{d[10]}</td>
                      <td>{d[12]}</td>
                      <td>{d[13]}</td>
                      <td>
                        <button
                          className="btn btn-danger"
                          onClick={() => deletec(d[0])}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  };
  return <>{content()}</>;
};

export default Viewattendancetoday;
