import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Nav from "../process/Nav";
import { url } from "../link";
const Viewenquiry = () => {
  const nav = useNavigate();
  const { state } = useLocation();

  const [id] = useState(state[0]);
  const [enquiry, setenquiry] = useState([[], []]);
  const [reg, setreg] = useState([[], []]);
  const [encol, setencol] = useState([]);
  const [regcol, setregcol] = useState([]);
  const [er, seter] = useState([]);
  const [ercol, setercol] = useState([]);
  useEffect(() => {
    axios
      .post(url + "/edusphere/vieweachenquiry", {
        eid: id,
      })
      .then((response) => {
        setenquiry(response.data["enquiry"][0]);
        setreg(response.data["reg"]);
        setencol(response.data["encol"]);
        setregcol(response.data["regcol"]);
        setercol(response.data["ermarkcol"]);
        seter(response.data["ermark"]);
      });
  }, []);

  const edit = (e) => {
    nav("/updateenquiry", { state: e });
  };
  const viewsinglereg = (e) => {
    nav("/viewsinglereg", { state: e });
  };
  const deletereg = (e) => {
    axios
      .post(url + "/edusphere/deleteregistration", {
        rid: e,
      })
      .then((response) => {
        axios
          .post(url + "/edusphere/vieweachenquiry", {
            eid: id,
          })
          .then((response) => {
            setenquiry(response.data["enquiry"][0]);
            setreg(response.data["reg"]);
            setencol(response.data["encol"]);
            setregcol(response.data["regcol"]);
            setercol(response.data["ermarkcol"]);
            seter(response.data["ermark"]);
          });
      });
  };
  const editreg = (e) => {
    nav("/updateregistration", { state: e });
  };
  const setvalue = () => {
    var x1 = 0;
    var k1 = 0;

    if (reg[0] !== undefined) {
      return (
        <div>
          <h1>Registration details</h1>
          <button className="btn btn-primary" onClick={() => editreg(reg[0])}>
            Edit
          </button>

          <table className="table table-bordered">
            <thead>
              <tr>
                <th></th>
                {regcol.map((e) => {
                  return <th key={k1++}>{e}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {reg.map((e) => {
                return (
                  <tr>
                    <td>
                      <button
                        className="btn btn-primary"
                        onClick={() => viewsinglereg(e)}
                      >
                        viewreg
                      </button>
                    </td>
                    {e.map((x) => {
                      return <td key={x1++}>{x}</td>;
                    })}
                    <td>
                      <button
                        className="btn btn-primary"
                        onClick={() => deletereg(e[0])}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    } else {
      return <h1>No Registration avaiable</h1>;
    }
  };

  const setremark = () => {
    var x1 = 0;
    var k1 = 0;

    if (er[0] !== undefined) {
      return (
        <div>
          <h1>Enquiry Remark</h1>

          <table className="table table-bordered">
            <thead>
              <tr>
                {ercol.map((e) => {
                  return <th key={k1++}>{e}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {er.map((e) => {
                return (
                  <tr>
                    {e.map((x) => {
                      return <td key={x1++}>{x}</td>;
                    })}
                    <td>
                      <button
                        className="btn btn-primary"
                        onClick={() => deletereg(e[0])}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    } else {
      return <h1>No Remark avaiable</h1>;
    }
  };
  var x = 0;
  var k = 0;
  const deletes = (e) => {
    axios
      .post(url + "/edusphere/enquirydelete", {
        eid: e,
      })
      .then((response) => {
        nav("/showenquiry");
      });
  };
  return (
    <>
      <div>
        <Nav />

        <div className="main">
          <div className="page-container">
            <h1>Enquiry details</h1>
            <button className="btn btn-primary" onClick={() => edit(enquiry)}>
              Edit
            </button>
            <button
              className="btn btn-primary"
              onClick={() => deletes(enquiry[0])}
            >
              Delete
            </button>
            <table className="table table-bordered">
              <thead>
                <tr>
                  {encol.map((e) => {
                    return <th key={k++}>{e}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {enquiry.map((e) => {
                    return <td key={x++}>{e}</td>;
                  })}
                </tr>
              </tbody>
            </table>
            {setvalue()}
            {setremark()}
          </div>
        </div>
      </div>
    </>
  );
};

export default Viewenquiry;
