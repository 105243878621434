import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Nav from "../process/Nav";
import { url } from "../link";
const Viewproject23payment = () => {
  const nav = useNavigate();
  const { state } = useLocation();
  const [data, setData] = useState([]);
  const [value, setvalue] = useState([]);
  useEffect(() => {
    axios
      .post(url + "/edusphere/viewproject23payment", {
        id: state,
      })
      .then((response) => {
        setData(response.data);
        setvalue(response.data);
      });
  }, []);

  const deletec = (e) => {
    axios
      .post(url + "/edusphere/deleteproject23payment", { id: e })
      .then((response) => {
        axios
          .post(url + "/edusphere/viewproject23payment", {
            id: state,
          })
          .then((response) => {
            setData(response.data);
            setvalue(response.data);
          });
      });
  };

  return (
    <div>
      <Nav />

      <h3>View Payment Bill</h3>

      <div className="table-responsive">
        <table className="table table-bordered" id="table_id">
          <thead>
            <tr>
              <th>pr23pa</th>
              <th>pid</th>
              <th>amountpaid</th>
              <th>balancetobepaid</th>
              <th>paiddate</th>
            </tr>
          </thead>
          <tbody>
            {data.map((d) => {
              return (
                <tr key={d[0]}>
                  <td>{d[0]}</td>
                  <td>{d[1]}</td>
                  <td>{d[2]}</td>
                  <td>{d[3]}</td>
                  <td>{d[4]}</td>
                  <td>
                    <button
                      className="btn btn-primary"
                      onClick={() => deletec(d[0])}
                    >
                      delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default Viewproject23payment;
