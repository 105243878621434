import axios from "axios";
import { useState } from "react";
import Nav from "../process/Nav";
import { NavLink } from "react-router-dom";
import { url } from "../link";
const Addmobileenquiry = () => {
  const [mobile, setmobile] = useState("");
  const [course, setcourse] = useState("");
  const [remark, setremark] = useState("");
  const [curdate, setcurdate] = useState("");
  const [status, setstatus] = useState("open");
  const [name, setname] = useState("");
  const [currentstatus, setcurrentstatus] = useState("");
  const submitdata = () => {
    const value = {
      mobile: mobile,
      course: course,
      remark: remark,
      curdate: curdate,
      status: status,
      name: name,
      currentstatus: currentstatus,
    };
    axios.post(url + "/edusphere/insertmobileenquiry", value).then((res) => {
      alert("success");
      setmobile("");
      setcourse("");
      setremark("");
      setcurdate("");
      setstatus("");
      setname("");
      setcurrentstatus("");
    });
  };
  return (
    <div>
      <Nav />
      <NavLink to="/viewmobileenquiry">
        <button className="btn btn-primary" style={{ float: "right" }}>
          View Mobile Enquiry
        </button>
      </NavLink>
      <h1>Add mobileenquiry</h1>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setname(e.target.value)}
          value={name}
          placeholder="Enter name"
        />
        <label htmlFor="name">Name</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setcurrentstatus(e.target.value)}
          value={currentstatus}
          placeholder="Enter currentstatus"
        />
        <label htmlFor="currentstatus">Currentstatus</label>
      </div>
      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setmobile(e.target.value)}
          value={mobile}
          placeholder="Enter mobile"
        />
        <label htmlFor="mobile">mobile</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setcourse(e.target.value)}
          value={course}
          placeholder="Enter course"
        />
        <label htmlFor="course">course</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setremark(e.target.value)}
          value={remark}
          placeholder="Enter remark"
        />
        <label htmlFor="remark">remark</label>
      </div>

      {/* <div className="form-floating mb-3 mt-3">
        <input
          type="date"
          className="form-control"
          onChange={(e) => setcurdate(e.target.value)}
          value={curdate}
          placeholder="Enter curdate"
        />
        <label htmlFor="curdate">curdate</label>
      </div> */}

      <input
        type="submit"
        className="btn btn-primary"
        onClick={submitdata}
        style={{ width: "100%" }}
      />
    </div>
  );
};
export default Addmobileenquiry;
