import axios from "axios";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { url } from "../link";
const Updateenquiryremark = () => {
  var rx = 0;
  const { state } = useLocation();
  const [erid, seterid] = useState(state[rx++]);
  const [remark, setremark] = useState(state[rx++]);
  const [remarkdate, setremarkdate] = useState(state[rx++]);
  const [eid, seteid] = useState(state[rx++]);
  const nav = useNavigate();
  const submitdata = () => {
    const value = {
      erid: erid,
      remark: remark,
      remarkdate: remarkdate,
      eid: eid,
    };
    axios
      .post(url + "/edusphere/updateenquiryremark", value)
      .then((response) => {
        nav("/viewenquiryremark");
      });
  };
  return (
    <div>
      <h1>Add enquiryremark</h1>
      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => seterid(e.target.value)}
          value={erid}
          placeholder="Enter erid"
        />
        <label htmlFor="erid">erid</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setremark(e.target.value)}
          value={remark}
          placeholder="Enter remark"
        />
        <label htmlFor="remark">remark</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setremarkdate(e.target.value)}
          value={remarkdate}
          placeholder="Enter remarkdate"
        />
        <label htmlFor="remarkdate">remarkdate</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => seteid(e.target.value)}
          value={eid}
          placeholder="Enter eid"
        />
        <label htmlFor="eid">eid</label>
      </div>

      <input
        type="submit"
        className="btn btn-primary"
        onClick={submitdata}
        style={{ width: "100%" }}
      />
    </div>
  );
};
export default Updateenquiryremark;
