import axios from "axios";
import { useState } from "react";
import { url } from "../link";
const Addlaptopphoto = () => {
  const [photoname, setphotoname] = useState("");
  const [lid, setlid] = useState("");
  const submitdata = () => {
    const value = { photoname: photoname, lid: lid };
    axios.post(url + "/edusphere/insertlaptopphoto", value).then((res) => {
      alert("success");
      setphotoname("");
      setlid("");
    });
  };
  return (
    <div>
      <h1>Add laptopphoto</h1>
      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setphotoname(e.target.value)}
          value={photoname}
          placeholder="Enter photoname"
        />
        <label htmlFor="photoname">photoname</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setlid(e.target.value)}
          value={lid}
          placeholder="Enter lid"
        />
        <label htmlFor="lid">lid</label>
      </div>

      <input
        type="submit"
        className="btn btn-primary"
        onClick={submitdata}
        style={{ width: "100%" }}
      />
    </div>
  );
};
export default Addlaptopphoto;
