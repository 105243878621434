import axios from "axios";
import { useState } from "react";
import { url } from "../link";
import { useLocation, useNavigate } from "react-router-dom";
const Updateattendingdays = () => {
  var rx = 0;
  const { state } = useLocation();
  const nav = useNavigate();
  const [adid, setadid] = useState(state[rx++]);
  const [aid, setaid] = useState(state[rx++]);
  const [days, setdays] = useState(state[rx++]);
  const submitdata = () => {
    const value = { adid: adid, aid: aid, days: days };
    axios
      .post(url + "/edusphere/updateattendingdays", value)
      .then((response) => {
        nav("/viewattendingdays");
      });
  };
  return (
    <div>
      <h1>Add attendingdays</h1>
      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setadid(e.target.value)}
          value={adid}
          placeholder="Enter adid"
        />
        <label htmlFor="adid">adid</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setaid(e.target.value)}
          value={aid}
          placeholder="Enter aid"
        />
        <label htmlFor="aid">aid</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setdays(e.target.value)}
          value={days}
          placeholder="Enter days"
        />
        <label htmlFor="days">days</label>
      </div>

      <input
        type="submit"
        className="btn btn-primary"
        onClick={submitdata}
        style={{ width: "100%" }}
      />
    </div>
  );
};
export default Updateattendingdays;
