import axios from "axios";
import { useState } from "react";
import { url } from "../link";
const Fileupload = (props) => {
  const { name, setdata } = props;
  const [selectedFiles, setselectedfiles] = useState("");
  const [format, setformat] = useState("");
  const selectFile = (event) => {
    var x = event.target.files[0].name;

    var forx = x.split(".")[1];

    setformat(forx);
    setselectedfiles(event.target.files);
  };
  const upload = (event) => {
    let currentFiles = selectedFiles[0];

    let formData = new FormData();

    formData.append("file", currentFiles);
    formData.append("name", name);
    formData.append("format", format);

    axios
      .post(url + "/edusphere/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        alert("uploaded");
        setdata(res.data);
        setselectedfiles("");
      })
      .catch((err) => {});
  };
  return (
    <>
      <div className="form-group" style={{ display: "flex" }}>
        <input
          className="form-control me-2"
          name="file"
          style={{ width: "50vh" }}
          onChange={selectFile}
          type="file"
        />
        <button
          className="btn btn-warning"
          disabled={!selectedFiles}
          onClick={upload}
          type="button"
        >
          Upload
        </button>
      </div>
    </>
  );
};

export default Fileupload;
