import Footer from "./Footer";
import Header from "./Header";
const Selenium = () => {
  return (
    <div className="container-fuild">
      <Header />
      <div className="container">
        <div className="row border border-dark m-1">
          <div className="row text-center m-3">
            <h1>
              <b>
                <u>Selenium Testing</u>
              </b>
            </h1>
          </div>

          <div className="row m-3">
            <h4>
              Selenium is one of the most widely used open source Web UI (User
              Interface) automation testing suite. Our Selenium tutorial
              includes all topics of Selenium such as Features, Selenium vs QTP,
              Selenium Tool Suits, Selenium IDE, Selenium IDE Locating
              Strategies, Selenium WebDriver, WebDriver Features, WebDriver vs
              RC, WebDriver Installation, etc.
            </h4>
          </div>

          <div className="row m-5 h4">
            <fieldset>
              <legend style={{ width: "40%" }}>Topics:</legend>
              <ul>
                <li>Introduction to Selenium</li>
                <li>Selenium IDE</li>
                <li>Selenium WebDriver</li>
                <li>Web driver installation</li>
                <li>Web driver commands</li>
                <li>Running test on Chrome</li>
                <li>Running test on Firefox</li>
                <li>Running test on IE</li>
                <li>Locating Strategies</li>
                <li>Handling Drop downs</li>
                <li>Drag Drop</li>
                <li>Alerts</li>
                <li>Scrolling</li>
                <li>Browser command</li>
                <li>Navigation command</li>
                <li>Web element command</li>
                <li>Raido button</li>
                <li>Check box</li>
                <li>Assertions</li>
                <li>Grid</li>
              </ul>
            </fieldset>
          </div>

          <div className="row m-5">
            <h2>
              <b> Certificate:</b>
            </h2>
            <h2>
              <p>Selenium Testing</p>
            </h2>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Selenium;
