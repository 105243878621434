import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Nav from "../process/Nav";
import { url } from "../link";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const Viewtodayattendance = () => {
  const nav = useNavigate();
  const [data, setData] = useState([]);
  const [value, setvalue] = useState([]);
  const [taid, setTaid] = useState("");
  const [days, setDays] = useState([
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ]);
  const [selectedDays, setSelectedDays] = useState([]);
  const [student, setstudent] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [selectedAttendance, setSelectedAttendance] = useState(null);

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;

    const reorderedDays = Array.from(days);
    const [removed] = reorderedDays.splice(source.index, 1);
    reorderedDays.splice(destination.index, 0, removed);

    setDays(reorderedDays);
  };

  const addSelectedDay = (day) => {
    if (!selectedDays.includes(day)) {
      setSelectedDays([...selectedDays, day]);
    }
  };

  const removeSelectedDay = (day) => {
    setSelectedDays(selectedDays.filter((d) => d !== day));
  };

  const [eid, seteid] = useState("");
  const [starttiming, setstarttiming] = useState("");
  const [endtime, setendtime] = useState("");

  useEffect(() => {
    axios.post(url + "/edusphere/searchstudent").then((response) => {
      setstudent(response.data);
    });
    axios.post(url + "/edusphere/viewtodayattendance").then((response) => {
      setData(response.data);
      setvalue(response.data);
    });
  }, []);

  const viewtodayattendance = (e) => {
    nav("/updatetodayattendance", { state: e });
  };

  const deletec = (e) => {
    axios
      .post(url + "/edusphere/deleteattendance", { id: e })
      .then((response) => {
        axios.post(url + "/edusphere/viewtodayattendance").then((response) => {
          setData(response.data);
          setvalue(response.data);
        });
      });
  };

  const searchdata = (e) => {
    const r = [];
    for (var k of value) {
      var v = 0;
      for (var n of k) {
        n = "" + n;
        if (n.toLowerCase().indexOf(e) !== -1) {
          v = 1;
          break;
        }
      }
      if (v === 1) {
        r.push(k);
      }
    }
    setData(r);
  };

  const addTodayAttendance = () => {
    if (eid != null && selectedDays.length !== 0) {
      const value = {
        eid: eid,
        starttiming: starttiming,
        endtime: endtime,
        skiptilldate: "",
        status: "attending",
        selectedDays: selectedDays,
      };
      axios.post(url + "/edusphere/insertattendance", value).then((res) => {
        alert("success");
        seteid("");
        setstarttiming("");
        setendtime("");
        setSelectedDays([]);
        axios.post(url + "/edusphere/searchstudent").then((response) => {
          setstudent(response.data);
        });
        axios.post(url + "/edusphere/viewtodayattendance").then((response) => {
          setData(response.data);
          setvalue(response.data);
        });
      });
    }
  };

  const handleMarkAttendance = (attendance) => {
    setSelectedAttendance(attendance);
    setModalShow(true);
  };

  const handleSaveAttendance = (aid, status, remark) => {
    const value = { aid: aid, status: status, remark: remark };
    axios.post(url + "/edusphere/inserttodayattendance", value).then((res) => {
      alert("Attendance marked successfully");
      setModalShow(false);
      setSelectedAttendance(null);
      axios.post(url + "/edusphere/viewtodayattendance").then((response) => {
        setData(response.data);
        setvalue(response.data);
      });
    });
  };

  return (
    <div>
      <Nav />
      <h3>Today's Attendance</h3>
      <input
        type="search"
        onChange={(e) => searchdata(e.target.value)}
        className="form-select"
        placeholder="Search"
      />
      <button
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#addAttendanceModal"
      >
        Add Today Attendance
      </button>

      <div
        className="modal fade"
        id="addAttendanceModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Today Attendance
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="taid" className="form-label">
                  Userid
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="taid"
                  value={taid}
                  onChange={(e) => {
                    var k = e.target.value;
                    var n = k.split("-")[0];
                    setTaid(k);
                    seteid(n);
                  }}
                  list="taidOptions"
                />
                <datalist id="taidOptions">
                  {student &&
                    student.map((s) => {
                      return <option key={s}>{s}</option>;
                    })}
                </datalist>
              </div>
              <div className="mb-3">
                <label htmlFor="aid" className="form-label">
                  RID
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="aid"
                  value={eid}
                  onChange={(e) => seteid(e.target.value)}
                  readOnly
                />
              </div>
              <div className="mb-3">
                <label htmlFor="status" className="form-label">
                  Starttime
                </label>
                <input
                  type="time"
                  className="form-control"
                  id="status"
                  value={starttiming}
                  onChange={(e) => setstarttiming(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="remark" className="form-label">
                  Endtiming
                </label>
                <input
                  type="time"
                  className="form-control"
                  id="remark"
                  value={endtime}
                  onChange={(e) => setendtime(e.target.value)}
                />
              </div>
            </div>
            <div className="mb-3">
              <label className="form-label">Select Days of the Week</label>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="days">
                  {(provided) => (
                    <ul
                      className="list-group"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {days.map((day, index) => (
                        <Draggable key={day} draggableId={day} index={index}>
                          {(provided) => (
                            <li
                              className="list-group-item"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              {day}
                              <button
                                type="button"
                                className="btn btn-secondary btn-sm float-end"
                                onClick={() => addSelectedDay(day)}
                              >
                                Add
                              </button>
                            </li>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
            <div className="mb-3">
              <label className="form-label">Selected Days</label>
              <ul className="list-group">
                {selectedDays.map((day) => (
                  <li className="list-group-item" key={day}>
                    {day}
                    <button
                      type="button"
                      className="btn btn-danger btn-sm float-end"
                      onClick={() => removeSelectedDay(day)}
                    >
                      Remove
                    </button>
                  </li>
                ))}
              </ul>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={addTodayAttendance}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="table-responsive">
        <table className="table table-bordered" id="table_id">
          <thead>
            <tr>
              <th>Rid</th>
              <th>Name</th>
              <th>Cname</th>
              <th>Mobile</th>
              <th>Classby</th>
              <th>Startime</th>
              <th>Endtime</th>
              <th>Course</th>
              <th>Status</th>
              <th>Days</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {data.map((d) => {
              return (
                <tr key={d[0]}>
                  <td>{d[1]}</td>
                  <td>{d[2]}</td>
                  <td>{d[3]}</td>
                  <td>{d[4]}</td>
                  <td>{d[5]}</td>
                  <td>{d[6]}</td>
                  <td>{d[7]}</td>
                  <td>{d[9]}</td>
                  <td>{d[8]}</td>
                  <td>{d[10]}</td>
                  <td>
                    <button
                      className="btn btn-primary"
                      onClick={() => deletec(d[0])}
                    >
                      Delete Attendance
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={() => handleMarkAttendance(d[0])}
                    >
                      Mark Attendance
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <MarkAttendanceModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        onSave={handleSaveAttendance}
        attendance={selectedAttendance}
      />
    </div>
  );
};

const MarkAttendanceModal = ({ show, onHide, onSave, attendance }) => {
  const [status, setStatus] = useState("");
  const [remark, setRemark] = useState("");

  const handleSave = () => {
    onSave(attendance, status, remark);
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Mark Attendance</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            onChange={(e) => setStatus(e.target.value)}
            value={status}
            placeholder="Enter status"
          />
          <label htmlFor="status">Status</label>
        </div>

        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            onChange={(e) => setRemark(e.target.value)}
            value={remark}
            placeholder="Enter remark"
          />
          <label htmlFor="remark">Remark</label>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Viewtodayattendance;
