import axios from "axios";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Nav from "../process/Nav";
import { url } from "../link";
const Updatecontent = () => {
  var rx = 0;
  const { state } = useLocation();
  const [cid, setcid] = useState(state[rx++]);
  const [stid, setstid] = useState(state[rx++]);
  const [content, setcontent] = useState(state[rx++]);
  const [samplecode, setsamplecode] = useState(state[rx++]);
  const [output, setoutput] = useState(state[rx++]);
  const [workout, setworkout] = useState(state[rx++]);
  const nav = useNavigate();
  const submitdata = () => {
    const value = {
      cid: cid,
      stid: stid,
      content: content,
      samplecode: samplecode,
      output: output,
      workout: workout,
    };
    axios.post(url + "/edusphere/updatecontent", value).then(() => {
      nav("/addsubject");
    });
    setcid("");
    setstid("");
    setcontent("");
    setsamplecode("");
    setoutput("");
    setworkout("");
  };
  return (
    <div>
      <Nav />
      <nav
        className="navbar navbar-expand-sm bg-success navbar-dark"
        style={{ marginTop: "5%" }}
      >
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink className="nav-link" to="/viewsubject">
                Subject
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/viewtopics">
                Topics
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/viewsubtopics">
                Subtopics
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/viewcontent">
                Content
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
      <h1>Add content</h1>
      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setcid(e.target.value)}
          value={cid}
          placeholder="Enter cid"
        />
        <label htmlFor="cid">cid</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setstid(e.target.value)}
          value={stid}
          placeholder="Enter stid"
        />
        <label htmlFor="stid">stid</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setcontent(e.target.value)}
          value={content}
          placeholder="Enter content"
        />
        <label htmlFor="content">content</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setsamplecode(e.target.value)}
          value={samplecode}
          placeholder="Enter samplecode"
        />
        <label htmlFor="samplecode">samplecode</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setoutput(e.target.value)}
          value={output}
          placeholder="Enter output"
        />
        <label htmlFor="output">output</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setworkout(e.target.value)}
          value={workout}
          placeholder="Enter workout"
        />
        <label htmlFor="workout">workout</label>
      </div>

      <input
        type="submit"
        className="btn btn-primary"
        onClick={submitdata}
        style={{ width: "100%" }}
      />
    </div>
  );
};
export default Updatecontent;
