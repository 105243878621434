import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { NavLink } from "react-router-dom";
import { url } from "../link";
import PermissionModal from "./PermissionModal";
import axios from "axios";
import AddModal from "./AddModal";

const Addstaffattendance = () => {
  var x = window.localStorage.getItem("user").split(",")[1];
  const [Staffname, setStaffname] = useState(x);
  const [Morningstarttime, setMorningstarttime] = useState("");
  const [Morningendtime, setMorningendtime] = useState("");
  const [Eveningstarttime, setEveningstarttime] = useState("");
  const [Eveningendtime, setEveningendtime] = useState("");
  const [leave, setLeave] = useState("");
  const [data, setData] = useState([]);
  const [dates, setdates] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
  const [isModalOpen1, setIsModalOpen1] = useState(false); // Modal state

  var nx = new Date();
  // var hr = 21;
  // var min = 35;
  var hr = nx.getHours();
  var min = nx.getMinutes();

  useEffect(() => {
    const now = new Date();
    const formattedDateTime =
      now.getFullYear() +
      "-" +
      String(now.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(now.getDate()).padStart(2, "0") +
      " " +
      String(now.getHours()).padStart(2, "0") +
      ":" +
      String(now.getMinutes()).padStart(2, "0") +
      ":" +
      String(now.getSeconds()).padStart(2, "0");

    setMorningstarttime(formattedDateTime);
    setMorningendtime(formattedDateTime);
    setEveningstarttime(formattedDateTime);
    setEveningendtime(formattedDateTime);
    setdates(
      now.getFullYear() +
        "-" +
        String(now.getMonth() + 1).padStart(2, "0") +
        "-" +
        String(now.getDate()).padStart(2, "0")
    );

    axios
      .post(url + "/edusphere/viewstaffbalanceattendance", {
        staffid: window.localStorage.getItem("user").split(",")[0],
      })
      .then((response) => {
        setData(response.data);
      });
  }, []);
  const getpermison = (value) => {
    axios.post(url + "/edusphere/insertstaffpermission", value).then((res) => {
      alert("Permission submitted successfully");
      axios
        .post(url + "/edusphere/viewstaffbalanceattendance", {
          staffid: window.localStorage.getItem("user").split(",")[0],
        })
        .then((response) => {
          setData(response.data);
        });
      setIsModalOpen(false);
    });
  };

  const getcount = (value) => {
    axios.post(url + "/edusphere/insertleavecount", value).then((res) => {
      axios
        .post(url + "/edusphere/viewstaffbalanceattendance", {
          staffid: window.localStorage.getItem("user").split(",")[0],
        })
        .then((response) => {
          alert("Count submitted successfully");
          setData(response.data);
        });
      setIsModalOpen1(false);
    });
  };

  const submitdata = () => {
    let xr = "";
    if (hr >= 9 && hr <= 11) {
      xr = "Morningstarttime";
    } else if (hr >= 12 && hr <= 14) {
      xr = "Morningendtime";
    } else if (hr >= 15 && hr <= 18) {
      xr = "Eveningstarttime";
    } else if (hr >= 19 && hr <= 22) {
      xr = "Eveningendtime";
    }

    const value = {
      staffname: Staffname,
      Morningstarttime: Morningstarttime,
      Morningendtime: Morningendtime,
      eveningstarttime: Eveningstarttime,
      eveningendtime: Eveningendtime,
      leave: leave,
      dateTimeParam: xr,
      date: dates,
      staffid: window.localStorage.getItem("user").split(",")[0],
    };

    axios
      .post(url + "/edusphere/insertstaffattendance", value)
      .then((res) => {
        // setMorningstarttime("");
        // setMorningendtime("");
        // setEveningstarttime("");
        // setEveningendtime("");
        // setLeave("");
        axios
          .post(url + "/edusphere/viewstaffbalanceattendance", {
            staffid: window.localStorage.getItem("user").split(",")[0],
          })
          .then((response) => {
            alert("success");
          })
          .catch((err) => {
            alert(err);
          });
      })
      .catch((err) => {
        alert(err);
      });
  };

  return (
    <div>
      <Navbar />
      <div>
        <h1>Add Staff Attendance</h1>
        <button
          type="button"
          className="btn btn-success"
          onClick={() => setIsModalOpen1(true)}
        >
          Add Leave
        </button>
        <NavLink to="/viewstaffattendance">
          <button className="btn btn-primary">View Staff Attendance</button>
        </NavLink>
        {data.length !== 0 ? (
          <>
            <h6>Total Leave Available - {data[1]}</h6>
            <h6>Total Permission Available - {data[2]}</h6>
          </>
        ) : null}
        <div className="form-group">
          <label htmlFor="Staffname">Staffname</label>
          <input
            type="text"
            className="form-control"
            onChange={(e) => setStaffname(e.target.value)}
            value={Staffname}
            placeholder="Enter Staffname"
          />
        </div>
        {hr >= 9 && hr <= 11 ? (
          <div className="form-floating mb-3 mt-3">
            <input
              type="datetime-local"
              className="form-control"
              onChange={(e) => setMorningstarttime(e.target.value)}
              value={Morningstarttime}
              placeholder="Enter Morningstarttime"
              readOnly
            />
            <label htmlFor="Morningstarttime">Morningstarttime</label>
          </div>
        ) : (
          <></>
        )}
        {hr >= 12 && hr <= 14 ? (
          <div className="form-floating mb-3 mt-3">
            <input
              type="datetime-local"
              className="form-control"
              onChange={(e) => setMorningendtime(e.target.value)}
              value={Morningendtime}
              placeholder="Enter Morningendtime"
              readOnly
            />
            <label htmlFor="Morningendtime">Morningendtime</label>
          </div>
        ) : (
          <></>
        )}
        {hr >= 15 && hr <= 18 ? (
          <div className="form-floating mb-3 mt-3">
            <input
              type="datetime-local"
              className="form-control"
              onChange={(e) => setEveningstarttime(e.target.value)}
              value={Eveningstarttime}
              placeholder="Enter Eveningstarttime"
              readOnly
            />
            <label htmlFor="Eveningstarttime">Eveningstarttime</label>
          </div>
        ) : (
          <></>
        )}
        {hr >= 19 && hr <= 22 ? (
          <div className="form-floating mb-3 mt-3">
            <input
              type="datetime-local"
              className="form-control"
              onChange={(e) => setEveningendtime(e.target.value)}
              value={Eveningendtime}
              placeholder="Enter Eveningendtime"
              readOnly
            />
            <label htmlFor="Eveningendtime">Eveningendtime</label>
          </div>
        ) : (
          <></>
        )}
        <div className="radio-group">
          <label>
            <input
              type="radio"
              checked={leave === "full"}
              onChange={() => setLeave("full")}
              name="leave"
            />
            Apply Full Day Leave
          </label>
          <label>
            <input
              type="radio"
              checked={leave === "half"}
              onChange={() => setLeave("half")}
              name="leave"
            />
            Apply Half Day Leave
          </label>
          <br />
          <br />
          <button
            type="button"
            className="btn btn-success"
            onClick={() => setIsModalOpen(true)}
          >
            Request Permission
          </button>
        </div>
        <br />
        {leave && (
          <input
            type="date"
            value={dates}
            onChange={(e) => setdates(e.target.value)}
            className="form-control"
          />
        )}
        <input type="submit" className="btn btn-primary" onClick={submitdata} />
      </div>

      {/* Include the modal component and pass the required props */}
      <PermissionModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        staffId={window.localStorage.getItem("user").split(",")[0]}
        getpermison={getpermison}
      />

      <AddModal
        isOpen={isModalOpen1}
        onClose={() => setIsModalOpen1(false)}
        getcount={getcount}
      />
    </div>
  );
};

export default Addstaffattendance;
