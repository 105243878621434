import React from 'react';
import { PDFDownloadLink, Document, Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import ContactPage from './course_contact';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#fff',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  title: {
    fontSize: 20,
    marginBottom: 10,
  },
  details: {
    fontSize: 14,
    lineHeight: 1.5,
  },
});

const CourseDownloadButton = ({ title, details,duration }) => {
  const courseData = {
    title,
    details,
    duration,
  };

  const handleDownload = () => {
    const pdfBlob = (
      <Document>
        <Page size="A4" style={styles.page}>

          <View style={styles.section}>
            <Text style={styles.title}>{title}</Text>
            <Text style={styles.details}>{details}</Text>
            <Text style={styles.duration}>{duration}</Text>
          </View>
          <ContactPage/>
        </Page>
      </Document>
    );

    const pdfAsBlob = pdfBlob.toBlob();
    saveAs(pdfAsBlob, `${title}.pdf`);
  };

  return (
    <button className="btn btn-primary" onClick={handleDownload}>
      Download PDF
    </button>
  );
};

export default CourseDownloadButton;
