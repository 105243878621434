import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Nav from "../process/Nav";
import { url } from "../link";
import $ from "jquery";
const Viewmonthlyexpense = () => {
  const nav = useNavigate();
  const [data, setData] = useState([]);
  const [value, setvalue] = useState([]);
  const [total, settotal] = useState(0);
  const [mode, setmode] = useState("cash");
  useEffect(() => {
    axios.post(url + "/edusphere/viewmonthlyexpense").then((response) => {
      setData(response.data);
      setvalue(response.data);
      var r = 0;
      for (var k of response.data) {
        r = r + k[2];
      }
      settotal(r);
      $(document).ready(function () {
        var eventFired = function (type) {
          var n = $("#demo_info")[0];
          n.scrollTop = n.scrollHeight;
        };
        $("#example")
          .on("order.dt", function () {
            eventFired("Order");
          })
          .on("search.dt", function () {
            eventFired("Search");
          })
          .on("page.dt", function () {
            eventFired("Page");
          })
          .DataTable();
      });
    });
  }, []);
  const viewmonthlyexpense = (e) => {
    nav("/updatemonthlyexpense", { state: e });
  };
  const paid = (e) => {
    var k = new Date();
    axios
      .post(url + "/edusphere/insertmonthlyexpense", {
        id: e[0],
        expense: e[1],
        amount: e[2],
        curdate:
          k.getFullYear() +
          "-" +
          String(k.getMonth() + 1).padStart(2, "0") +
          "-" +
          String(k.getDate()).padStart(2, "0"),
        mode: mode,
      })
      .then((response) => {
        axios.post(url + "/edusphere/viewmonthlyexpense").then((response) => {
          setData(response.data);
          setvalue(response.data);
          var r = 0;
          for (var k of response.data) {
            r = r + k[2];
          }
          settotal(r);
          $(document).ready(function () {
            var eventFired = function (type) {
              var n = $("#demo_info")[0];
              n.scrollTop = n.scrollHeight;
            };
            $("#example")
              .on("order.dt", function () {
                eventFired("Order");
              })
              .on("search.dt", function () {
                eventFired("Search");
              })
              .on("page.dt", function () {
                eventFired("Page");
              })
              .DataTable();
          });
        });
      });
  };

  return (
    <div>
      <Nav />
      <div className="main">
        <div className="page-container">
          <h3>Monthlyexpense- {total}</h3>
          <div className="table-responsive p-0 pb-2">
            <div
              id="demo_info"
              className="box"
              style={{ display: "none" }}
            ></div>
            <table id="example" className="display" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>id</th>
                  <th>name</th>
                  <th>amount</th>
                  <th>paymentdate</th>

                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data.map((d) => {
                  return (
                    <tr key={d[0]}>
                      <td>
                        <button
                          className="btn btn-primary"
                          onClick={() => viewmonthlyexpense(d)}
                        >
                          {d[0]}
                        </button>
                      </td>
                      <td>{d[1]}</td>
                      <td>{d[2]}</td>
                      <td>{d[3]}</td>

                      <td>
                        <button
                          className="btn btn-primary"
                          onClick={() => paid(d)}
                        >
                          Paid
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Viewmonthlyexpense;
