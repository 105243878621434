import axios from "axios";
import { useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import Webcam from "react-webcam";
import Navbar from "../components/Navbar";
import { url } from "../link";

const videoConstraints = {
  width: { ideal: 1280 },
  height: { ideal: 720 },
  facingMode: "environment", // Use "user" for front camera and "environment" for rear camera
};

const Addlaptop = () => {
  const { state } = useLocation();

  const [laptopname, setlaptopname] = useState("");
  const [addons, setaddons] = useState("");
  const [givendate, setgivendate] = useState("");
  const [deliverydate, setdeliverydate] = useState("");
  const [commiment, setcommiment] = useState("");
  const [pid, setpid] = useState(state);
  const [receivedby, setreceivedby] = useState(
    window.localStorage.getItem("user").split(",")[0]
  );
  const [screenshot, setScreenshot] = useState(null);

  const webcamRef = useRef(null);

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setScreenshot(imageSrc);
  };

  const submitdata = () => {
    const value = {
      laptopname: laptopname,
      addons: addons,
      givendate: givendate,
      deliverydate: deliverydate,
      commiment: commiment,
      pid: pid,
      receivedby: receivedby,
      photo: screenshot,
    };
    axios.post(url + "/edusphere/insertlaptop", value).then((res) => {
      alert("success");
      setlaptopname("");
      setaddons("");
      setgivendate("");
      setdeliverydate("");
      setcommiment("");
      setScreenshot(null);
    });
  };

  return (
    <div>
      <Navbar />
      <h1>Add laptop</h1>
      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setlaptopname(e.target.value)}
          value={laptopname}
          placeholder="Enter laptopname"
        />
        <label htmlFor="laptopname">laptopname</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setaddons(e.target.value)}
          value={addons}
          placeholder="Enter addons"
        />
        <label htmlFor="addons">addons</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="date"
          className="form-control"
          onChange={(e) => setgivendate(e.target.value)}
          value={givendate}
          placeholder="Enter givendate"
        />
        <label htmlFor="givendate">givendate</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="datetime-local"
          className="form-control"
          onChange={(e) => setdeliverydate(e.target.value)}
          value={deliverydate}
          placeholder="Enter deliverydate"
        />
        <label htmlFor="deliverydate">deliverydate</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setcommiment(e.target.value)}
          value={commiment}
          placeholder="Enter commiment"
        />
        <label htmlFor="commiment">commiment</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setpid(e.target.value)}
          value={pid}
          placeholder="Enter pid"
        />
        <label htmlFor="pid">pid</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setreceivedby(e.target.value)}
          value={receivedby}
          placeholder="Enter receivedby"
        />
        <label htmlFor="receivedby">receivedby</label>
      </div>

      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraints}
      />
      <button onClick={capture}>Capture photo</button>
      {screenshot && (
        <div>
          <img src={screenshot} alt="screenshot" />
        </div>
      )}

      <input
        type="submit"
        className="btn btn-primary"
        onClick={submitdata}
        style={{ width: "100%" }}
      />
    </div>
  );
};

export default Addlaptop;
