import Footer from "./Footer";
import Header from "./Header";
const Mysql = () => {
  return (
    <div className="container-fuild">
      <Header />
      <div className="container">
        <div className="row border border-dark m-1">
          <div className="row text-center m-3">
            <h1>
              <b>
                <u> My SQL</u>
              </b>
            </h1>
          </div>

          <div className="row m-3">
            <h4>
              Variables are used for storing data or information during the
              execution of a program. It is a way of labeling data with an
              appropriate name that helps to understand the program more clearly
              by the reader. The main purpose of the variable is to store data
              in memory and can be used throughout the program.
            </h4>
          </div>

          <div className="row m-5 h4">
            <fieldset>
              <legend style={{ width: "40%" }}>Topics:</legend>
              <ul>
                <li>MySQL Workbench</li>
                <li>User Management</li>
                <li>MySQL Database</li>
                <li>Table & Views</li>
                <li>MySQL Queries</li>
                <li>MySQL Indexes</li>
                <li>MySQL Clauses</li>
                <li>MySQL Privileges</li>
                <li>Control Flow Function</li>
                <li>MySQL Conditions</li>
                <li>MySQL Join</li>
                <li>MySQL Key</li>
                <li>MySQL Triggers</li>
                <li>Aggregate Functions</li>
                <li>MySQL Misc</li>
                <li>MySQL Globalisation</li>
                <li>Regular Expressions</li>
              </ul>
            </fieldset>
          </div>

          <div className="row m-5">
            <h2>
              <b> Certificate:</b>
            </h2>
            <h2>
              <p>Database(MySQL)</p>
            </h2>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Mysql;
