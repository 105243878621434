import axios from "axios";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Nav from "../process/Nav";
import { url } from "../link";
const Addregistrationremark = () => {
  const { state } = useLocation();
  const [remark, setremark] = useState("");
  const [remarkdate, setremarkdate] = useState("");
  const [rid, setrid] = useState(state);
  const [am, setam] = useState("");
  const [status, setstatus] = useState("none");
  const nav = useNavigate();
  const submitdata = () => {
    const value = {
      remark: remark,
      remarkdate: remarkdate,
      rid: rid,
      am: am,
      status: status,
    };
    axios
      .post(url + "/edusphere/insertregistrationremark", value)
      .then((res) => {
        alert("success");
        nav("/viewregistration");
      });
  };
  return (
    <div>
      <Nav />

      <h1>Add registrationremark</h1>
      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setremark(e.target.value)}
          value={remark}
          placeholder="Enter remark"
        />
        <label htmlFor="remark">remark</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="date"
          className="form-control"
          onChange={(e) => setremarkdate(e.target.value)}
          value={remarkdate}
          placeholder="Enter remarkdate"
        />
        <label htmlFor="remarkdate">Next Payment date</label>
      </div>
      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setam(e.target.value)}
          value={am}
          placeholder="Enter amount"
        />
        <label htmlFor="amount">Next Payment amount</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setrid(e.target.value)}
          value={rid}
          placeholder="Enter rid"
          disabled
        />
        <label htmlFor="rid">rid</label>
      </div>
      <div className="form-floating mb-3 mt-3">
        <select
          className="form-select"
          onChange={(e) => {
            setstatus(e.target.value);
          }}
        >
          <option disabled selected>
            Select
          </option>
          <option>discontinued</option>
          <option>close</option>
          <option>joined</option>
          <option>completed</option>
          <option>certified</option>
        </select>
        <label htmlFor="Change Status">Change Status</label>
      </div>

      <input
        type="submit"
        className="btn btn-primary"
        onClick={submitdata}
        style={{ width: "100%" }}
      />
    </div>
  );
};
export default Addregistrationremark;
