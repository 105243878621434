import React, { useState, useEffect } from "react";
import Countdown from "react-countdown";

const Timer = () => {
  const [time] = useState(Date.now() + 1000 * (60 * 60));

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      alert("completed");
    } else {
      return (
        <span style={{ float: "right" }}>
          {hours}:{minutes}:{seconds}
        </span>
      );
    }
  };

  return (
    <>
      <Countdown date={time} renderer={renderer} />
    </>
  );
};

export default Timer;
